import React from 'react'
import cookie from 'react-cookies'

class RegistroVendedor extends React.Component{

    constructor(props){
        super(props)

        console.log(props)
    }

    render(){
        return(
            <div></div>
        )
    }
}

export default RegistroVendedor