import React from 'react'
import cookie from 'react-cookies';

class Logout extends React.Component{

    componentDidMount(){
        cookie.remove('token');
        cookie.remove('token_expire')

        window.location.href = "/";
    }

    render(){
        return(<div></div>)
    }

}

export default Logout