import React from 'react'
import {Form, Col, Row, OverlayTrigger, Tooltip, Button, Modal, FormGroup} from 'react-bootstrap'
import Dropzone from 'react-dropzone-uploader'
import {FaQuestionCircle, FaOm, FaUndo, FaTrash} from 'react-icons/fa'
import axios from 'axios'
import swal from 'sweetalert';
import cookie from 'react-cookies'
import { service_path, img_url, prev_url } from './conts'
import Loader from './Loader'
import jwtDecode from 'jwt-decode'
import SelectCuenta from './SelectCuenta'
import CrearCuenta from './CrearCuenta'
import CrearTarjeta from './CrearTarjeta'
import scooter from './assets/scooter.png'
import truck from './assets/truck.png'
import NuevaDireccion from './NuevaDireccion'
import SelectDireccion from './SelectDireccion'
import Container from './Container'
import imageCompression from 'browser-image-compression';
import RichTextEditor from 'react-rte'
import DropdownTreeSelect from 'react-dropdown-tree-select'
import AWS from 'aws-sdk'
import 'react-dropdown-tree-select/dist/styles.css'

class AddProduct extends React.Component{

    constructor(props){
        super(props);
        console.log(props.match.params.id)
        this.props = props;

        this.state = {
            nombre: "",
            categoria: "",
            imagenes: [],
            condicion: "",
            descripcion: "",
            descripcionrt: RichTextEditor.createEmptyValue(),
            caracteristicas: "",
            precio: 0,
            precioVendedor: 0,
            cantidad: 1,
            loader: true,
            modal: false,
            modalRegistro: false,
            email: "",
            password: "",
            token: cookie.load("token"),
            cuentas: "",
            mostrarCuentas: false,
            cuenta: "",
            agregarCuenta: false,
            agregarTarjeta: false,
            tarjetas: "",
            tarjeta: "",
            imagenesCargadas: [],
            camion: false,
            alto: 1,
            ancho: 1,
            largo: 1,
            tarjetaDefault: "",
            direccionDefault: "", 
            mostrarDireccion: false,
            direccion: "",
            aliasdireccion: "",
            deptos: "",
            departamento: "",
            muni: "",
            munis: "",
            latitud: 0,
            longitud: 0,
            peso: 1,
            guardarDespuesRegistro: true,
            numImagenes: 0,
            numNoUpload: 0,
            numUpload: 0,
            numero: "",
            dirnombre: "",
            dirdireccion: "",
            dirdepto: "",
            dirmuni: "",
            dirid: 0,
            alias: "",
            selectDireccion: false,
            direcciones: "",
            exit: false,
            dirShow: true,
            costoEnvio: 0,
            imagenids: [],
            envio: true,
            total: 0,
            cod: 0,
            comision: 0,
            categoriaOpciones: []
        }

        AWS.config.update({
            accessKeyId: "AKIAIH3GPDOPHNNEAG6Q",
            secretAccessKey: "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"
        })

        this.myBucket = new AWS.S3({
            params: { Bucket: 'rapimercado'},
            region: 'us-east-1',
          })

        this.handleChangeStatus = this.handleChangeStatus.bind(this)
        this.onChange = this.onChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.login = this.login.bind(this);
        this.signup = this.signup.bind(this);
        this.showRegistro = this.showRegistro.bind(this);
        this.cambiarData = this.cambiarData.bind(this);
        this.showAgregarCuenta = this.showAgregarCuenta.bind(this);
        this.showAgregarTarjeta = this.showAgregarTarjeta.bind(this);
        this.subirCuenta = this.subirCuenta.bind(this);
        this.returnCuentas = this.returnCuentas.bind(this);
        this.saveData = this.saveData.bind(this);
        this.closeCuenta = this.closeCuenta.bind(this);
        this.subirTarjeta = this.subirTarjeta.bind(this)
        this.deleteFoto = this.deleteFoto.bind(this)
        this.classImg = this.classImg.bind(this)
        this.changeEnvio = this.changeEnvio.bind(this)
        this.loginRequest = this.loginRequest.bind(this)
        this.showDireccion = this.showDireccion.bind(this);
        this.changeDepto = this.changeDepto.bind(this);
        this.changeMuni = this.changeMuni.bind(this);
        this.changeLocation = this.changeLocation.bind(this)
        this.hideDireccion = this.hideDireccion.bind(this);
        this.addDireccion = this.addDireccion.bind(this)
        this.registroPrev = this.registroPrev.bind(this)
        this.onChangePrecio = this.onChangePrecio.bind(this)
        this.validate = this.validate.bind(this)
        this.uploadImage = this.uploadImage.bind(this)
        this.hideRegistro = this.hideRegistro.bind(this)
        this.changeDir = this.changeDir.bind(this)
        this.loadDireccion = this.loadDireccion.bind(this)
        this.showDireccionSelect = this.showDireccionSelect.bind(this)
        this.hideDireccionSelect = this.hideDireccionSelect.bind(this)
        this.changeDireccion = this.changeDireccion.bind(this)
        this.onChangeEnvio = this.onChangeEnvio.bind(this)
        this.changeDescripcion = this.changeDescripcion.bind(this)
        this.changeCaracteristicas = this.changeCaracteristicas.bind(this)
        this.categoriaChange = this.categoriaChange.bind(this)
        this.createImages = this.createImages.bind(this)
        this.rotateImage = this.rotateImage.bind(this)
    }

    changeDir(name, value){
        
        let campo = "dir"+name;
        console.log("Entro al change: ", campo, ", ", value)
        this.setState({
   
            [campo]: value
     
        })
    }
    
    changeDescripcion(value){
        this.setState({
            descripcion: value.toString("html"),
            descripcionrt: value
        })
    }

    changeCaracteristicas(value){
        this.setState({
            caracteristicas: value
        })
    }

    componentDidMount(){

        let { id } = this.props.match.params 

        if(id !== undefined){
            if(this.state.token !== undefined){
                axios.get(
                    service_path + "/producto/editProduct/" + this.props.match.params.id,
                    {
                        headers: {
                            "Authorization": "Bearer " + this.state.token
                        }
                    }
                ).then((res) => {
                    let {data} = res;

                    console.log("res producto: ", data)

                    let total, comision = 0;

                    if(data.incluirEnvio){
                        total = data.precio;
                        comision = total - (data.precioVendedor + 25);
                    }
                    else{
                        total = data.precio + 25;
                        comision = data.precio - data.precioVendedor;
                    }

                    this.setState({
                        nombre: data.nombre,
                        categoria: data.categoria.codigoCategoria,
                        condicion: data.condicion.codigoCondicion,
                        descripcion: data.descripcion,
                        descripcionrt: RichTextEditor.createValueFromString(data.descripcion, 'html'),
                        caracteristicas: data.caracteristicas,
                        precio: data.precio,
                        cantidad: data.cantidad,
                        //cuenta: data.cuenta !== null ? data.cuenta.codigoCuenta : "",
                        imagenes: data.fotos,
                        camion: data.entregaCamion,
                        alto: data.alto !== null ? data.alto : 1,
                        ancho: data.ancho !== null ? data.ancho : 1,
                        largo: data.largo !== null ? data.largo : 1,
                        precioVendedor: data.precioVendedor,
                        comision: comision,
                        total: total,
                        peso: data.peso
                    }, () => {console.log(this.state)})
                }).catch((err) => {
                    console.log(err)
                    swal({
                        icon: "error",
                        title: "Producto no encontrado",
                        text: "err.response.data.mensaje"
                    }).then(() => {
                        window.location.href = "/"
                    })
                })
            }
            else{
                window.location.href = prev_url +"login";
            }
        }

        axios.get(
            service_path + "/usuario/depto",
        ).then((res) => {
            let { data } = res;

            this.setState({
                deptos: data.map((item, index) => {
                    return(
                        <option key={index} value={item.codigoDepartamento}>{item.nombre}</option>
                    )
                })
            })
        })

        if(id === undefined){
            id =  0;
        }

        axios.get(service_path + "/categoria/selectOption?id="+id)
            .then(response => {
                let { data } = response


                this.setState({
                    categoriaOpciones: data
                })
            })

        axios(service_path + "/producto/condiciones")
            .then(response => {
                console.log(response)
                this.setState({
                    condicionOpciones: response.data.map((item, index) => {
                        return(
                            <option value={item.codigoCondicion} key={index}>{item.nombre}</option>
                        )
                    }),
                    loader: false
                })
            })

        if(this.state.token !== undefined){
            

            this.cargarCuentas((err) => {
                if(err !== null) {
                    console.log(err)
                }
            })
        }


    }

    base64(file, callback){
        let reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = function () {
                callback(reader.result)
            };
            reader.onerror = function (error) {
                console.log('Error: ', error);
            };


    }
    makeid(length) {
        var result           = '';
        var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;
        for ( var i = 0; i < length; i++ ) {
           result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
     }

    createImages(file){
        let { imagenes } = this.state
        let name = this.makeid(16)

       

        const options = {
            maxWidthOrHeight: 360,
        }

        let original, normal, thumbnail = "";

        const optionsthumbnail = {
            maxWidthOrHeight: 50,
        }

        const optionoriginal = {
            maxWidthOrHeight: 960,
        }

        imageCompression(file, options)
        .then((fileCompresed) => {
            this.uploadS3(fileCompresed, name, (err) => {
                if(!err){
                    imageCompression(file, optionsthumbnail)
                    .then((fileCompresed) => {
                        this.uploadS3(fileCompresed, name + "thumb", (err) => {
                            if(!err){
                                imageCompression(file, optionoriginal)
                                .then((fileCompresed) => {
                                    this.uploadS3(fileCompresed, name + "full", (err) => {
                                        let { numImagenes } = this.state;
                                        console.log("completo", numImagenes)
                                        numImagenes--;

                                        let imagen = {
                                            codigoFoto: null,
                                            nombre: name, 
                                            clase: null
                                        }

                                        imagenes.push(imagen)

                                        let loader = numImagenes !== 0;
                                        console.log(loader)
                                        this.setState({
                                            numImagenes: numImagenes,
                                            imagenes: imagenes,
                                            loader: loader
                                        })
                                    })
                                })
                            }
                        })
                    })
                }
            })
        })
    }

    rotateImage(direction, index){

        let { imagenes } = this.state

        let className = "";
        
        let currentClass = imagenes[index].clase;

        console.log("actual", currentClass)

        switch(currentClass){
            case null:
            case undefined:
            case "":
                className = direction ? "rotateXP" : "rotateXN";
                break;
            case "rotateXP":
                className = direction ? "rotateY" : null;
                break;
            case "rotateY":
                className = direction ? "rotateXN" : "rotateXP";
                break;
            case "rotateXN":
                className = direction ? null : "rotateY";
                break;
            default:
                className = direction ? "rotateXP" : "rotateXN";
                break;
        }

        console.log("cambiao: ", className)

        imagenes[index].clase = className;

        this.setState({
            imagenes: imagenes
        }, () => {
            console.log(this.state.imagenes)
        })
         
    }

    uploadS3(file,  name, callback){        
        console.log("file", file);
        const params = {
            ACL: 'public-read',
            Key: name + ".jpg",
            ContentType: "image/jpg",
            Body: file,
        }

        this.myBucket.putObject(params)
        .on('httpUploadProgress', (evt) => {
            // that's how you can keep track of your upload progress
            
            console.log(Math.round((evt.loaded / evt.total) * 100))
        })   
        .send((err) => {
            callback(err)
        })
    }

    handleChangeStatus({ meta, file }, status){

        if(status === "done"){
            let{numImagenes} = this.state

            numImagenes++;
                
            this.setState({
                loader: true,
                numImagenes: numImagenes
            })
            let imagen = "";

            const options = {
                maxSizeMB: 1,
                useWebWorker: true
            }

            
            imageCompression(file, options)
            .then((compressedFile) => {
                this.createImages(compressedFile)
            }).catch((error) => {
                console.log(error.message);
            })
            
            
        }
        else if(status === "removed"){
            let imagen = "";
            this.base64(file, (result) => {
                imagen = result.split(',');
                var imagenes = this.state.imagenes;
                var newimagenes = [];
                imagenes.map(item => {
                    if(item !== imagen[1]){
                        newimagenes.push(item)
                    }
                    return true;
                })
                this.setState({
                    imagenes: newimagenes
                })
            })
        }
    }

    uploadImage(file, file2, file3){
        let normal, original, thumbnail = "";

        this.base64(file, (result) => {
            let imagen = "";              
            imagen = result.split(',');
            normal = imagen[1];

            this.base64(file2, (result2) => {
                let imagen2 = "";
                imagen2 = result2.split(',');
                thumbnail = imagen2[1];

                this.base64(file3, (result3) => {
                    let imagen3 = "";
                    imagen3 = result3.split(',');
                    original = imagen3[1];

                    this.setState({
                        numImagenes: this.state.numImagenes + 1
                    }, () => {
                        let data = {
                            normal: normal,
                            thumbnail: thumbnail,
                            original: original
                        }
                        console.log(data);

                        console.log("num upload: ", this.state.numImagenes)
                        axios.post(
                            service_path + "/producto/agregarFoto",
                            JSON.stringify(data),
                            {
                                headers: {
                                    "Content-Type": "application/json"
                                }
                            }
                        ).then((res) => {
                            let { data } = res;
                            var imagenes = this.state.imagenesCargadas;
                            var ids = this.state.imagenids;
                            console.log(imagenes);
                            imagenes.push(data.thumbnail);

                            for(let i = 0; i < data.ids.length ; i++){
                                ids.push(data.ids[i]);
                            }
        
                            this.setState({
                                imagenesCargadas: imagenes,
                                numImagenes: this.state.numImagenes - 1,
                                imagenids: ids
                            }, () => {
                                console.log("num post up: ", this.state.numImagenes)
                                if(this.state.numImagenes === 0){
                                    this.setState({
                                        loader: false
                                    })
                                }
                            })
                        })
        
                    })
                 
                })
            })

           
        })
    }

    onChange(event){
        const {name, value} = event.target;
        this.setState({
            [name]: value
        })
    }

    onChangeEnvio(){
        let { envio, total, precioVendedor, precio, comision, cod } = this.state;

        let parseado = parseFloat(precio);

        if(envio){

           

            if(parseado !== 0){
                total = parseFloat(precio) + 25
            }
            else{
                total = 0;
            }
            precioVendedor = (parseFloat(precio) *  0.91);
            comision = (parseFloat(precio) *  0.09);

        }else{

            if(parseado !== 0){
                 let subtotal = parseFloat(precio) - 25;

                precioVendedor = (subtotal *  0.91)
                comision = (subtotal * 0.09)
                total = precio
            }
            else{
                total = 0;
                comision = 0;
                precioVendedor = 0;
            }
        }


        this.setState({
            envio: !envio,
            total: total,
            comision: comision,
            precioVendedor: precioVendedor
        })
    }

    loadDireccion(guardar){
        axios.get(
            service_path + "/usuario/direcciones",
            {
                headers: {
                    "Authorization": "Bearer " + this.state.token,
                }
            }
        ).then((res) => {
            let { data } = res;

            if(data.length > 0){
                
                this.setState({
                    direcciones: data
                }, () => {
                    data.map((item) => {
                        if(item.defaultComprar){
                            //console.log("depto", item.depto.codigoDepartamento);
                            this.setState({
                                direccion: item.codigoDireccion
                            }, () => {
                                if(item.direccion === ""){
                                    swal({
                                        icon: "warning",
                                        title: "Accion necesaria para continuar",
                                        text: "Hacen falta datos a tu dirección principal, completala para poder continuar"
                                    })
                                    .then(() => {
                                        if(item.depto.codigoDepartamento !== ""){
                                            this.changeDepto(item.depto.codigoDepartamento)
                                        }
        
                                        this.setState({
                                            mostrarDireccion: true,
                                            exit: false,
                                            guardarDespuesRegistro: guardar,
                                            dirnombre: item.nombre,
                                            dirdireccion: data.direccion,
                                            dirmuni: item.muni.codigoMunicipio,
                                            dirdepto: item.depto.codigoDepartamento,
                                            dirid: item.codigoDireccion
                                        })
                                    })
                                }
                                else{
                                    if(guardar){
                                        this.saveData();
                                    }
                                }
                            })
                            
                        }
                    })
                })
                
              
                
               
            }
            else{
                swal({
                    icon: "warning",
                    title: "Accion necesaria para continuar",
                    text: "No tienes una dirección asociada a tu cuenta, crea una para poder continuar"
                })
                .then(() => {
                    this.setState({
                        mostrarDireccion: true,
                        exit: false,
                        guardarDespuesRegistro: guardar,
                        dirnombre: "",
                        dirdireccion: "",
                        dirdepto: "",
                        dirmuni: "",
                        dirid: 0,
                    })
                })
            }

            
        }).catch((err) => {
            console.log(err)
            console.log("Err response: ", err.response)
        })
    }

    onSubmit(event){
        
        if(event !== undefined) event.preventDefault();
        
        if(this.state.token !== undefined){
        //    this.showDireccionSelect();
            this.saveData();
        }
        else{
            this.setState({
                modal: true
            })
        }
        

      
    }

    handleClose(){
        this.setState({
            modal: false,
            modalRegistro: false
        })
    }

    showRegistro(){
        this.setState({
            modal: false,
            modalRegistro: true
        })
    }

    hideRegistro(){
        this.setState({
            modalRegistro: false
        })
    }

    login(){

        this.loginRequest((err) => {
            if(err === null){
                // this.cargarCuentas((err) => {
                //     if(err !== null){
        
                //     }
                //     else{
                //         this.setState({
                //             mostrarCuentas: true
                //         })
                //     }
                // })

                this.loadDireccion(true)
            }
            else{
                swal({
                    icon: err.icon,
                    title: err.title,
                    text: err.text
                })
            }
        })

        
    
    }

    loginRequest(callback){
        let email = this.state.email;

        email = email.replace(/\s/g,'');

        let data = {
            usuario: email,
            password: this.state.password
        }

        axios.post(
            service_path+"/auth/login",
            JSON.stringify(data),
            {
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Content-Type': 'application/json'
                }
            }
        ).then((res) => {
            console.log(res.data.accessToken)
            if(res.data.success){
                let token = res.data.accessToken

                let decoded = jwtDecode(token);

                cookie.save("token", token,  { path: '/' })
                cookie.save("token_expire", decoded.exp + "000", { path: '/' })
                
                this.setState({
                    token: token,
                    modal: false
                })

                callback(null)
            }
            else{
                let error = {
                    icon: "error",
                    title: "No has podidio iniciar sesión",
                    text: "Email inválido"
                }
    
                callback(error);
            }
            

             
        }).catch((err) => {
            console.log(err);

            let error = {
                icon: "error",
                title: "No has podidio iniciar sesión",
                text: "Contraseña inválida "
            }

            callback(error);
        })
    }

    registroPrev(){
        this.setState({
            modalRegistro: true,
            guardarDespuesRegistro: false
        })
    }

    signup(){
      
        if(this.state.password === this.state.confPassword){
            let email = this.state.email;

            email = email.replace(/\s/g,'');

            const data = {
                nombre: this.state.nombreRegistro,
                email: email,
                direccion: this.state.direccion,
                password: this.state.password,
                numero: this.state.numero,
                alias: this.state.alias,
                roles: [
                    "1"
                ]
            }

            this.setState({
                loader: true,
                modalRegistro: false
            })

            axios.post(
                service_path+"/auth/signup", 
                JSON.stringify(data), 
                {
                    headers: {
                        'Access-Control-Allow-Origin': '*',
                        'Content-Type': 'application/json'
                    }
                }
            ).then((response) => {
                console.log(response)
                this.setState({
                    loader: false,
                })

                let { data } = response 

                if(data.success){
                    swal({
                        title: "Te has registrado con éxito", 
                        text: "Gracias por registrarte a Rapimercado, por favor ingresa la cuenta a la que se te realizaran los pagos de lo que vendas.", 
                        icon: "success"
                    }).then(() => {
                        this.loginRequest((err) => {
                            if(err === null){
                                this.showAgregarCuenta();
                            }
                            else{
                                swal({
                                    icon: err.icon,
                                    title: err.title,
                                    text: err.text
                                })
                            }
                        })
                    });
                }
                else{
                    swal({
                        icon: "error",
                        title: "No se podido completar el Registro",
                        text: "El correo ingresado ya tiene una cuenta asociada"
                    }).then(() => {
                        this.setState({
                            modalRegistro: true
                        })
                    })
                }

            }).catch((error) => {
                let data = error.response.data;
                this.setState({
                    loader: false,
                })
                swal({
                    title: "No se ha completado registro", 
                    text: "No te has podido registrar: " + data.mensaje, 
                    icon: "error"
                }).then(() => {
                    this.setState({
                        modalRegistro: true
                    })
                });
            })
        }
        else{
            this.setState({
                password: "",
                confPassword: "",
                modalRegistro: true,
            })
            swal({
                title: "Las contraseñas no coinciden", 
                text: "La confirmación de contraseña no conicide con la contraseña ingresada", 
                icon: "error"
            });
        }
    }

    cargarCuentas(callback){
        axios.get(
            service_path + "/cuenta/obtener",
            {
                headers: {
                    'Authorization': "Bearer " + this.state.token
                }
            }
        ).then((response) => {

            let { cuentas } = response.data;
            
            console.log("cuentas: ", cuentas);

            if(cuentas.length === 0){
                this.setState({
                    dirShow: false
                }, () => {
                    swal({
                        icon: "warning",
                        title: "Acción requerida",
                        text: "Para vender necesitas tener una cuenta bancaria a la cual se te hagan los depositos de lo que vendas"
                    })
                    .then(() => {
                        this.showAgregarCuenta();
                    })
                })
            }
            else{
                this.loadDireccion(false);
            }

        }).catch((err) => {
            callback(err.response)
        })
    }

    saveData(){
        this.setState({
            loader: true,
            selectDireccion: false
        });
       
        const data = {
            nombre: this.state.nombre,
            categoria: this.state.categoria,
            imagenes: this.state.imagenes,
            condicion: this.state.condicion,
            descripcion: this.state.descripcion,
            caracteristicas: this.state.caracteristicas,
            precio: this.state.precio,
            cantidad: this.state.cantidad,
            cuenta: this.state.cuenta,
            camion: this.state.camion,
            alto: this.state.alto,
            ancho: this.state.ancho,
            largo: this.state.largo,
            peso: this.state.peso,
            precioVendedor: this.state.precioVendedor,
            direccion: this.state.direccion,
            envio: this.state.envio,
        }

        let url = this.props.match.params.id !== undefined ? "/producto/updateProduct/" + this.props.match.params.id  : "/producto/guardar"

        axios.post(
            service_path + url,
            JSON.stringify(data),
            {
                mode: 'no-cors',
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + this.state.token
                }
            }
        ).then((res) => {
            this.setState({
                loader:false
            })

            swal({
                title: "Producto Guardado exitosamente",
                text: "Tu producto se ha publicado exitosamente en la plataforma",
                icon: "success",
              }).then(() => {
                  window.location.href = "/"
              });
        }).catch((ex) => {
            this.setState({
                loader: false
            })
            console.log(ex.response)
            let data = ex.response.data;

            swal({
                icon: "error",
                title: "Producto no guardado",
                text: "No se ha podido guardar tu producto: " + data.mensaje
            })
        })
    }

    cambiarData(name, value){
        this.setState({
            [name]: value
        })
    }

    returnCuentas(){
       swal({
           icon: "warning",
           title: "Acción inválida",
           text: "Esta acción es necesaria para continuar"
       })
    }

    showAgregarCuenta(){
        this.setState({
            mostrarCuentas: false,
            agregarCuenta: true
        })
    }

    showAgregarTarjeta(){
        this.setState({
            mostrarCuentas: false,
            agregarTarjeta: true
        })
    }

    subirCuenta(data){
        this.setState({
            agregarCuenta: false,
            loader: true,
        })

        axios.post(
            service_path + "/cuenta/nueva",
            JSON.stringify(data),
            {
                headers: {
                    'Authorization': "Bearer " + this.state.token,
                    'Content-Type': 'application/json'
                }
            }
        ).then((response) => {
            let { dirShow } = this.state
            
            if(dirShow){
                swal({
                    icon: "success",
                    title: "Cuenta creada",
                    text: "Su cuenta ha sido creada exitosamente, ahora agrega la dirección a la que se recogeran tus productos"
                }).then(() => {
                    this.showDireccion();
                })            

            }
            else{
                swal({
                    icon: "success",
                    title: "Cuenta creada",
                    text: "Su cuenta ha sido creada exitosamente"
                }).then(() => {
                    window.location.reload()
                })
            }
            
            // this.cargarCuentas((err) => {
            //     if(err !== null){

            //     }
            //     else{
            //         this.setState({
            //             mostrarCuentas: true,
            //             loader: false,
            //         })
            //     }
            // })
        }).catch((err) => {
            this.setState({
                agregarCuenta: true,
                loader: false,
            })
        })
        
    }

    subirTarjeta(data){
        this.setState({
            agregarTarjeta: false,
            loader: true,
        }, console.log(this.state))
        axios.post(
            service_path + "/usuario/addCard",
            JSON.stringify(data),
            {
                headers: {
                    'Authorization': "Bearer " + this.state.token,
                    'Content-Type': 'application/json'
                }
            }
        ).then((response) => {
            swal({
                icon: "success",
                title: "Tarjeta Guardada",
                text: "Su tarjeta se ha guardado exitosamente"
            })                

            this.cargarCuentas((err) => {
                if(err !== null){

                }
                else{
                    console.log(this.state);
                    this.setState({
                        agregarTarjeta: false,
                        mostrarCuentas: true,
                        loader: false,
                    })
                }
            })
        }).catch((err) => {
            console.log(err);
            this.setState({
                agregarTarjeta: true,
                loader: false,
            }, console.log(this.state))
        })
    }

    closeCuenta(){
        this.setState({
            mostrarCuentas: false,
        }, this.saveData())
    }

    deleteFoto(id){

        swal({
            title: "¿Deseas eliminar la imagen?",
            text: "Estas seguro de querer eliminar la imagen",
            icon: "warning",
            buttons: {
                cancelar: {
                    text: "Cancelar",
                    value: false
                },
                eliminar: {
                    text: "Eliminar",
                    value: true,
                    className: "btn-danger"
                }
            },
            dangerMode: true,
        }).then((value) => {
            if(value){
                this.setState({
                    loader: true,
                })
        
                let { imagenes } = this.state;
                let nuevas = [];
                for(let i = 0; i < imagenes.length; i++){
                    if(i !== id){
                        nuevas.push(imagenes[i])
                    }
                }

                this.setState({
                    loader: false,
                    imagenes: nuevas,
                })
            }
        })

        

    }

    classImg(imagen){
        let clases = "img-envio";

        if(imagen === 1){
            if(!this.state.camion){
                clases += " active"
            }
        }
        else{
            if(this.state.camion){
                clases += " active"
            }
        }

        return clases;
    }

    changeEnvio(envio){
        this.setState({
            camion: envio
        })
    }

    changeDepto(value){
        axios.get(
            service_path + "/usuario/muni/" + value
        ).then(res => {
            let {data} = res;
            console.log(value)
            this.setState({
                departamento: value,
                muni: "",
                munis:  data.map((item, index) => {
                    return(
                        <option key={index} value={item.codigoMunicipio} >{item.nombre}</option>
                    )
                })
            }, () => {
                console.log(this.state)
            })
        })

    }

    changeMuni(value){
        this.setState({
            muni: value
        })
    }

    addDireccion(data){
        axios.post(
            service_path + "/usuario/direcciones",
            JSON.stringify(data),
            {
                headers: {
                    "Authorization": "Bearer " + this.state.token,
                    "Content-Type": "application/json"
                }
            }
        ).then((res) => {
            let direcciones = this.state.direcciones;
            console.log(direcciones)
            if(direcciones.length > 0){
                direcciones.push(res.data);
            }
            else{
                direcciones = [res.data]
            }
            this.setState({
                mostrarDireccion: false,
                direccion: res.data.codigoDireccion,
                direcciones: direcciones
            })
            swal({
                title: "Dirección guardada con éxito",
                icon: "success"
            }).then(() => {
                let { guardarDespuesRegistro } = this.state;
                if(guardarDespuesRegistro){
                     this.saveData();
                }
                else{
                    window.location.reload()
                }
            })
        }).catch((ex) => {
            console.log(ex);
        })
    }

    showDireccion(){
        this.setState({
            selectDireccion: false,
            mostrarDireccion: true,
            dirnombre: "",
            dirdireccion: "",
            dirdepto: "",
            dirmuni: "",
            dirid: 0,
        })
    }

    changeLocation(lat, lng){
        this.setState({
            latitud: lat,
            longitud: lng
        })
    }

    hideDireccion(){
        console.log(this.state)
        if(this.state.exit){
            this.setState({
                mostrarDireccion: false,
                selectDireccion: true,
            })
        }
        else{
            swal({
                icon: "warning",
                title: "Acción inválida",
                text: "Esta acción es necesaria para continuar"
            })
        }
        
    }

    onChangePrecio(event){
        let {value} = event.target;
        let {envio} = this.state;

        let precioVendedor = 0;
        let total = 0;
        let comision = 0;
        let parseado = parseFloat(value);
        if(!envio){

            

            if(parseado !== 0){
                 total =  parseado + 25
            }
            else{
                total = 0;
            }
            precioVendedor = (parseFloat(value) *  0.91);
            comision = (parseFloat(value) *  0.09);

        }else{

            if(parseado !== 0){
                let subtotal = parseFloat(value) - 25;

                precioVendedor = (subtotal *  0.91)
                comision = (subtotal * 0.09)
                total = value
            }
            else{
                total = 0;
                comision = 0;
                precioVendedor = 0;
            }
            
        }
        

        this.setState({
            precio: value,
            precioVendedor: precioVendedor,
            total: total,
            comision: comision
        })
    }

    validate(evt) {
        var theEvent = evt || window.event;
        var key = theEvent.keyCode || theEvent.which;
        key = String.fromCharCode( key );
        var regex = /[0-9]|\./;
        if( !regex.test(key) ) {
            theEvent.returnValue = false;
            if(theEvent.preventDefault) theEvent.preventDefault();
        }
    }

    showDireccionSelect(){
        this.setState({
            selectDireccion: true,
            exit: true,
        },
            () => {
                console.log(this.state)
            }
        )
    }

    hideDireccionSelect(){
        this.setState({
            selectDireccion: false,
            exit: false,
        })
    }

    changeDireccion(e){
        let { value } = e.target

        this.setState({
            direccion: value
        })
    }

    uploadDataDir(){
        this.saveData();
    }

    categoriaChange(currentNode, selectedNodes) {
        let { value } = currentNode;

        this.setState({
            categoria: value
        })
    }

    render(){
        let dataDir = {
            nombre: this.state.dirnombre,
            direccion: this.state.dirdireccion,
            muni: this.state.dirmuni,
            depto: this.state.dirdepto,
            id: this.state.dirid
        }
        return(
            <div className="container" style={{paddingBottom: "1rem"}}>
                <Loader visible={this.state.loader} />
                <SelectCuenta visible={this.state.mostrarCuentas} cuenta={this.state.cuenta} tarjeta={this.state.tarjeta} changeSelect={this.cambiarData} data={this.state.cuentas} data2={this.state.tarjetas} uploadData={this.showAgregarCuenta}  uploadData2={this.showAgregarTarjeta} save={this.closeCuenta} />
                <CrearCuenta visible={this.state.agregarCuenta} uploadData={this.subirCuenta} back={this.returnCuentas} note={null} />
                <CrearTarjeta visible={this.state.agregarTarjeta} uploadData={this.subirTarjeta} back={this.returnCuentas} />
                <NuevaDireccion visible={this.state.mostrarDireccion} uploadData={this.addDireccion} onClose={this.hideDireccion} 
                    deptos={this.state.deptos} munis={this.state.munis} changeDepto={this.changeDepto}
                    changeMuni={this.changeMuni} data={dataDir} onChange={this.changeDir}
                />
                <SelectDireccion visible={this.state.selectDireccion} direcciones={this.state.direcciones}
                    direccion={this.state.direccion} onChange={this.changeDireccion} 
                    addDireccion={this.showDireccion} onClose={this.hideDireccionSelect} 
                    uploadData={this.saveData}
                    />
                {
                    this.state.token === undefined ?
                    <div>
                        <Modal show={this.state.modal} onHide={this.handleClose} centered>
                            <Modal.Header closeButton>
                                <Modal.Title>Tienes que iniciar sesión para continuar</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <Form >
                                    <Form.Group as={Col} controlId="formEmail">
                                        <Form.Label>Correo Eléctronico</Form.Label>
                                        <Form.Control type="text" name="email" onChange={this.onChange}  value={this.state.email} />
                                    </Form.Group>

                                    <Form.Group as={Col} controlId="formPassword">
                                        <Form.Label>Contraseña</Form.Label>
                                        <Form.Control type="password" name="password" onChange={this.onChange} value={this.state.password} />
                                    </Form.Group>
                                </Form>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={this.showRegistro}>
                                    Registrarse
                                </Button>
                                <Button variant="primary" onClick={this.login}>
                                    Iniciar Sesion
                                </Button>
                            </Modal.Footer>
                        </Modal>
                        <Modal show={this.state.modalRegistro} onHide={this.handleClose} centered>
                            <Modal.Header closeButton>
                                <Modal.Title>Registrate</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <Form >
                                    <Form.Group as={Col} controlId="formNombre">
                                        <Form.Label>Nombre</Form.Label>
                                        <Form.Control type="text" name="nombreRegistro" onChange={this.onChange} autoComplete="off" value={this.state.nombreRegistro}  />
                                    </Form.Group>
                                    <Form.Group as={Col} controlId="formAlias">
                                        <Form.Label>Alias de Vendedor</Form.Label>
                                        <Form.Control type="text" name="alias" onChange={this.onChange} autoComplete="off" value={this.state.alias}  />
                                    </Form.Group>
                                    <Form.Group as={Col} controlId="formTelefono">
                                        <Form.Label>Telefono</Form.Label>
                                        <Form.Control type="number" name="numero" onChange={this.onChange} autoComplete="off" value={this.state.numero}  />
                                    </Form.Group>
                                    <Form.Group as={Col} controlId="formEmail">
                                        <Form.Label>Correo Eléctronico</Form.Label>
                                        <Form.Control type="text" name="email" onChange={this.onChange} autoComplete="off" value={this.state.email}  />
                                    </Form.Group>

                                    <Form.Group as={Col} controlId="formPassword">
                                        <Form.Label>Contraseña</Form.Label>
                                        <Form.Control type="password" name="password" onChange={this.onChange} autoComplete="off" value={this.state.password} />
                                    </Form.Group>

                                    <Form.Group as={Col} controlId="formConfPassword">
                                        <Form.Label>Repetir Contraseña</Form.Label>
                                        <Form.Control type="password" name="confPassword" onChange={this.onChange} autoComplete="off" value={this.state.confPassword} />
                                    </Form.Group>
                                </Form>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={this.hideRegistro}>
                                    Cancelar
                                </Button>
                                <Button variant="primary" onClick={this.signup}>
                                    Registrarse
                                </Button>
                            </Modal.Footer>
                        </Modal>
                    </div>
                    :
                    <div></div>
                }
                
                    
                <Form onSubmit={this.onSubmit}>
                    <h2>Datos del producto</h2>
                    <p>Ingresa los datos del producto que quieras vender</p>
                    {
                        this.state.token === undefined ? 
                        <span>¿Aún no te has registrado? <a className="link" onClick={this.registroPrev}>Haz click aquí para registrarte</a></span>
                        :
                        ""
                    }
                    
                    <Form.Group as={Row} controlId="formCategoria" style={{marginTop: "1.5rem"}}>
                        <Form.Label column md={2}>
                            Categoria
                        </Form.Label>
                        <Col md={10}>
                            {/* <Form.Control as="select" value={this.state.categoria} name="categoria" onChange={this.onChange}>
                                <option value="" disabled>Escoger</option>
                                {this.state.categoriaOpciones}
                            </Form.Control> */}
                            <Container
                                data={this.state.categoriaOpciones}
                                onChange={this.categoriaChange} 
                                mode="radioSelect" 
                                
                                texts={{
                                    placeholder: "Escoger"
                                }}
                            />

                        </Col>
                    </Form.Group>

                    <Form.Group as={Row} controlId="formNombre">
                        <Form.Label column md={2}>
                            Nombre
                        </Form.Label>
                        <Col md={10}>
                            <Form.Control placeholder="Nombre del Producto" value={this.state.nombre} name="nombre" onChange={this.onChange} />
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row} controlId="formImagenes">
                        <Form.Label column md={2}>
                            Imagenes
                        </Form.Label>
                        <Col md={10}>
                            <Dropzone
                                inputContent="Arrastra o haz click para agregar tus imagenes"
                                inputWithFilesContent="Agregar más"
                                onChangeStatus={this.handleChangeStatus}
                                accept="image/*"
                            />

                            {
                                this.state.imagenes.length !== 0 ? 
                                    <div>
                                        <p>Imagenes del Producto</p>
                                        <div className="row no-margin">
                                            {this.state.imagenes.map((item, index) => {
                                                return(
                                                    <div className="col-4 col-md-2 producto-preview" key={index} >
                                                        <div className="col-12 preview-hover" >
                                                            <span onClick={(e) =>{ 
                                                                e.preventDefault()
                                                                this.rotateImage(false, index)}
                                                                }>
                                                                <FaUndo />
                                                            </span>
                                                            <span onClick={(e) => {
                                                                    e.preventDefault();
                                                                    this.deleteFoto(index);
                                                                }}><FaTrash /></span>
                                                            <span onClick={(e) =>{ 
                                                                e.preventDefault()
                                                                this.rotateImage(true, index)
                                                                }} ><FaUndo style={{transform: "scaleX(-1)"}} /></span>
                                                        </div>
                                                        <img className={item.clase !== null ? item.clase : ""} src={ img_url + item.nombre + "thumb.jpg" }  />
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </div>
                                : ""
                            }
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row} controlId="formDescripcion">
                        <Form.Label column md={2}>
                            Descripcion
                        </Form.Label>
                        <Col md={10}>
                            {/* <Form.Control as="textarea" rows="4" value={this.state.descripcion} name="descripcion" onChange={this.onChange} />
                             */}
                            <RichTextEditor 
                                value={this.state.descripcionrt}
                                onChange={this.changeDescripcion}
                                name="descripcion"
                            />
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row} controlId="formCaracteristicas">
                        <Form.Label column md={2}>
                            Caracteristicas
                        </Form.Label>
                        <Col md={10}>
                            <Form.Control as="textarea" rows="4" value={this.state.caracteristicas} name="caracteristicas" onChange={this.onChange} />
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row} controlId="formPrecio">
                        <Form.Label column md={2}>
                            Precio (Q) 
                            {/* <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">El precio deberá incluir los Q35.00 de logistica de entrega</Tooltip>} >
                                <FaQuestionCircle />
                            </OverlayTrigger> */}
                            
                        </Form.Label>
                        <Col md={10}>
                               
                            <Form.Control type="text" onKeyPress={this.validate} placeholder="0.00" step="0.25" value={this.state.precio} name="precio" onChange={this.onChangePrecio} />
                            
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row} controlId="formEnvio">

                        <Form.Label column md={2}>
                            Incluir envio en precio
                        </Form.Label>
                        <Col md={10}>
                            <label className="switch">
                                <input type="checkbox" checked={this.state.envio} onChange={this.onChangeEnvio} />
                                <div className="slider"></div>
                            </label>
                            {/* <Form.Control type="checkbox" checked={this.state.envio} name="envio" onChange={this.onChangeEnvio}/> */}
                            <p>Venta: Q.{ parseFloat(this.state.precioVendedor).toFixed(2) }</p>
                            <p>Envio: Q.{ this.state.precioVendedor !== 0 ? "25.00" : "0.00"}</p>
                            <p>Comisión: Q.{ parseFloat(this.state.comision).toFixed(2)}</p>
                            <p><strong>Total: Q.{parseFloat(this.state.total).toFixed(2)}</strong></p>
                        </Col>
                    
                    </Form.Group>

                    <Form.Group as={Row} controlId="formCondicion" >
                        <Form.Label column md={2}>
                            Condicion
                        </Form.Label>
                        <Col md={10}>
                            <Form.Control as="select" value={this.state.condicion} name="condicion" onChange={this.onChange}>
                                <option value="" disabled>Escoger</option>
                                {this.state.condicionOpciones}
                            </Form.Control>
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row} controlId="formCantidad">
                        <Form.Label column md={2}>
                            Cantidad
                        </Form.Label>
                        <Col md={10}>
                            <Form.Control type="number"  step="1" value={this.state.cantidad} name="cantidad" onChange={this.onChange} />
                        </Col>
                    </Form.Group>

                    <FormGroup as={Row} controlId="formCamion">
                        <Form.Label column md={2}>
                            Tipo de Envío
                        </Form.Label>
                        <div className="col-md-10 d-flex flex-wrap">
                            <p className="col-12">¿En dónde se puede enviar tu producto?</p>
                            <div className="col-6 d-flex justify-content-center text-center " >
                                <img src={scooter} className={this.classImg(1)} onClick={(e) => {
                                    e.preventDefault();
                                    this.changeEnvio(false)
                                }} alt=""/>
                            </div> 
                            <div className="col-6 d-flex justify-content-center text-center " >
                                <img src={truck} className={this.classImg(2)} onClick={(e) => {
                                    e.preventDefault();
                                    this.changeEnvio(true)
                                }} alt=""/>
                            </div> 
                        </div>
                    </FormGroup>

                    {
                        this.state.camion ?
                            <div className="d-flex form-group flex-wrap">
                                <Form.Label column md={2}>Medidas del Paquete</Form.Label>
                                <div className="col-md-10 d-flex flex-wrap">
                                    <div className="col-md-4">
                                        <p>Alto</p>
                                        <Form.Control type="text" onKeyPress={this.validate} name="alto" value={this.state.alto} onChange={this.onChange} />
                                    </div>
                                    <div className="col-md-4">
                                        <p>Ancho</p>
                                        <Form.Control type="text" onKeyPress={this.validate} name="ancho" value={this.state.ancho} onChange={this.onChange} />
                                    </div>
                                    <div className="col-md-4">
                                        <p>Largo</p>
                                        <Form.Control type="text" onKeyPress={this.validate} name="largo" value={this.state.largo} onChange={this.onChange} />
                                    </div>
                                </div>
                            </div>
                        :
                            ""
                    }

                    <Form.Group as={Row} controlId="formPeso">
                        <Form.Label column md={2}>
                            Peso (En Libras)
                        </Form.Label>
                        <Col md={10}>
                            <Form.Control type="text" onKeyPress={this.validate} name="peso" value={this.state.peso} onChange={this.onChange} />
                        </Col>
                    </Form.Group>

                    <Button variant="primary" type="submit">
                        Guardar
                    </Button>
                </Form>
            </div>
        )
    }

  

}

export default AddProduct