import React from 'react'
import Axios from 'axios'
import { service_path, cargo } from './conts'
import cookie from 'react-cookies'
import { Form, Button } from 'react-bootstrap'
import swal from 'sweetalert'
import NuevaDireccion from './NuevaDireccion'
import add from './assets/add.jpg'
import Mapa from './Mapa'
import Loader from './Loader'

class Pago extends React.Component{

    constructor(props){
        super(props)

        let { params } = props.match;

        this.state = {
            id: params.id,
            cantidad: params.cantidad, 
            producto: {},
            loader: true,
            nombre: "",
            direccion: "",
            email: "",
            telefono: "",
            telefono2: "",
            metodo: 1,
            mostrarDireccion: false,
            token: cookie.load('token'),
            direcciones: [],
            direccionSelect: "",
            deptos: "",
            departamento: "",
            muni: "",
            munis: "",
            latitud: 0,
            longitud: 0,
            dirnombre: "",
            dirdireccion: "",
            dirdepto: "",
            dirmuni: "",
            dirid: 0,
            data: {},
            exit: true,
        }

        this.changePagoDeposito = this.changePagoDeposito.bind(this);
        this.changePagoEntrega = this.changePagoEntrega.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.onInput = this.onInput.bind(this);
        this.addDireccion = this.addDireccion.bind(this);
        this.loadDireccion = this.loadDireccion.bind(this);
        this.onChageDireccion = this.onChageDireccion.bind(this);
        this.hideDireccion = this.hideDireccion.bind(this);
        this.showDireccion = this.showDireccion.bind(this);
        this.changeDepto = this.changeDepto.bind(this);
        this.changeMuni = this.changeMuni.bind(this);
        this.changeLocation = this.changeLocation.bind(this)
        this.changeDir = this.changeDir.bind(this)
    }

    onInput(e){
        let { target } = e;
        target.setCustomValidity('');
    }

    changeDepto(value){

        Axios.get(
            service_path + "/usuario/muni/" + value
        ).then(res => {
            let {data} = res;
            console.log(value)
            this.setState({
                departamento: value,
                muni: "",
                munis:  data.map((item, index) => {
                    return(
                        <option key={index} value={item.codigoMunicipio} >{item.nombre}</option>
                    )
                })
            }, () => {
                console.log(this.state)
            })
        })

    }

    changeMuni(value){
        this.setState({
            muni: value
        })
    }


    addDireccion(data){
        Axios.post(
            service_path + "/usuario/direcciones",
            JSON.stringify(data),
            {
                headers: {
                    "Authorization": "Bearer " + this.state.token,
                    "Content-Type": "application/json"
                }
            }
        ).then(() => {
            this.loadDireccion();
            this.setState({
                mostrarDireccion: false
            })
            swal({
                title: "Dirección guardada con éxito",
                text: "Ahora puedes elegir la nueva dirección para tus pedidos",
                icon: "success"
            })
        }).catch((ex) => {

        })
    }

    
    changeDir(name, value){
        let campo = "dir"+name;
        this.setState({
   
            [campo]: value
     
        })
    }

    loadDireccion(){
        Axios.get(
            service_path + "/usuario/direcciones",
            {
                headers: {
                    "Authorization": "Bearer " + this.state.token,
                }
            }
        ).then((res) => {
            let { data } = res;

            if(data.length > 0){
                if(this.state.direccionSelect === ""){
                    data.map((item) => {
                        if(item.defaultComprar){
                            //console.log("depto", item.depto.codigoDepartamento);
                            if(item.direccion !== ""){
                                this.setState({
                                    direccionSelect: item.codigoDireccion,
                                    direccion: item.direccion,
                                    muni: item.muni.codigoMunicipio,
                                    departamento: item.depto.codigoDepartamento
                                })
                            }
                            else{
                                swal({
                                    icon: "warning",
                                    title: "Accion necesaria para continuar",
                                    text: "Hacen falta datos a tu dirección principal, completala para poder continuar"
                                })
                                .then(() => {
                                    if(item.depto.codigoDepartamento !== ""){
                                        this.changeDepto(item.depto.codigoDepartamento)
                                    }

                                    this.setState({
                                        mostrarDireccion: true,
                                        exit: false,
                                        dirnombre: item.nombre,
                                        dirdireccion: data.direccion,
                                        dirmuni: item.muni.codigoMunicipio,
                                        dirdepto: item.depto.codigoDepartamento,
                                        dirid: item.codigoDireccion
                                    })
                                })
                            }
                        }
                    })
                }
                
                this.setState({
                    direcciones: data
                })
            }
            else{
                swal({
                    icon: "warning",
                    title: "Accion necesaria para continuar",
                    text: "No tienes una dirección asociada a tu cuenta, crea una para poder continuar"
                })
                .then(() => {
                    this.setState({
                        mostrarDireccion: true,
                        exit: false,
                    })
                })
            }

            
        }).catch((err) => {
            console.log(err)
            console.log("Err response: ", err.response)
        })
    }

    showDireccion(){
        this.setState({
            mostrarDireccion: true,
            exit: true,
            dirnombre: "",
            dirdireccion: "",
            dirdepto: "",
            dirmuni: "",
            dirid: 0,
        })
    }

    hideDireccion(){
        if(this.state.exit){
            this.setState({
                mostrarDireccion: false,
            })
        }
        else{
            swal({
                icon: "warning",
                text: "Este paso es obligatorio para continuar",
                title: "Accion inválida"
            })
        }
       
    }

    componentDidMount(){
        Axios.get(service_path + "/producto/get/"+this.state.id)
            .then((response) => {
                this.setState({
                    data: response.data,
                    loader: false
                })
            })

        Axios.get(
            service_path + "/usuario/depto",
        ).then((res) => {
            let { data } = res;

            this.setState({
                deptos: data.map((item, index) => {
                    return(
                        <option key={index} value={item.codigoDepartamento}>{item.nombre}</option>
                    )
                })
            })
        })
        if(this.state.token !== undefined){

            this.loadDireccion()

            Axios.get(
                service_path + "/usuario/get",
                {
                    headers: {
                        'Access-Control-Allow-Origin': '*',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + this.state.token
                    }
                }
            ).then((response) => {
                var data = response.data;
                this.setState({
                    nombre: data.nombre,
                    email: data.email
                })
            })
        }
    }

    onChange(event){
        let {name, value} = event.target

        this.setState({
            [name]: value
        })
    }

    changePagoDeposito(){
        this.setState({
            metodo: 1
        })
    }

    changePagoEntrega(){
        this.setState({
            metodo: 1
        })
    }

    onChageDireccion(e){
        let { value } = e.target;
        let direccion = "";

        this.state.direcciones.map((item) => {
            console.log("Value: ", parseInt(value))
            console.log("Dir: ", item.codigoDireccion)
            console.log(item.codigoDireccion ===  parseInt(value))
            if(item.codigoDireccion === parseInt(value)){
                direccion = item;
                
            }
        })
        console.log(direccion);
        this.setState({
            direccion: direccion.direccion,
            muni: direccion.muni.codigoMunicipio,
            depto: direccion.depto.codigoDepartamento,
            direccionSelect: value
        })
    }

    onSubmit(e){
        e.preventDefault()
        this.setState({
            loader: true
        })

        let fecha = "";
        let hora = "";
        let date = new Date();

        date = this.addDays(date, 1)
        hora = "08:00";
      
        let dia = date.getDate();
        if(dia < 10){
            dia = "0" + dia;
        }
        let mes = date.getMonth() + 1;
        if(mes < 10){
            mes = "0" + mes;
        }

        fecha = date.getFullYear() + "-" + mes + "-" + dia;

        console.log(this.state);
        let data = {
            producto: this.state.id,
            cantidad: this.state.cantidad,
            nombre: this.state.nombre,
            direccion: this.state.direccion,
            email: this.state.email,
            telefono: this.state.telefono,
            telefono2: this.state.telefono2,
            metodo: this.state.metodo,
            depto: this.state.departamento,
            muni: this.state.muni,
            latitud: this.state.latitud,
            longitud: this.state.longitud,
            loader: false,
            hora: hora,
            fecha: fecha,
        }

        console.log("data: ", data)

        let token = cookie.load("token");

        if(token === undefined){
            var headers = {
                "headers": {
                    "Content-Type": "application/json"
                }
            }
        }
        else{
            var headers = {
                "headers": {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + token
                }
            }
        }

        Axios.post(
            service_path + "/orden/crear",
            JSON.stringify(data),
            headers
        ).then((response) => {
            let { data } = response;

            this.setState({
                loader: false
            })

            swal({
                icon: data.success,
                title: data.titulo,
                text: data.mensaje
            }).then(() => {
                if(data.success == "success")
                {
                    window.location.href = "/"
                }

                
            })
        }).catch((err) => {
            console.log(err);

            this.setState({
                loader: false
            })

            swal({
                icon: "error",
                title: "No se ha podido completar la compra",
                text: "Ha ocurrido un error al realizar tu compra"
            }).then(() => {
               
            })
        })
    }

    isAvaibleDate(date){
        let day = date.getDay();
        if(day === 0 || day === 6){

            return false
        }
        else{
            
            return true
        }
    }

    addDays(date, days){
        for(let i = 0; i < days; i++){
             do {date.setDate(date.getDate() + 1);}
             while(!this.isAvaibleDate(date))}
        return date;
    }

    changeLocation(lat, lng){
        this.setState({
            latitud: lat,
            longitud: lng
        })
    }

    render() {
        let dataDir = {
            nombre: this.state.dirnombre,
            direccion: this.state.dirdireccion,
            muni: this.state.dirmuni,
            depto: this.state.dirdepto,
            id: this.state.dirid
        }

        let { precio, envio, nombre, precioEnvio } = this.state.data

        precio = parseFloat(precio * this.state.cantidad).toFixed(2);
        let valenvio = envio ? 0 : precioEnvio;
        let total = parseFloat(precio) + parseFloat(valenvio);

        return (
            <div className="container">
                <NuevaDireccion visible={this.state.mostrarDireccion} uploadData={this.addDireccion} onClose={this.hideDireccion} 
                    deptos={this.state.deptos} munis={this.state.munis} changeDepto={this.changeDepto}
                    changeMuni={this.changeMuni} data={dataDir} onChange={this.changeDir}
                />
                <Loader visible={this.state.loader} />
                <div className="col-md-12 pagos-container">
                    <h2>{nombre}</h2>
                    <h4>Formas de pago</h4>
                    <div className="col-md-12 no-padding d-flex flex-wrap" style={{borderBottom: "3px solid #c7c7c7"}}>
                       
                        <div className="col-md-6">
                            <div className="pago-card entrega" id="entrega" onClick={this.changePagoEntrega}>
                                <div className="col-12 text-center d-flex justify-content-center" >
                                    <div className="pago-card-title">Contra Entrega</div>
                                </div>
                                <p>
                                    <strong>Precio: </strong> Q. { precio }
                                </p>
                                <p>
                                    <strong>Envio: </strong> { valenvio === 0 ? "Envio Gratis": "Q " + parseFloat(valenvio).toFixed(2)}
                                    
                                </p>
                                <p>
                                <strong>Total: </strong>Q. {parseFloat(total).toFixed(2)}
                                </p>
                            </div>
                        </div>
                    </div>
                    {
                        this.state.metodo !== "" ?
                        <div>
                            {
                                this.state.metodo === 1 ?
                                <div>
                                    <h3>Pago contra Entrega</h3>
                                    <h4>Deberas realizar tu pago cuando recibas el producto. El pago contra entrega tiene un recargo del {cargo}% sobre el precio del producto</h4>
                                </div>
                               
                                :
                                 <div>
                                    <h3>Pago con Deposito:</h3>
                                    <h4>Deberas realizar tu pago en banco al numero de cuenta de que se te estara enviado por correo electronico. Tendras 72 horas para realizar y registrar tu pago.</h4>
                                </div>
                            }

                            <h4 style={{marginTop: "2rem"}}>Datos de envío</h4>

                            <div className="col-md-12 no-padding">
                                <Form onSubmit={this.onSubmit} className="d-flex flex-wrap" >
                                    
                                        
                                    {
                                        this.state.token === undefined ?
                                            <div className="col-md-12 d-flex flex-wrap no-padding">
                                                <Form.Group className="col-md-6" controlId="ForDepto">
                                                    <Form.Label>Departamento: </Form.Label>
                                                    <Form.Control as="select"name="depto" value={this.state.departamento} onChange={(e) => this.changeDepto(e.target.value)} required onInvalid={(e) => {
                                                        let { target } = e;

                                                        target.setCustomValidity("El campo no puede ser vacio")    
                                                    }} onInput={this.onInput} >
                                                        <option value="" disabled>Elegir</option>
                                                        {this.state.deptos}
                                                    </Form.Control>
                                                </Form.Group>
                                                <Form.Group className="col-md-6" controlId="ForMuni">
                                                    <Form.Label>Municipio: </Form.Label>
                                                    <Form.Control as="select" name="muni" value={this.state.muni} onChange={(e) => this.changeMuni(e.target.value)} required onInvalid={(e) => {
                                                        let { target } = e;

                                                        target.setCustomValidity("El campo no puede ser vacio")    
                                                    }} onInput={this.onInput} >
                                                        <option value="" disabled>Elegir</option>
                                                        {this.state.munis}
                                                    </Form.Control>
                                                </Form.Group>
                                                <Form.Group className="col-md-12" controlId="ForDireccion">
                                                    <Form.Label>Dirección Exacta: </Form.Label>
                                                    <Form.Control type="text" name="direccion" value={this.state.direccion} onChange={this.onChange} required onInvalid={(e) => {
                                                        let { target } = e;

                                                        target.setCustomValidity("El campo no puede ser vacio")    
                                                    }} onInput={this.onInput} />
                                                    
                                                </Form.Group>
                                                <Form.Group className="col-md-12">
                                                    <Form.Label>Elige en el mapa tu direccion</Form.Label>
                                                    <Mapa changeLocation={this.changeLocation} />
                                                </Form.Group>
                                            </div>
                                        
                                        :
                                        <Form.Group className="col-md-6" controlId="ForDireccion">
                                            <Form.Label>
                                                Dirección: 
                                                <a className="add-icon"  variant="secondary" onClick={this.showDireccion} >
                                                    Agregar direccion <img src={add} /> 
                                                </a>
                                            </Form.Label>
                                            <Form.Control as="select" value={this.state.direccionSelect} onChange={this.onChageDireccion}>
                                                <option value="" disabled>Elegir</option>
                                                {
                                                    this.state.direcciones.map((item, index) => {
                                                        return(
                                                            <option key={index} value={item.codigoDireccion}>{ item.nombre }</option>
                                                        )
                                                    })
                                                }
                                            </Form.Control>
                                        </Form.Group>
                                    }
                                    <Form.Group className="col-md-6" controlId="ForNombre">
                                        <Form.Label>Persona que recibe: </Form.Label>
                                        <Form.Control type="text" name="nombre" value={this.state.nombre} onChange={this.onChange} required onInvalid={(e) => {
                                            let { target } = e;

                                            target.setCustomValidity("El campo no puede ser vacio")    
                                        }} onInput={this.onInput} />
                                    </Form.Group>
                                
                                    <Form.Group className="col-md-6" controlId="ForTelefono">
                                        <Form.Label>Teléfono: </Form.Label>
                                        <Form.Control type="number" name="telefono" value={this.state.telefono} onChange={this.onChange} />
                                    </Form.Group>
                                    <Form.Group className="col-md-6" controlId="ForTelefono2">
                                        <Form.Label>Teléfono alterno: </Form.Label>
                                        <Form.Control type="number" name="telefono2" value={this.state.telefono2} onChange={this.onChange} />
                                    </Form.Group>
                                
                                    <Form.Group className="col-md-6" controlId="ForEmail">
                                        <Form.Label>Email: </Form.Label>
                                        <Form.Control type="email" name="email" value={this.state.email} onChange={this.onChange} required  onInvalid={(e) => {
                                                let { target } = e;
                                                var mensaje = target.value === "" ? "El campo no puede ser vacio" : "El valor ingresado no es el de un correo electronico";
                                                target.setCustomValidity(mensaje);
                                            }}  onInput={this.onInput} />
                                    </Form.Group>
                                    

                                    <Button variant="danger" type="submit">
                                        Comprar
                                    </Button>
                                </Form>
                            </div>
                        </div>
                        :
                        <div></div>
                    }
                </div>
            </div>
        )
    }

}

export default Pago