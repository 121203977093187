import React from 'react'
import Axios from 'axios'
import { prev_url, service_path } from './conts';
import { isMobile } from 'react-device-detect';

class Anuncio extends React.Component{
    constructor(props){
        super(props);

        this.props = props

        this.state = {
            htmlAnuncio: "",
            visible: false,
            producto: "",
            width: 0,
        }
    }

    componentDidMount(){
       

       Axios.get(
           service_path + "/anuncio/get"
       ).then((res) => {
           let { data } = res;

           if(data !== false){
                this.setState({
                    visible: true,
                    htmlAnuncio: data.htmlAnuncio,
                    producto: data.producto,
                })
           }
       })
    }

    render(){
        let { htmlAnuncio, producto } = this.state;
        if(!isMobile){
            let container = document.getElementById("container")
            let navbar = document.getElementById("navbar")
        
            let width = 0;
            let top = 0;

            if(container !== null){
                width = (window.innerWidth - container.clientWidth) / 2;
            }
            if(navbar !== null){
                top = navbar.clientHeight;
            }
            return(
                <a href={producto !== null ? prev_url + "art/"+encodeURI(producto.nombre)+"/"+producto.codigoProducto : "#"} >
                    <div style={{
                        width: width+"px",
                        marginLeft : "-" + width + "px",
                        position: "absolute",
                        top: "1rem",
                        padding: "1rem"
                    }} dangerouslySetInnerHTML={{__html: htmlAnuncio}} >
                        
                    </div>
                </a>
                
            )
        }
        else{
            return(
                <a href={producto !== null ? prev_url + "art/"+encodeURI(producto.nombre)+"/"+producto.codigoProducto : "#"} >
                    <div className="col-12" dangerouslySetInnerHTML={{__html: htmlAnuncio}} > 
                    
                    </div>
                </a>
            )
        }
    }
}

export default Anuncio;