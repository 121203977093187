import React from 'react'
import cookie from 'react-cookies'
import {CSVReader} from 'react-papaparse'
import Axios from 'axios';
import { service_path } from './conts';
import swal from 'sweetalert';
import imageCompression from 'browser-image-compression';
import AWS from 'aws-sdk'
import Loader from './Loader';

const buttonRef = React.createRef();

class MasiveUpdate extends React.Component{

    constructor(){
        super();

        this.state = {
            token: cookie.load("token"),
            htmlTable: "",
            numImagenes: 0,
            dataSend: [],
            dataImagenes: [],
            loader: false,
            index: 1,
        }

        AWS.config.update({
            accessKeyId: "AKIAIH3GPDOPHNNEAG6Q",
            secretAccessKey: "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"
        })

        this.myBucket = new AWS.S3({
            params: { Bucket: 'rapimercado'},
            region: 'us-east-1',
        })

        this.onChangeFile = this.onChangeFile.bind(this)
        this.uploadData = this.uploadData.bind(this)
        this.bucleRead = this.bucleRead.bind(this)
        this.onChangeFile = this.onChangeFile.bind(this)
    }

    handleOpenDialog = (e) => {
        // Note that the ref is set async, so it might be null at some point
        if (buttonRef.current) {
          buttonRef.current.open(e);
        }
    };

    onChangeFile(data){

        // let fileName = fileInfo.name;
        // let formatString = /[^.]*$/.exec(fileName)[0];

        // let isCSVFile = formatString.includes('csv');
        
        this.setState({
            loader: true
        })

        console.log(data)

        // if(!isCSVFile){
        //     this.setState({
        //         htmlTable: data[0],
                
        //     }, () => {
               
        //         let tableInfo = Array.prototype.map.call(document.getElementsByTagName("tr"), function(tr){
        //             return Array.prototype.map.call(tr.querySelectorAll('td'), function(td){
        //               return td.innerHTML;
        //               });
        //         });

        //         data = tableInfo;

        //     })
        // }
        
        this.bucleRead(data, [], 1, (res) => {
            this.uploadData(res);
        })

       
        // this.ReadData(data, (dataSend, dataImagenes, numImagenes) => {
            
        //     this.setState({
        //         dataSend: dataSend,
        //         dataImagenes: dataImagenes
        //     }, () => {
        //         let { dataSend, dataImagenes } = this.state;
        //         let timeout = (parseInt(dataSend.length / 30) + 1) * 1000
        //         console.log(dataSend)
        //         console.log(dataImagenes)
        //         console.log("timeout", timeout);
        //         setTimeout(() => {
        //             let { dataImagenes } = this.state
        //             console.log("ya entro!")
        //             console.log("dataImagenes: ", dataImagenes.length)
        //             for(let i = 0; i < dataImagenes.length; i++){
        //                 let item = dataImagenes[i];
        //                 console.log("subiendo! ", i)
                        
        //                 this.imageUploader(item.file, item.nombre)

        //             }
        //         }, timeout)
                
        //     })
        // })
        
        
        
       
    }

    bucleRead(data, dataSend, index, callback){
        console.log("Leyendo... data: ", index )
        this.ReadData(data[index].data, (res) => {
            index++;
            if(res !== null){
                dataSend.push(res)
            }
            
            if(index !== data.length){
                setTimeout(() => {
                    this.bucleRead(data, dataSend, index, (res) => {
                        callback(res)
                    }) 
                }, 50)
                
            }
            else{
                callback(dataSend)
            }
           
        })

    }

    readUrlFile(data, imagenes, index, callback){
        if(data[index] !== "" && data[index] !== undefined && data[index] !== null){
            this.urlFile(data[index], (err, imagen) => {
                index++;
                
                if(err === null){
                    imagenes.push(imagen)
                    
                }
                if(index <= 17){
                    this.readUrlFile(data, imagenes, index, (res) => {
                        callback(res)
                    })
                }else{
                    callback(imagenes)
                }
            })
        }
        else{
            index++;
            if(index <= 16){
                this.readUrlFile(data, imagenes, index, (res) => {
                    callback(res)
                })
            }else{
                callback(imagenes)
            }
        }
    }

    ReadData(item, callback){
        
        if(item.length > 1){

            this.readUrlFile(item, [], 9, (res) => {
                let obj = item;

                let envio = false;

                switch(obj[6]){
                    case "si":
                    case "Si":
                    case "SI":
                    case 1:
                        envio = true;
                        break;
                    case "no":
                    case "No":
                    case "NO":
                        envio = false;
                        break;
                    default:
                        envio = null;
                        break;
                }

                let condicion;

                switch(obj[7]){
                    case "nuevo":
                    case "Nuevo":
                    case "NUEVO":
                        condicion = 1;
                        break;
                    case "usado":
                    case "Usado":
                    case "USADO":
                        condicion = 2;
                        break;
                    default:
                        envio = null;
                        break;
                }

                let send = {
                    codigo: obj[0],
                    nombre: obj[1],
                    descripcion: obj[2],
                    caracteristicas: obj[3],
                    cantidad: parseInt(obj[4]),
                    precio: parseFloat(obj[5]),
                    envio: envio,
                    condicion: condicion,
                    categoria: parseInt(obj[8]),
                    imagenes: res
                }
    
                callback(send)
            })      
        }
        else{
            callback(null)
        }
        
        
    }

    uploadData(dataSend){
        let{ token} = this.state
        console.log("dataSend: ", dataSend)
        Axios.post(
            service_path + "/producto/updateMasive",
            JSON.stringify(dataSend),
            {
                headers: {
                    "Authorization": "Bearer " + token,
                    "Content-Type": "application/json"
                }
            }
        ).then((res) => {
            let { data } = res

          
            swal({
                icon: "success",
                title: "Operación terminada con éxito",
                text: "Se han actualizado " + data.completados + " de " + data.total,
                buttons: {
                    download: {
                        text: "Descargar detalle error",
                        value: false
                    },
                    aceptar: true,
                }
            }).then((value) => {
                if(value){
                    window.location.reload()
                }
                else{
                    var hiddenElement = document.createElement('a');

                    hiddenElement.href = 'data:attachment/text,' + encodeURI(data.listNoCompletados);
                    hiddenElement.target = '_blank';
                    hiddenElement.download = 'myFile.txt';
                    hiddenElement.click();

                    swal({
                        icon: "success",
                        title: "Se ha descargado tu archivo"
                    }).then(() => {
                        window.location.reload()
                    })
                }
            })
            
          
        }).catch((err) => {
            swal({
             icon: "error",
             title: "Ha ocurrido un error",
             text: err
            }).then(() => {
                this.setState({
                    loader: false
                })
            })
         })
    }

    makeid(length) {
        var result           = '';
        var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;
        for ( var i = 0; i < length; i++ ) {
           result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
     }

     uploadS3(file,  name, callback){        

        const params = {
            ACL: 'public-read',
            Key: name + ".jpg",
            ContentType: "image/jpg",
            Body: file,
        }

        this.myBucket.putObject(params)
        .on('httpUploadProgress', (evt) => {
            // that's how you can keep track of your upload progress
            

        })   
        .send((err) => {
            callback(err)
        })
        // callback(null)
    }

    urlFile(url, callback){

        var name = this.makeid(15);
    
        fetch(url)
        .then(res => res.blob()) // Gets the response and returns it as a blob
        .then(blob => {
            // Here's where you get access to the blob
            // And you can use it for whatever you want
            // Like calling ref().put(blob)

            // Here, I use it to make an image appear on the page

            var file = new File([blob], "image.jpg", {
                type: blob.type,
              });

            let imagen = {
                nombre: name,
                file: file
            }

            if(blob.type.includes("image")){
                //callback(null, imagen)
                this.imageUploader(imagen.file, imagen.nombre, (res) => {
                    console.log("Termino de subir: ", res)
                    if(res === "Completo"){
                        callback(null, imagen)
                    }
                    else{
                        let err = "Error upload"
                        callback(err, null)
                    }
                })
            }
            else{
                let err = "File is not a image"
                callback(err, null)
            }
            
        }).catch((err) => {
            console.log(err)
            callback(err, null)
        });
    }

    imageUploader(file, name, callback){
        const options = {
            maxWidthOrHeight: 360,
        }

        const optionsthumbnail = {
            maxWidthOrHeight: 75,
        }

        const optionoriginal = {
            maxWidthOrHeight: 960,
        }

        imageCompression(file, options)
        .then((fileCompresed) => {
            this.uploadS3(fileCompresed, name, (err) => {
                if(!err){
                    imageCompression(file, optionsthumbnail)
                    .then((fileCompresed) => {
                        this.uploadS3(fileCompresed, name + "thumb", (err) => {
                            if(!err){
                                imageCompression(file, optionoriginal)
                                .then((fileCompresed) => {
                                    this.uploadS3(fileCompresed, name + "full", (err) => {
                                        
                                        callback("Completo")
                                       
                                    })
                                })
                            }
                        })
                    })
                }
            })
        }).catch((err) => {
            callback(err)
        })
    }

    render(){
        let { loader } = this.state
        return(
            <div >
                <CSVReader 
                    ref={buttonRef}
                    onFileLoad={this.onChangeFile} 
                    config={{
                        encoding: "ISO-8859-1"
                    }}  cssLabelClass="custom-file-upload btn btn-primary" label="Actualizar por bloque" cssInputClass="hide" 
                    noProgressBar 
                    noDrag
                >
                {({ file }) => (
                    <aside>
                    <button
                        type='button'
                        className="btn btn-primary"
                        onClick={this.handleOpenDialog}
                    >
                        Actualizar por bloque
                    </button>
                    </aside>
                )}
                </CSVReader>
                <div id="container" dangerouslySetInnerHTML={{__html: this.state.htmlTable}} class="hide"></div>
                <Loader visible={loader} />
            </div>
            
        )
    }

}

export default MasiveUpdate;