import React from 'react'
import cookie from 'react-cookies';
import Axios from 'axios';
import { service_path } from './conts';
import { Table, Button } from 'react-bootstrap';
import swal from 'sweetalert';

class Tarjetas extends React.Component{
    constructor(){
        super();

        this.state = {
            token: cookie.load("token"),
            tarjetas: []
        }

        this.setDefault = this.setDefault.bind(this);
    }

    componentDidMount(){
        Axios.get(
            service_path + "/usuario/getCard",
            {
                headers: {
                    'Authorization': "Bearer " + this.state.token
                }
            }
        ).then((res) => {
            this.setState({
                tarjetas: res.data.tarjetas
            })
        })
    }

    setDefault(value){
        let data = {};
        Axios.post(
            service_path + "/usuario/setCardDefault/" + value,
            JSON.stringify(data),
            {
                headers: {
                    "Authorization": "Bearer " + this.state.token
                }
            }
        ).then((res) => {
            let {tarjetas} = res.data;
            this.setState({
                tarjetas: tarjetas
            })
            swal({
                title: "Tarjeta Principal Modificada",
                text: "Tu tarjeta principal ha sido modificada con éxito",
                icon: "success"
            })
        }).catch((err) => {
            console.log(err);
            console.log("Err: ", err.response)
        })
    }

    render(){
        let { tarjetas } = this.state;
        console.log("Tarjetas: ", tarjetas);
        return(
            <div className="container">
                <h1>Tarjetas</h1>
                <Table striped bordered>
                    <tbody>
                        {
                            tarjetas.map((item, index) => {
                                return(
                                    <tr key={index}>
                                        <td>
                                            <h3 className="capitalize">{item.terminacion} - {item.emisor}</h3>
                                            {
                                                item.principal ?
                                                    <p>Tarjeta Principal</p>
                                                :
                                                    <Button 
                                                        variant="primary" 
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            this.setDefault(item.codigoTarjeta);
                                                        }}
                                                    >
                                                        Hacer Principal
                                                    </Button>
                                            }
                                        </td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                    
                </Table>
            </div>
        )
    }
}

export default Tarjetas