import React from 'react'
import  banner1 from '../assets/banner1.png'
import  banner2 from '../assets/banner2.png'
import  banner3 from '../assets/banner3.png'
import  banner4 from '../assets/banner4.png'

class Banner extends React.Component{

    constructor(){
        super();
    }

    render(){
        return(
            <div className="banner-info">
                <div className="col-md-3 d-flex">
                    <div className=" banner-col red">
                        <img src={banner1} />
                        <h3>En Rapimercado compra y vende sin salir de casa</h3>
                    </div>
                </div>
                <div className="col-md-3 d-flex">
                    <div className=" banner-col purple">
                        <img src={banner2} />
                        <h3>Compras a cientos de vendedores como tu y pagas cuando recibes</h3>
                    </div>
                </div>
                <div className="col-md-3 d-flex">
                    <div className=" banner-col yellow">
                        <img src={banner3} />
                        <h3>Protegemos tu compra y velamos de que recibas tu pago oportuno en cada venta que hagas</h3>
                    </div>
                </div>
                <div className="col-md-3 d-flex">
                    <div className=" banner-col green">
                        <img src={banner4} />
                        <h3>Crea tu cuenta hoy mismo y empieza a crecer sin contratos ni membresia</h3>
                    </div>
                </div>
            </div>
        )
    }

}

export default Banner