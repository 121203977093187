import React from 'react';
import { Modal, Form, Button } from 'react-bootstrap';
import Cards from 'react-credit-cards';
import Payment from 'payment';

import {
    formatCreditCardNumber,
    formatCVC,
    formatExpirationDate,
    formatFormData,
  } from './util';

class CrearTarjeta extends React.Component{
    constructor(props){
        super(props);

        this.props = props;

        this.state = {
            cvc: '',
            expiry: '',
            focused: '',
            name: '',
            number: '',
        };

        this.onChange = this.onChange.bind(this);
        this.onFocus = this.onFocus.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.onClose = this.onClose.bind(this);
    }

    handleCallback = ({ issuer }, isValid) => {
        if (isValid) {
          this.setState({ issuer });
        }
      };

    onFocus(e){
        this.setState({ focused: e.target.name });
    }

    onChange(e){
        let { name, value } = e.target;
        
        if (name === 'number') {
            value = formatCreditCardNumber(value);
          } else if (name === 'expiry') {
            value = formatExpirationDate(value);
          } else if (name === 'cvc') {
            value = formatCVC(value);
            console.log(value)
          }
     
        this.setState({ [name]: value });
    }

    onSubmit(){
        let data = {
            numero: this.state.number,
            cvv: this.state.cvc,
            expiracion: this.state.expiry,
            nombre: this.state.name,
            emisor: this.state.issuer
        }

        this.props.uploadData(data);
    }

    onClose(){
        this.props.back()
    }

    render(){
        return(
            <Modal show={this.props.visible}>
                <Modal.Header>
                    <Modal.Title>Guardar nueva tarjeta</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div id="PaymentForm">
                        <Cards
                            cvc={this.state.cvc}
                            expiry={this.state.expiry}
                            focused={this.state.focused}
                            name={this.state.name}
                            number={this.state.number}
                            callback={this.handleCallback}
                        />
                        <Form>
                        <Form.Group controlId="formNumero">
                            <Form.Label>Número: </Form.Label>
                            <Form.Control type="tel"  name="number" pattern="[\d| ]{16,22}" value={this.state.number} onChange={this.onChange} onFocus={this.onFocus} />
                        </Form.Group>
                        <Form.Group controlId="formNombre">
                            <Form.Label>Nombre: </Form.Label>
                            <Form.Control type="tel"  name="name" value={this.state.name} onChange={this.onChange} onFocus={this.onFocus} />
                        </Form.Group>
                        <Form.Group controlId="formNumero">
                            <Form.Label>Expiracion: </Form.Label>
                            <Form.Control type="tel"  name="expiry" pattern="\d\d/\d\d" value={this.state.expiry} onChange={this.onChange} onFocus={this.onFocus} />
                        </Form.Group>
                        <Form.Group controlId="formCvv">
                            <Form.Label>cvv: </Form.Label>
                            <Form.Control type="tel"  name="cvc" pattern="\d{3,4}" value={this.state.cvc} onChange={this.onChange} onFocus={this.onFocus} />
                        </Form.Group>
                        <input type="hidden" name="issuer" value={this.state.issuer} />
                        </Form>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={this.onClose}>
                        Regresar
                    </Button>
                    <Button variant="primary" onClick={this.onSubmit}>
                        Agregar
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }
}

export default CrearTarjeta