import React from 'react'
import { Modal, Form, Col, Row, Button } from 'react-bootstrap'
import Axios from 'axios';
import { service_path } from './conts';
import cookie from 'react-cookies';

class EditarCuenta extends React.Component{

    constructor(props){
        super(props)

        this.props = props;

        let { data } = props

        this.state = {
            bancos: "",
            tipos: "",
            monedas: ""
        }

        this.onChange = this.onChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);

    }

    componentDidMount(){
        Axios.get(
            service_path + "/cuenta/datos",
            {
                headers: {
                    'Authorization': "Bearer " + cookie.load("token")
                }
            }
        ).then((response) => {

            let { bancos, tipos, monedas } = response.data;

            this.setState({
                bancos: bancos.map((item, index) => {
                    return(
                        <option value={item.codigoBanco} key={index}>{item.nombre}</option>
                    );
                }),
                tipos: tipos.map((item, index) => {
                    return(
                        <option value={item.codigoTipoCuenta} key={index}>{item.nombre}</option>
                    );
                }),
                monedas: monedas.map((item, index) => {
                    return(
                        <option value={item.codigoMoneda} key={index}>{item.nombre} ({item.simbolo})</option>
                    );
                })
            })

        }).catch((err) => {

        })
    }

    

    onChange(event){
        let { name, value } = event.target;

       this.props.onChange(name, value)
    }

    onSubmit(event){
        event.preventDefault();

        let data = {
            numero: this.state.numero,
            banco: this.state.banco,
            tipoCuenta: this.state.tipoCuenta,
            moneda: this.state.moneda,
        }

        this.props.uploadData(data);
    }

    render(){
        let { data } = this.props; 
        return(
            <Modal show={this.props.visible} >
                <Modal.Header>
                    <Modal.Title>Actualizar cuenta</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {
                        this.props.note !== null ? 
                            <span><strong>Nota: </strong>{this.props.note}</span>
                        :
                            ""
                    }
                    <Form>
                        <Form.Group as={Row} controlId="formNumero" >
                            <Form.Label column md={2}>
                                Numero de Cuenta
                            </Form.Label>
                            <Col md={10}>
                                <Form.Control value={data.numero} name="numero" onChange={this.onChange} />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} controlId="formBanco" >
                            <Form.Label column md={2}>
                                Banco
                            </Form.Label>
                            <Col md={10}>
                                <Form.Control as="select" value={data.banco} name="banco" onChange={this.onChange}>
                                    <option value="" disabled>Escoger</option>
                                    {this.state.bancos}
                                </Form.Control>
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} controlId="formTipoCuenta" >
                            <Form.Label column md={2}>
                                Tipo de Cuenta
                            </Form.Label>
                            <Col md={10}>
                                <Form.Control as="select" value={data.tipoCuenta} name="tipoCuenta" onChange={this.onChange}>
                                    <option value="" disabled>Escoger</option>
                                    {this.state.tipos}
                                </Form.Control>
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} controlId="formMoneda" >
                            <Form.Label column md={2}>
                                Moneda
                            </Form.Label>
                            <Col md={10}>
                                <Form.Control as="select" value={data.moneda} name="moneda" onChange={this.onChange}>
                                    <option value="" disabled>Escoger</option>
                                    {this.state.monedas}
                                </Form.Control>
                            </Col>
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={this.props.back}>
                        Cancelar
                    </Button>
                    <Button variant="primary" onClick={this.onSubmit}>
                        Agregar
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }

}

export default EditarCuenta