import React from 'react'
import { GalleryCard } from 'tabler-react'
import { Link } from 'react-router-dom'

function CategorieCard(props) {
    const classes = "d-flex align-items-center px-2 justify-content-center"
    return (
        <div className="col-12 no-padding categoria" >
            <Link className="link-route" to={"/"+props.data.opcionMenu.nombre}>
                <GalleryCard>
                    <GalleryCard.Image src={props.data.imagen} />
                    <div className={classes}>
                    <h4>{props.data.nombre}</h4>
                    </div>
                </GalleryCard>
            </Link>
            
        </div>

    )
}

export default CategorieCard