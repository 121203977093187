import React from 'react'
import {BrowserRouter, HashRouter, Router} from 'react-router-dom'
import ClearCache from 'react-clear-cache';
import CookieConsent from "react-cookie-consent";

import Header from './templates/Header'
import Routes from './routes/Routes'
import Footer from './templates/Footer';

class App extends React.Component{
    render() {
        return (
            <HashRouter>
                <ClearCache>
                    {({ isLatestVersion, emptyCacheStorage }) =>
                        {
                            console.log(isLatestVersion)
                            if(!isLatestVersion){
                                emptyCacheStorage();
                            }
                            return("")
                        }
                    }
                </ClearCache>
                <Header />
                <CookieConsent
                    buttonText="Entiendo"
                >
                    Este sitio utiliza cookies para hacer de tu navegación una experiencia mas cómoda y personal. 
                    Las cookies nos permiten almacenar información útil en tu computadora ayudandonos a mejor la eficiencia de 
                    nuestro sitio web. En algunos casos son necesarias para el correcto funcionamiento del sitio. 
                    Al acceder a este sitio, usted esta acepta y autoriza el uso de las cookies.
                </CookieConsent>
                <Routes />
                <Footer />
            </HashRouter>
        )
    }
}

export default App