import React from 'react'

import {Route, Switch, Redirect} from 'react-router-dom'
import Home from '../Home'
import Category from '../Category'
import ProductDetail from '../ProductDetail'
import AddProduct from '../AddProduct'
import Login from '../Login'
import cookies from 'react-cookies'
import Logout from '../Logout'
import SignUp from '../SignUp'
import Pago from '../Pago'
import Verificar from '../Verificar'
import Vendidos from '../Vendidos'
import Compras from '../Compras'
import Direcciones from '../Direcciones'
import Tarjetas from '../Tarjetas'
import Vendedor from '../Vendedor'
import Mensaje from '../Mensaje'
import Mensajes from '../Mensajes'
import Mapa from '../Mapa'
import Tracking from '../Tracking'
import Ordenes from '../Ordenes'
import NuevaClave from '../NuevaClave'
import Cuentas from '../Cuentas'
import FAQ from '../FAQ'
import Terminos from '../Terminos'
import Perfil from '../Perfil'
import TestGetFile from '../TestGetFile'
import RegistroVendedor from '../RegistroVendedor'

function Routes(){
    return (
        <Switch>
            <Route className="link-route" exact path="/" component={Home} />
            <Route className="link-route" exact path="/art/:prod/:id" component={ProductDetail} />
            <Route className="link-route" exact path="/art/:prod/:id/pago/:cantidad" component={Pago} />
            <Route className="link-route" exact path="/add-product" component={AddProduct} />
            <Route className="link-route" exact path="/edit-product/:id" component={AddProduct} />
            <PrivateRoute className="link-route" exact path="/logout" component={Logout} />
            <Route className="link-route" exact path="/login" component={Login} />
            <Route className="link-route" exact path="/signup" component={SignUp} />
            <Route className="link-route" exact path="/signupSeller" component={RegistroVendedor} />
            <Route className="link-route" exact path="/onsale" component={Vendidos} />
            <Route className="link-route" exact path="/orders" component={Ordenes} />
            <Route className="link-route" exact path="/buy" component={Compras} />
            <Route className="link-route" exact path="/resetPassword/:token" component={NuevaClave} />
            <Route className="link-route" exact path="/verify/:token" component={Verificar} />
            <Route className="link-route" exact path="/bankAccounts" component={Cuentas} />
            <Route className="link-route" exact path="/address" component={Direcciones} />
            <Route className="link-route" exact path="/cards" component={Tarjetas} />
            <Route className="link-route" exact path="/seller/:id" component={Vendedor} />
            <Route className="link-route" exact path="/message/:llave" component={Mensaje} />
            <Route className="link-route" exact path="/messages" component={Mensajes} />
            <Route className="link-route" exact path="/map" component={Mapa} />
            <Route className="link-route" exact path="/faq" component={FAQ} />
            <Route className="link-route" exact path="/terms" component={Terminos} />
            <Route className="link-route" exact path="/profile" component={Perfil} />
            <Route className="link-route" exact path="/tracking/:codigo?" component={Tracking} />
            <Route className="link-route" exact path="/testGetFile" component={TestGetFile} />
            <Route className="link-route" exact path="/:category/:page?" component={Category} />
            
        </Switch>
    )
}

function PrivateRoute({ component: Component, ...rest }) {
    let token = cookies.load("token");
    return (
      <Route exact
        render={props =>
          token !== undefined ? (
            <Component {...props} />
          ) : (
            <Redirect
              to={{
                pathname: "/login",
                state: { from: props.location }
              }}
            />
          )
        }
      />
    );
  }

export default Routes