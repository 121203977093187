import React from 'react'
import { Modal, Button, Form } from 'react-bootstrap';
import add from './assets/add.jpg'
import swal from 'sweetalert';

class SelectDireccion extends React.Component{

    constructor(props){
        super(props);

        this.props = props

        this.uploadData = this.uploadData.bind(this)
    }

    uploadData(){
        let { direccion, uploadData } = this.props
        if(direccion !== ""){
            uploadData()
        }
        else{
            swal({
                icon: "warning",
                title: "Accion inválida",
                text: "Debes seleccionar o agregar una dirección para continuar"
            })
        }
    }

    render(){
        let { direcciones, visible, direccion, onChange, addDireccion, onClose } = this.props
        return(
            <Modal show={visible}>
                <Modal.Header>
                    <Modal.Title>Seleccionar Direccion de Recogida</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group>
                            <Form.Label>
                                Elegir dirección
                                <a className="add-icon"  variant="secondary" onClick={addDireccion} >
                                    Agregar direccion <img src={add} /> 
                                </a>
                            </Form.Label>
                            <Form.Control as="select" value={direccion} onChange={onChange} >
                                <option value="" disabled>Elegir</option>
                                {
                                    direcciones != "" ?
                                        direcciones.map((item, index) => {
                                            return (
                                                <option value={item.codigoDireccion} key={index}>
                                                    {item.nombre}
                                                </option>
                                            )
                                        })
                                    :
                                    ""
                                }
                            </Form.Control>
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={onClose}>Cancelar</Button>
                    <Button onClick={this.uploadData}>Guardar</Button>
                </Modal.Footer>
            </Modal>
        )
    }
}

export default SelectDireccion