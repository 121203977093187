import React from 'react'

class Terminos extends React.Component{

    constructor(){
        super()

        window.scrollTo(0,0);
    }

    render(){
        return(
            <div className="container">
                <h1 className="term-title">Términos y Condiciones de Uso de RAPIMERCADO</h1>
                <span>Versión vigente a partir del 1 de mayo de 2020</span>

                <p className="term-par">Bienvenido a <strong>Rapimercado</strong>.  Estamos muy contentos de que estés aquí. Ponte cómodo y pásalo bien, pero por favor sigue las reglas de nuestra casa.</p>

                <h3>1.	ACEPTACIÓN DE TÉRMINOS</h3>
                <p>Los Términos y Condiciones de Uso son un contrato legalmente vinculante entre tú y <strong>Rapimercado, S.A.</strong> legalmente establecida en Guatemala, C.A. </p>
                <p>Estos Términos y Condiciones de Uso regulan el uso que haces de los servicios, contenido, software y servicios ofrecidos por <strong>Rapimercado, S.A</strong> (a partir de ahora <strong>Rapimercado</strong>) a través de nuestra página web <strong>Rapimercado.com</strong>.</p>
                <p>Al utilizar <strong>Rapimercado</strong>, aceptas y estás de acuerdo con quedar sujeto a lo dispuesto en los Términos y Condiciones de Uso.  </p>
                <p>Los Términos y Condiciones de Uso pueden ser actualizados periódicamente con o sin preaviso.  Deberías revisar periódicamente nuestra web o notificaciones oficiales para ver si hay modificaciones en la página de los Términos y Condiciones de Uso.</p>
                <p>A no ser que se especifique explícitamente lo contrario, toda nueva característica, mejora o introducción de nuevas propiedades en el Servicio deberá estar sujeta a los Términos y Condiciones de Uso. El uso que hagas de un servicio particular de <strong>Rapimercado</strong> también podrá estar sujeto a las directrices y políticas aplicables a dicho servicio que podrán ser publicadas y modificadas periódicamente por <strong>Rapimercado</strong>. Todas estas directivas y políticas están incluidas a modo de referencia en los Términos y Condiciones de Uso. En caso de contradicción entre los Términos y Condiciones de Uso y cualquier directiva o política adicional, prevalecerán los Términos y Condiciones de Uso.</p>
                <p>CUALQUIER PERSONA QUE NO ACEPTE ESTOS TÉRMINOS Y CONDICIONES DE USO, LOS CUALES TIENEN UN CARÁCTER OBLIGATORIO Y VINCULANTE, DEBERÁ ABSTENERSE DEL USO DEL SITIO Y/O LOS SERVICIOS.</p>

                <p>DEBES leer, entender y aceptar todas las condiciones establecidas en los Términos y Condiciones de Uso así como en los demás documentos incorporados a los mismos por referencia, previo a tu inscripción como Usuario de <strong>Rapimercado</strong>.</p>



                <h3>2.	ÁMBITO DE RESPONSABILIDAD</h3>
                <p><strong>Rapimercado</strong> es una plataforma digital de compraventa que permite a los Usuarios ofrecer, vender y comprar prácticamente de todo, en una amplia variedad de formatos de precio y ubicaciones.</p>
                <p><strong>Rapimercado</strong> no posee ninguno de los artículos en venta o vendidos a través de nuestra plataforma, y no está involucrado en la transacción real entre Compradores y Vendedores. El contrato de compraventa se hace directamente entre el Comprador y el Vendedor. </p>
                <p><strong>Rapimercado</strong> no interviene en el perfeccionamiento de las transacciones que realices con otros Usuarios ni en las condiciones que estipulen para las mismas, por ello no será responsable respecto de la existencia, calidad, cantidad, estado, integridad o legitimidad de los bienes ofrecidos, adquiridos, donados o cedidos, así como de tus habilidades y experiencia para intercambiar bienes o de la veracidad de los datos personales ingresados por otros Usuarios. Conoces y aceptas que al realizar operaciones con otros Usuarios lo haces bajo tu propio riesgo. En ningún caso <strong>Rapimercado</strong> será responsable por lucro cesante, o por cualquier otro daño y/o perjuicio que hayas podido sufrir, debido al uso de artículos intercambiados o de las transacciones realizadas o no realizadas por artículos publicados a través de <strong>Rapimercado</strong></p>
                <p>Podemos orientar acerca de precios, gastos de envío, y otras cuestiones en nuestros Servicios, si bien dichas orientaciones se realizan a título informativo, por lo que puedes optar por no seguirlas. <strong>Rapimercado</strong> no revisa los anuncios de los Usuarios o su contenido. Aunque podemos ayudar a facilitar la resolución de controversias a través de diversos programas, <strong>Rapimercado</strong> ni controla ni garantiza, la existencia, calidad, seguridad o legalidad de los artículos anunciados; la veracidad o exactitud de los contenidos, anuncios o valoraciones de los Usuarios; la capacidad de los Vendedores para vender artículos; la capacidad de los Compradores para pagar los artículos; o que un Comprador o Vendedor llegarán a formalizar una transacción o a devolver un artículo.</p>
                <p><strong>Rapimercado</strong> te recomienda actuar con prudencia y sentido común al momento de realizar operaciones con otros Usuarios. Debes tener presentes, además, los riesgos de contratar con menores o con Usuarios que se valgan de una identidad falsa. <strong>Rapimercado</strong> no será responsable por la realización de transacciones que efectúes con otros Usuarios basadas en la confianza depositada en el historial de los Usuarios, el sistema o los Servicios brindados por <strong>Rapimercado</strong>.</p>

                <p>En caso que inicies cualquier tipo de reclamo o acciones legales contra otro u otros Usuarios, tú y cada uno de los Usuarios involucrados en dichos reclamos o acciones eximen de toda responsabilidad a <strong>Rapimercado</strong> y a sus directores, gerentes, empleados, agentes, operarios, representantes y apoderados. </p>
                <p>Aceptas y reconoces expresamente que no asumiremos ninguna responsabilidad por daños, ni directo ni indirecto, incluidos, entre otros, daños por pérdida de beneficios, fondo de comercio, datos u otras pérdidas intangibles, incluso aunque hubiéramos sido avisados de la posibilidad de estos daños, derivados de: (i) el uso o la incapacidad de usar el servicio o cualquier producto mostrado en el servicio; (ii) el gasto de adquisición de productos sustitutivos y servicios derivados de cualquier producto, datos, información o servicios comprados o adquiridos o mensajes recibidos o transacciones introducidas a través o desde el servicio; (iii) acceso no autorizado a o alteración de tus transmisiones o datos; (iv) declaraciones o conducta de terceras partes en el servicio; o (v) cualquier otro asunto relacionado con el servicio o productos mostrados o vendidos a través del servicio.</p>
                <p>Toda nuestra responsabilidad y tu recurso exclusivo no deberá exceder la cantidad que has pagado por el producto y los servicios pertinentes.</p>

                <h3>3.	TUS OBLIGACIONES DE REGISTRO</h3>
                <p>En consideración con el uso que haces del Servicio, aceptas: (a) proporcionar información veraz, precisa, actual y completa sobre ti mismo, tal según lo requiera el formulario de registro en el Servicio ("Datos de registro") y (b) mantener y actualizar rápidamente los datos de registro para que sigan siendo veraces, precisos y completos.</p>
                <p>Los Servicios sólo están disponibles para personas que tengan capacidad legal para contratar. No podrán utilizar los servicios las personas que no tengan esa capacidad, los menores de edad o Usuarios que hayan sido suspendidos por <strong>Rapimercado</strong>.</p>
                <p>Si proporcionaras información falsa, imprecisa o incompleta o tenemos motivos razonables para sospechar que dicha información es falsa, imprecisa o incompleta, tendremos derecho a suspender o cerrar tu cuenta y a rechazar cualquier uso futuro del servicio (o cualquier parte del mismo).</p>

                <h3>4.	CUENTA, CONTRASEÑA Y SEGURIDAD</h3>
                <p>Te asignaremos una cuenta y una contraseña tras finalizar el proceso de registro en el Servicio. Es tu responsabilidad mantener la confidencialidad de la contraseña y la cuenta y eres el único responsable de todas las actividades que se lleven a cabo con tu contraseña y tu cuenta. Te comprometes a notificarnos de inmediato en caso de que se produzca un uso no autorizado de tu cuenta o tu contraseña o cualquier otra violación de la seguridad. No podemos asumir y no asumiremos ninguna responsabilidad por cualquier daño o pérdida que se produzca como resultado del incumplimiento por tu parte de este apartado.</p>

                <h3>5.	POLÍTICA DE PRIVACIDAD</h3>
                <p>Los datos de registro y otra información determinada sobre ti están sujetos a las siguientes condiciones:</p>
                <p>Aceptas que podemos acceder, conservar y publicar tu información de cuenta y tu Contenido: (a) a nuestras empresas afiliadas con el objetivo de proporcionar el Contenido tanto a ti como a otros de una manera eficiente; (b) con el objetivo de administrar de forma óptima tu cuenta de acuerdo con nuestros procedimientos operativos estándar; y (c) si la ley lo requiere o a nuestro leal saber y entender algún acceso, conservación o publicación de este tipo es razonablemente necesario para: (i) cumplir los procesos legales; (ii) aplicar los Términos y Condiciones de Uso; (iii) responder a demandas de que algún contenido viola los derechos de terceras partes; (iv) responder a sus solicitudes para el servicio de atención al cliente; o (v) proteger los derechos, propiedad o seguridad del Servicio, a sus Usuarios y a nuestra comunidad en general. Podremos registrar tus mensajes dirigidos a nuestro servicio de atención al cliente y recoger toda información relacionada en la interacción entre tú y <strong>Rapimercado</strong> y entre tú y demás Usuarios de <strong>Rapimercado</strong>.</p>
                <p><strong>Rapimercado.com</strong> podrá utilizar cookies para facilitar la navegación en su página web y conocer las preferencias del Usuario permitiendo su reconocimiento. No obstante, el Usuario podrá configurar su equipo para aceptar o no las cookies que recibe conforme a las instrucciones que figuran en nuestra Política de Cookies de <strong>Rapimercado.com</strong>.</p>

                <h3>6.	DERECHO DE DESISTIMIENTO</h3>
                <p>Como Comprador, tienes derecho a desistir de una compra en cualquier momento, antes de que tu pedido haya sido despachado al transportista por parte del Vendedor.  Esta acción no tiene ningún tipo de penalidad y deberá ser aceptada por todos los Vendedores sin excepción.  Generalmente un pedido es despachado por el Vendedor en un lapso de un (1) día hábil, aunque esto no excluye que en algunos casos el pedido pueda ser despachado antes o después de éste plazo.  Si el artículo ya fue despachado y aun deseas desistir de la compra, deberás hacer uso del Derecho de Devolución cuando lo recibas en tu domicilio.</p>

                <h3>7.	DERECHO DE DEVOLUCIÓN</h3>
                <p>Como Comprador, tienes la protección de nuestra Compra Segura al permitirte reportar la devolución de un artículo dentro de un plazo de siete (7) días naturales a partir del día de la recepción del artículo en tu domicilio.  Durante éste periodo de tiempo podrás revisar que el artículo cumpla con las especificaciones y descripciones del artículo que compraste en nuestra plataforma.  </p>
                <p>Si por cualquier razón, decides devolver el artículo, debes comunicarte con ayuda@rapimercado.com para recibir las instrucciones de devolución del artículo. Cualquier omisión de las instrucciones de devolución te hará perder el derecho de protección de la Compra Segura. Recuerda que mientras el artículo esté en tu poder, tu eres el responsable de que éste se conserve en las mismas condiciones como lo recibiste. Si el artículo en devolución fue alterado respecto al estado original como se entregó, se presenta en embalaje distinto al original, o se entrega incompleto; éstos podrán ser motivos para rechazar la devolución o agregar un cargo extra de reabastecimiento. </p>
                <p>Una vez recibas la confirmación de devolución por parte del Vendedor, el artículo deberás devolverlo en un periodo no mayor a siete (7) días naturales.</p>
                <p>Cuando el Vendedor confirme haber recibido el artículo devuelto por parte del Comprador, el reembolso será devuelto al Comprador por parte de <strong>Rapimercado</strong> durante un plazo máximo de quince (15) días hábiles.</p>
                <p>Como Vendedor, debes aceptar la devolución del artículo por parte del Comprador durante el plazo válido de la Compra Segura y hacerte cargo de los costos de envío de entrega y devolución.  De acuerdo a la Ley de Protección al Consumidor, el Vendedor debe hacerse cargo de éstos gastos de envío.</p>
                <p>Si ya pasaron más de siete (7) días naturales después que el Comprador haya recibido el artículo, éste ya no podrá ser reportado para devolución. </p>

                <h3>8.	DERECHO DE GARANTIA </h3>
                <p>Como Comprador, tienes la protección de garantía del artículo por parte del Vendedor, por un periodo mínimo de tres (3) meses a partir de su fecha de recepción del artículo, de acuerdo a la Ley de Protección del Consumidor.  Este periodo puede ser superior dependiendo del estado del artículo y las condiciones de garantía que ofrezca el Vendedor.   Debes informarte previo a adquirir un artículo, las condiciones de garantía que ofrece el Vendedor.  </p>
                <p>La garantía de compra protegerá al Comprador ante casos donde el artículo no presente las facultades operativas del artículo bajo las cuales éste fue ofertado y vendido por el Vendedor.  Si el Vendedor omitió indicar el estado operativo del artículo vendido, se interpreta que el artículo vendido se encuentra en buenas condiciones de funcionamiento.</p>
                <p>La garantía no cubrirá artículos reclamados bajo éstas condiciones, pero sin limitarse a: (i) que fueron utilizados u operados con descuido o uso inadecuado, (ii) que fueron utilizados u operados sin haber seguido las instrucciones de uso correcto del fabricante, (iii) que fueron utilizados u operados para un fin o propósito distinto del que fue fabricado, (iv) que fueron modificados o alterados en su estructura (vi) suministros o partes consumibles agotados, derivados de su uso convencional.</p>
                <p>Como Vendedor debes ofrecer en cada operación de venta, al menos 3 meses de garantía sin importar que el artículo sea nuevo, usado o reconstruido.  Este periodo puede ser superior, pero no obligatorio, de acuerdo a las condiciones del artículo y tus propias políticas de venta. El artículo sujeto a reclamo de garantía lo podrás reparar, reemplazar completamente o aceptar su devolución y entregar reintegro del importe cobrado. </p>
                <p><strong>Rapimercado</strong> no suministra la cobertura de garantía de los artículos que se comercializan en su plataforma. Únicamente se limita a velar porque los bienes y artículos sean intercambiados bajo las mejores condiciones de comercio sostenible, haciendo valer el respeto de los estatutos de la Ley de Protección al Consumidor del país. <strong>Rapimercado</strong> no es responsable por los gastos o pérdidas derivadas de anomalías o desacuerdos resultantes de la resolución de garantía entre Compradores y Vendedores.</p>

                <h3>9.	IMPUESTOS</h3>
                <p>Dado que <strong>Rapimercado</strong> no tiene ningún tipo de participación e intervención en el perfeccionamiento del contrato de compra-venta entre Compradores y Vendedores no es responsable del incumplimiento de las normas fiscales que apliquen en Guatemala como resultado de las operaciones que realicen los usuarios. </p>
                <p>El usuario Comprador debe exigir boleta o factura fiscal al vendedor como comprobante de la operación para futuros reclamos y disposiciones legales.</p>
                <p>Es responsabilidad exclusiva de todo usuario Vendedor extender la boleta o factura fiscal por cada operación de venta que realice de acuerdo a las normas y políticas fiscales del país. El usuario Vendedor deberá responder exclusivamente ante cualquier incumplimiento de la normativa fiscal de las operaciones que realice.</p>
                <p><strong>Rapimercado</strong> extenderá factura fiscal en formato electrónico por cada operación de venta que realice única y exclusivamente por concepto de los servicios de promoción de venta y logística.</p>

                <h3>10.	MEDIOS DE PAGO</h3>
                <p>Como Vendedor deben contar en todo momento con una cuenta bancaria válida asociada a tu cuenta de Usuario para poder percibir el monto de tus ventas.</p>
                <p>Este pago se hará efectivo entre los once (11) y quince (15) días hábiles posteriores a la entrega del artículo. </p>

                <h3>11.	TARIFAS DEL SERVICIO </h3>
                <p>No es requerido ningún pago de membrecía por registrarse en <strong>Rapimercado</strong>.  Esto significa que la inscripción dentro de nuestra plataforma es totalmente gratuita.   </p>
                <p>Como Vendedor, debes pagar a <strong>Rapimercado</strong> una comisión por la venta cuando la operación o transacción se complete.   Esta comisión incluye el cargo de gestión por nuestros servicios operativos y administrativos, así como el cargo por el cobro contra entrega del artículo.  La comisión es del 9% sobre el valor que esperas recibir del artículo a vender.</p>
                <p>Adicionalmente, cada operación de venta está sujeta al cargo del envío para la entrega del artículo. Este cargo para la tarifa básica es de Q. 25 por cada operación de venta.</p>
                <p>El incumplimiento del pago de la comisión y cargo por envío puede estar sujeto a cancelación temporal o permanente de la cuenta y correspondientes acciones judiciales necesarias para obtener el pago suministrado por servicios. </p>
                <p>Como Comprador, únicamente debes pagar el monto del precio anunciado en la página del artículo. Esto significa que no debes pagar ningún tipo de cobros extras requeridos por algún Vendedor. Este pago lo debes realizar al momento de recibir el artículo en tu domicilio. </p>
                <p><strong>Rapimercado</strong> se reserva el derecho de modificar, cambiar, agregar, o eliminar las tarifas vigentes, en cualquier momento, lo cual será notificado a nuestros Usuarios, por la vía oficial correspondiente.</p>
                
                <h3>12.	ARTÍCULOS PROHIBIDOS</h3>
                <p>Aunque en <strong>Rapimercado</strong> es posible vender prácticamente cualquier artículo, mantener la seguridad de nuestra comunidad es una responsabilidad que nos tomamos muy en serio. </p>
                <p>Como Vendedor eres el responsable exclusivo de la legalidad y legitimidad de los artículos que ofreces. Deberás cumplir con todas las obligaciones regulatorias pertinentes y contar con los registros, habilitaciones, permisos y/o autorizaciones exigidos por la normativa aplicable del país para la venta de los bienes y servicios ofrecidos. <strong>Rapimercado</strong> no asume ningún tipo de responsabilidad por la existencia en nuestra plataforma de artículos que infrinjan las políticas o cualquier ley o resolución judicial vigente.</p>
                <p>La siguiente lista enumera las categorías de artículos que no están permitidas en <strong>Rapimercado</strong>:</p>

                <ol>
                    <li>Armas, municiones y material explosivo</li>
                    <li>Estupefacientes y sustancias prohibidas</li>
                    <li>Productos fuera del comercio</li>
                        <ul>
                            <li>Artículos prohibidos por la ley</li>
                            <li>Propiedad robada</li>
                            <li>Productos distribuidos con fines sociales o humanitarios</li>
                            <li>Productos alimenticios o cosméticos vencidos.</li>
                        </ul>
                    <li>Medicamentos bajo receta o que requieran prescripción médica.</li>
                    <li>Productos que violan derechos de propiedad intelectual.</li>
                        <ul>
                            <li>Copias no autorizadas</li>
                            <li>Réplicas y falsificaciones</li>
                            <li>Software pirata</li>
                        </ul>
                    <li>Complementos nutricionales que contengan sustancias prohibidas </li>
                    <li>Órganos y miembros del cuerpo humano</li>
                    <li>Flora, fauna y derivados</li>
                    <li>Fuegos artificiales</li>
                    <li>Productos y servicios financieros</li>
                    <li>Todo tipo de servicios sin importar su naturaleza</li>
                    <li>Todo tipo de servicios, productos y contenidos sexuales para adultos</li>
                    <li>Todo tipo de ropa íntima usada incluyendo calcetines, calcetas o similares.</li>
                    <li>Publicaciones que violen derechos de propiedad intelectual</li>
                    <li>Documentos legales y personales</li>
                    <li>Patrimonio histórico, cultural, arqueológico y paleontológico</li>
                    <li>Programas o servicios para hackear dispositivos electrónicos</li>
                    <li>Loterías y rifas</li>
                    <li>Publicaciones con fines distintos a la venta de un producto</li>
                    <li>Manuales, cursos en formato impreso o digital y guías de estudio</li>
                    <li>Productos sin homologación o autorización de organismos estatales</li>
                    <li>Seguros</li>
                    <li>Vehículos automotores de todo tipo excepto motocicletas </li>
                    <li>Artículos que no puedan ser trasportados por un camión de 2 ejes (10 toneladas)</li>
                    <li>Entradas para espectáculos fuera de la ley</li>
                    <li>Productos inflamables prohibidos por el transportista</li>
                    <li>Preventa de productos</li>
                    <li>Millas o puntos de compañías aéreas</li>
                    <li>Venta y arrendamiento de bienes inmuebles</li>
                    <li>Tarjetas de crédito, débito y giros postales</li>
                    <li>Artículos de entrega electrónica o digital</li>
                    <li>Artículos que fomentan y facilitan actividades ilegales</li>
                </ol>

                <p><strong>Rapimercado</strong>, podrá finalizar en todo momento las publicaciones que no cumplan con las políticas aquí mencionadas y trasladar a los organismos competentes información personal asociada con la cuenta del Usuario que infrinja tales políticas.</p>
                <p>Mediante la aceptación de los Términos y Condiciones de Uso en el momento de la inscripción del Usuario, aceptas las políticas aquí contenidas.</p>

                
                <h3>13.	MODIFICACIONES DEL SERVICIO</h3>
                <p>Nos reservamos el derecho a modificar o suspender en cualquier momento, de manera temporal o permanente, el Servicio (o cualquier parte del mismo) con o sin preaviso. Aceptas que no asumiremos ninguna responsabilidad frente a ti o frente a terceras partes por ninguna modificación, suspensión o interrupción del Servicio o de las pérdidas económicas y materiales que puedas incurrir por éstas.</p>

                <h3>14.	ENLACES</h3>
                <p>El Servicio de nuestra plataforma o terceras partes pueden proporcionar enlaces a otros recursos o páginas de internet. Dado que no tenemos control sobre estas páginas y recursos, aceptas y reconoces que no asumimos ninguna responsabilidad por la disponibilidad de estos recursos o páginas externos y no aprobamos ni somos responsables por los materiales o servicios que estén disponibles en estas páginas y recursos o a partir de ellos. Además, reconoces y aceptas que no seremos responsables, ni directa ni indirectamente, por cualquier pérdida o daño causado o presuntamente causado por o en relación con el uso o la confianza en cualquiera de estos servicios o materiales disponibles en estos recursos o páginas o a partir de ellos.</p>
                <p>Así mismo está prohibida la adición de todo tipo de enlaces o hipervínculos en las distintas secciones de las publicaciones de artículos.   Toda publicación que incluya éstos enlaces será bloqueada o eliminada por nuestra plataforma.</p>

                <h3>15.	INDEMNIZACIÓN</h3>
                <p>Aceptas indemnizarnos y eximirnos de toda responsabilidad a nosotros y a nuestras empresas subsidiarias, filiales, agentes y otros socios, minoristas y empleados respecto a cualquier reclamación o demanda, incluidos los honorarios moderados de abogados, realizada por terceros debido a como consecuencia del Contenido que envíes, publiques o transmitas a través del Servicio, tu uso del Servicio o relación con el mismo, tu violación de los Términos y Condiciones de Uso o de derechos de otros.</p>

                <h3>16.	AVISO LEGAL</h3>
                <p>Aceptas y reconoces expresamente que:</p>
                <p>Utilizas el servicio bajo tu propia cuenta y riesgo. El servicio es proporcionado "tal y como se encuentra" y "según disponibilidad": en la mayor medida posible permitida por la ley aplicable, declinamos expresamente la responsabilidad de toda garantía, condiciones y otros términos de cualquier tipo, tanto expresos como implícitos, incluidos, entre otros, cualquier condición implícita de comerciabilidad, calidad satisfactoria o adecuación para un determinado objetivo.</p>
                <p>No ofrecemos ninguna garantía ni representación de que (i) el servicio satisfará tus necesidades, (ii) el servicio será ininterrumpido, oportuno, seguro o sin errores, (iii) los resultados que puedan obtenerse del uso del servicio serán precisos o fiables (iv) y la calidad de los artículos que compres u obtengas a través del servicio cumplirá tus expectativas.</p>
                <p>Ninguna recomendación o información, tanto oral como escrita, que obtengas de nosotros o a través/del servicio creará ninguna garantía u otra obligación que no esté expresamente establecida en los Términos y Condiciones de Uso.</p>


                <h3>17.	CANCELACIÓN DE SERVICIO</h3>
                <p>Aceptas que podremos cancelar, a nuestra discreción, tu contraseña, cuenta (o cualquier parte de ella) o el uso del Servicio y eliminar o desechar cualquier Contenido del Servicio, por cualquier motivo, incluidos, entre otros, la falta de uso o si creemos que has violado o actuado de manera contraria a la letra o el espíritu de los Términos y Condiciones de Uso. Aceptas que la cancelación de tu acceso al Servicio bajo cualquier disposición de estos Términos y Condiciones de Uso podrá efectuarse sin previo aviso y reconoces y aceptas que podremos desactivar o eliminar tu cuenta de inmediato.</p>
                <p>Toda cuenta que no haya sido utilizada por más de un año, podrá ser eliminada y todos los contenidos de ésta podrán ser borrados de manera permanente en consonancia con las directivas y políticas de <strong>Rapimercado</strong>. Aceptas que no asumimos ninguna responsabilidad por eliminar o no guardar cualquier contenido mantenido o transmitido por el Servicio. Además, aceptas que no asumimos ninguna responsabilidad frente a ti ni frente a terceras partes por la cancelación de tu acceso al Servicio.</p>

                <h3>18.	NOTIFICACIONES Y AVISOS</h3>
                <p>El único medio de comunicación oficial para notificar todas nuestras comunicaciones del servicio será a través de correo electrónico. El Servicio también podrá avisarte sobre modificaciones en los Términos y Condiciones de Uso u otros asuntos mediante la publicación de avisos o enlaces a avisos generalmente en el Servicio.</p>
                <p>Es tu responsabilidad asegurarte que tu proveedor de correo electrónico no bloquee ni envíe a la bandeja de Spam o correo basura los correos enviados desde <strong>Rapimercado</strong> y sus dominios oficiales.</p>
                <p>Damos por hecho que cualquier correo enviado efectivamente desde <strong>Rapimercado</strong> a tu buzón representa la entrega irrefutable de nuestras notificaciones.</p>

                <h3>19.	RESOLUCIÓN DE DISPUTAS</h3>
                <p>Si surge una disputa entre tú y <strong>Rapimercado</strong>, te pedimos que primero te pongas en contacto directo con nosotros para buscar una solución, poniéndote en contacto con Atención al Cliente o través de ayuda@rapimercado.com. Tendremos en consideración las solicitudes razonables para resolver una disputa a través de procedimientos alternativos de resolución de conflictos, tales como la mediación o el arbitraje, como alternativas a los litigios. La Constitución Política de la República de Guatemala facilita a los consumidores la Ley de Protección al Consumidor y Usuario para resolver los litigios de forma amistosa. Los consumidores pueden presentar una reclamación a la Dirección de Atención y Asistencia al Consumidor – DIACO –. Cualquier reclamación, disputa o asunto que surja de o en conexión con estos Términos y Condiciones de Uso se regirán e interpretarán en todos los aspectos por las leyes de Guatemala. Tú y <strong>Rapimercado</strong> acuerdan someterse con carácter exclusivo, a la jurisdicción de los juzgados y tribunales guatemaltecos</p>


            </div>
        )
    }

}

export default Terminos