import React from 'react'

import ProductCard from './ProductCard'
import ProductList from './ProductList'
import CategorieCard from './CategorieCard'
import { List } from 'tabler-react'
import Axios from 'axios'
import Slider from "react-slick";
import { service_path } from './conts'
import  Pagination  from './Pagination'
import { ReactComponent as Icon_list } from './assets/icon_list.svg'
import { ReactComponent as Icon_menu } from './assets/icon_menu.svg'

class Category extends React.Component{
    constructor(props){
        console.log(props)
        super(props)
        this.state = {
            category: props.match.params.category,
            list: false,
            page: 0,
            totalPage: 1,
            first: true,
            last: true,
            listproduct: [],
            name: "",
        }

        this.changeHandle = this.changeHandle.bind(this)
        this.resize = this.resize.bind(this);
        this.getDataProducto = this.getDataProducto.bind(this);
        this.changePage = this.changePage.bind(this);
    }

    componentDidMount(){

        Axios.get(service_path + "/categoria/all")
        .then(response => {
            this.setState({
                categories: response.data.map((item, index) => {
                    return(
                        <CategorieCard key={index} data={item} /> 
                    )
                }),
                loader: false
            })
        })
        this.getDataProducto();

        window.addEventListener('resize', this.resize)
        this.resize();
    }

    componentWillReceiveProps(props){
        window.location.reload()
    }

    changePage(page){
        this.setState({
            page: page,
        }, () => {
            this.getDataProducto()
        })
    }

    getDataProducto(){
        Axios.get(service_path + "/producto/categoria/"+this.state.category + "?page=" + this.state.page)
        .then(response => {
            let {data} = response;
            this.setState({
                first: data.first,
                last: data.last,
                totalPage: data.total,
                name: data.nombre,
                listproduct: data.lista
            })
            this.loadProducts(data.lista, this.state.list)
        });
    }

    resize() {
        if(window.innerWidth > 992){
            this.setState({
                settings: {
                    dots: false,
                    infinite: false,
                    speed: 500,
                    slidesToShow: 12,
                    slidesToScroll: 1,
                    arrows: true,
                    swipeToSlide: true,
                }
            })
        }
        else if(window.innerWidth > 639){
            this.setState({
                settings: {
                    dots: false,
                    infinite: false,
                    speed: 500,
                    slidesToShow: 8,
                    slidesToScroll: 1,
                    arrows: true,
                    swipeToSlide: true,
                }
            })
        }
        else{
            this.setState({
                settings: {
                    dots: false,
                    infinite: true,
                    speed: 500,
                    slidesToShow: 4,
                    slidesToScroll: 1,
                    arrows: true,
                    swipeToSlide: true,
                }
            })
        }
    }

    changeHandle(event){
        const list = event;
        this.setState({
            list: list  
        }, () => {
            
            this.loadProducts(this.state.listproduct, list)
        
        })
        
    }

    loadProducts(products, list){
        console.log("Estado al cargar: ", list)

        let productos;

        if(list){
            productos = <List.Group>
                {
                    products.map((item, index) => {
                        return(
                            <ProductList key={index} data={item} />
                        )
                    })
                }   
                </List.Group>
        }
        else{
            productos =  products.map((item, index) => {
                return(
                    <ProductCard key={index} data={item} />
                )
            })
        }
        

        this.setState({
            products: productos
        })
    }
 
    render() {
        var settings = this.state.settings;
        let { list } = this.state;
        return(
            <div className="container">
                <Slider {...settings}>
                    {this.state.categories}
                </Slider>
                <br></br>
                <div className="d-flex justify-content-between align-items-center">
                <h2>{this.state.name}</h2>
                    <label>
                        Ver en lista
                        <Icon_menu className={list ? "icon-grid": "icon-grid selected"} onClick={(e) => {
                                e.preventDefault();
                                this.changeHandle(false)
                            }} />
                        <Icon_list className={list ? "icon-grid selected": "icon-grid"} onClick={(e) => {
                                e.preventDefault();
                                this.changeHandle(true)
                            }} />
                    </label>
                </div>
                
                <div className="col-md-12 row row-cards justify-content-center">
               
                {this.state.products}
                
                    
                </div>
                <Pagination current={this.state.page} total={this.state.totalPage} isFirst={this.state.first} isLast={this.state.last} changePage={this.changePage} />
            </div>
            
        )
    }
}

export default Category