import React from 'react'
import cookie from 'react-cookies'
import Axios from 'axios'
import { prev_url, service_path } from './conts'
import { Table } from 'react-bootstrap'
import Pagination from './Pagination'

class Mensajes extends React.Component{
    
    constructor(){
        super()

        this.state = {
            hilos: [],
            token: cookie.load("token"),
            page: 0,
            totalPage: 1,
            first: true,
            last: true,
        }

        this.onClick = this.onClick.bind(this);
        this.getData = this.getData.bind(this)
        this.changePage = this.changePage.bind(this)
    }

    componentDidMount(){
        if(this.state.token === undefined) window.location.href = "/"

        this.getData()
    }

    getData(){
        Axios.get(
            service_path + "/mensaje/hilos?page=" + this.state.page,
            {
                headers: {
                    "Authorization": "Bearer " + this.state.token
                }
            }
        ).then((res) => {
            let { paginacion, hilos } = res.data;

            this.setState({
                hilos: hilos,
                first: paginacion.first,
                last: paginacion.last,
                totalPage: paginacion.totalPages,
            }, () => {
                window.scrollTo(0,0)
            })
        })
    }

    onClick(hilo){
        window.location.href = prev_url +"message/" + hilo;
        
    }

    changePage(page){
        this.setState({
            page: page,
        }, () => {
            this.getData()
        })
    }

    render(){
        let mensajes = "";
        if(this.state.hilos.length > 0){
            mensajes = this.state.hilos.map((item, index) =>{
                return(
                    <tr key={index} onClick={(e) => {
                            e.preventDefault();
                            this.onClick(item.hilo.llave)
                        }}>
                        <td>
                          <h4>{item.usuario.nombre}</h4>
                          <p>{item.ultimo.mensaje}</p>  
                        </td>
                           
                    </tr>
                )
            })
        }
        return(
            <div className="container">
                <h1>Mensajes</h1>

                <Table striped bordered>
                    <tbody>
                        {
                            mensajes
                        }
                    </tbody>
                </Table>
                <Pagination current={this.state.page} total={this.state.totalPage} isFirst={this.state.first} isLast={this.state.last} changePage={this.changePage} />
            </div>
        )
    }

}

export default Mensajes