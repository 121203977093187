import React from 'react';
import cookie from 'react-cookies';
import Axios from 'axios';
import { service_path } from './conts';
import { Table, Button } from 'react-bootstrap';
import swal from 'sweetalert';
import NuevaDireccion from './NuevaDireccion'

class Direcciones extends React.Component{
    constructor(){
        super();

        this.state = {
            token: cookie.load('token'),
            direcciones: [],
            visible: false,
            deptos: "",
            munis: "",
            dirnombre: "",
            dirdireccion: "",
            dirdepto: "",
            dirmuni: "",
            dirid: 0,
        }

        this.setDefault = this.setDefault.bind(this);
        this.getDirecciones  = this.getDirecciones.bind(this);
        this.hideDireccion = this.hideDireccion.bind(this);
        this.showDireccion = this.showDireccion.bind(this);
        this.changeDepto = this.changeDepto.bind(this);
        this.changeMuni = this.changeMuni.bind(this)
        this.addDireccion = this.addDireccion.bind(this)
        this.changeDir = this.changeDir.bind(this);
        this.editDireccion = this.editDireccion.bind(this)
    }

    changeDir(name, value){
        let campo = "dir"+name;
        this.setState({
   
            [campo]: value
     
        })
    }

    getDirecciones(){
        Axios.get(
            service_path + "/usuario/direcciones",
            {
                headers: {
                    "Authorization": "Bearer " + this.state.token
                }
            }
        ).then((res) => {
            let {data} = res;
            this.setState({
                direcciones: data
            })
        })
    }

    componentDidMount(){
        this.getDirecciones();

        Axios.get(
            service_path + "/usuario/depto",
        ).then((res) => {
            let { data } = res;

            this.setState({
                deptos: data.map((item, index) => {
                    return(
                        <option key={index} value={item.codigoDepartamento}>{item.nombre}</option>
                    )
                })
            })
        })
    }

    changeDepto(value){
        Axios.get(
            service_path + "/usuario/muni/" + value
        ).then(res => {
            let {data} = res;
            console.log(value)
            this.setState({
                munis:  data.map((item, index) => {
                    return(
                        <option key={index} value={item.codigoMunicipio} >{item.nombre}</option>
                    )
                })
            }, () => {
                console.log(this.state)
            })
        })
    }

    changeMuni(value){
        this.setState({
            muni: value
        })
    }


    setDefault(id){
        let data = {};

        Axios.post(
            service_path + "/usuario/direcciones/update/" + id,
            JSON.stringify(data),
            {
                headers: {
                    "Authorization": "Bearer " + this.state.token,
                    "Content-Type": "json/application"
                }
            }
        ).then((res) => {
            let { data } = res;
            this.setState({
                direcciones: data.direcciones
            })
            swal({
                title: "Direccion Principal Modificada",
                text: "Ahora esta es tu dirección principal",
                icon: "success"
            })
        })
    }

    addDireccion(data){
        Axios.post(
            service_path + "/usuario/direcciones",
            JSON.stringify(data),
            {
                headers: {
                    "Authorization": "Bearer " + this.state.token,
                    "Content-Type": "application/json"
                }
            }
        ).then(() => {
            this.setState({
                visible: false
            })
            swal({
                title: "Dirección guardada con éxito",
                text: "Has guardado tu dirección con éxito",
                icon: "success"
            }).then(() => {
                this.getDirecciones()
            })
        }).catch((ex) => {

        })
    }

    showDireccion(){
        this.setState({
            visible: true,
            dirnombre: "",
            dirdireccion: "",
            dirdepto: "",
            dirmuni: "",
            dirid: 0,
        })
    }

    hideDireccion(){
        this.setState({
            visible: false,
        })
    }

    editDireccion(data){
        let { nombre, direccion, depto, muni, codigoDireccion } = data
        
        this.changeDepto(depto.codigoDepartamento)
        this.changeMuni(muni.codigoMunicipio)

        this.setState({
            visible: true,
            dirnombre: nombre,
            dirdireccion: direccion,
            dirdepto: depto.codigoDepartamento,
            dirmuni: muni.codigoMunicipio,
            dirid: codigoDireccion,
        })
    }

    render(){
        let {direcciones, visible, munis, deptos} = this.state
        let dataDir = {
            nombre: this.state.dirnombre,
            direccion: this.state.dirdireccion,
            muni: this.state.dirmuni,
            depto: this.state.dirdepto,
            id: this.state.dirid
        }
        return(
            <div className="container">
                <NuevaDireccion visible={visible} uploadData={this.addDireccion} onClose={this.hideDireccion} 
                    deptos={deptos} munis={munis} changeDepto={this.changeDepto}
                    changeMuni={this.changeMuni} data={dataDir} onChange={this.changeDir}
                />
                <h1>Direcciones <Button variant="danger" onClick={this.showDireccion}>Nueva direccion</Button></h1>
                
                <Table striped bordered>
                    <tbody>
                        {
                            direcciones.map((item, index) => {
                                return(
                                <tr key={index}>
                                    <td>
                                        <h3>{item.nombre}</h3>
                                        <p>{item.direccion}</p>
                                        {
                                            item.defaultComprar ? 
                                                <p>Direccion Principal</p>
                                            :
                                                <Button style={{margin: "0.5rem"}} variant="primary" onClick={(e) => {
                                                    e.preventDefault()
                                                    this.setDefault(item.codigoDireccion)
                                                }}>Hacer Direccion Principal</Button>
                                        }
                                        <Button style={{margin: "0.5rem"}} variant="warning" onClick={(e) => {
                                                e.preventDefault();
                                                this.editDireccion(item);
                                            }} >
                                            Editar
                                        </Button>
                                    </td>
                                </tr>
                                )
                            })
                        }
                    </tbody>
                </Table>
            </div>
        )
    }
}

export default Direcciones;