import React from 'react'
import Axios from 'axios';
import cookie from 'react-cookies';
import CrearCuenta from './CrearCuenta';
import { Button, Table } from 'react-bootstrap';
import Loader from './Loader';
import { service_path } from './conts';
import swal from 'sweetalert';
import EditarCuenta from './EditarCuenta';

class Cuentas extends React.Component{

    constructor(){
        super();

        this.state = {
            loader: false,
            token: cookie.load("token"),
            cuentas: [],
            agregarCuenta: false,
            dataEdit: {
                codigo: "",
                numero: "",
                banco: "",
                tipoCuenta: "",
                moneda: ""
            },
            actualizarCuenta: false,
        }

        this.cargarCuentas = this.cargarCuentas.bind(this);
        this.showAgregar = this.showAgregar.bind(this);
        this.returnCuentas = this.returnCuentas.bind(this);
        this.subirCuenta = this.subirCuenta.bind(this);
        this.changeData = this.changeData.bind(this);
        this.actualizarCuenta = this.actualizarCuenta.bind(this);
    }

    componentDidMount(){
       this.cargarCuentas();
    }

    cargarCuentas(){
        Axios.get(
            service_path + "/cuenta/obtener",
            {
                headers: {
                    'Authorization': "Bearer " + this.state.token
                }
            }
        ).then((res) => {
            this.setState({
                cuentas: res.data.cuentas
            })
        })
    }

    showAgregar(){
        this.setState({
            agregarCuenta: true,
        })
    }

    changeData(name, value){
        const dataEdit = {...this.state["dataEdit"]}

        dataEdit[name] = value;

        this.setState({
            dataEdit: dataEdit
        })
    }

    returnCuentas(){
        this.setState({
            agregarCuenta: false,
            actualizarCuenta: false,
        })
    }

    editCuenta(data){
        console.log(data)
        this.setState({
            actualizarCuenta: true,
            dataEdit: {
                codigo: data.codigoCuenta,
                numero: data.numeroCuenta,
                banco: data.banco.codigoBanco,
                tipoCuenta: data.tipoCuenta.codigoTipoCuenta,
                moneda: data.moneda.codigoMoneda
            }
        })
    }

    actualizarCuenta(){
        this.setState({
            actualizarCuenta: false,
            loader: true,
        })

        let {dataEdit} = this.state

        Axios.post(
            service_path + "/cuenta/actualizar",
            JSON.stringify(dataEdit),
            {
                headers: {
                    'Authorization': "Bearer " + this.state.token,
                    'Content-Type': 'application/json'
                }
            }
        ).then((response) => {
            swal({
                icon: "success",
                title: "Cuenta Actualizada",
                text: "Se ha guardado los datos de tu cuenta"
            }).then(() => {
                this.setState({
                    loader: false
                })
                this.cargarCuentas();
            })            

           
        }).catch((err) => {
            swal({
                icon: "error",
                title: "Ha ocurrido un error",
                text: "Vuelve a intentarlo más tarde"
            }).then(() => {
                this.setState({
                    agregarCuenta: true,
                    loader: false,
                })
            })
            
        })
    }

    subirCuenta(data){
        this.setState({
            agregarCuenta: false,
            loader: true,
        })

        Axios.post(
            service_path + "/cuenta/nueva",
            JSON.stringify(data),
            {
                headers: {
                    'Authorization': "Bearer " + this.state.token,
                    'Content-Type': 'application/json'
                }
            }
        ).then((response) => {
            swal({
                icon: "success",
                title: "Cuenta creada",
                text: "Su cuenta ha sido creada exitosamente"
            }).then(() => {
                this.setState({
                    loader: false
                })
                this.cargarCuentas();
            })            

           
        }).catch((err) => {
            this.setState({
                agregarCuenta: true,
                loader: false,
            })
        })
        
    }

    render(){
        let { loader, agregarCuenta, cuentas, actualizarCuenta, dataEdit } = this.state;

        return(
            <div className="container">
                <Loader visible={loader} />
                <CrearCuenta visible={agregarCuenta} uploadData={this.subirCuenta} back={this.returnCuentas} note={null} />
                <h1>Cuentas <Button variant="danger" onClick={this.showAgregar}>Nueva Cuenta</Button></h1>
                <EditarCuenta visible={actualizarCuenta} uploadData={this.actualizarCuenta} back={this.returnCuentas} 
                    data={dataEdit} onChange={this.changeData} />
                <Table striped bordered>
                    <tbody>
                        {
                            cuentas.map((item, index) => {
                                return(
                                <tr key={index}>
                                    <td>
                                        <h3>{item.banco.nombre}</h3>
                                        <p>{item.tipoCuenta.nombre} - {item.moneda.nombre}</p>
                                        <p>{item.numeroCuenta}</p>
                                        <Button variant="primary" onClick={(e) => {
                                                e.preventDefault();
                                                this.editCuenta(item)
                                            }} >
                                                Actualizar
                                            </Button>
                                    </td>
                                </tr>
                                )
                            })
                        }
                    </tbody>
                </Table>
            </div>
        )
    }

}

export default Cuentas;