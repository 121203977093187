import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import cookie from 'react-cookies';
import Form from 'react-bootstrap/FormControl';
import Axios from 'axios';
import { prev_url, service_path } from './conts';
import swal from 'sweetalert';

class NuevoMensaje extends React.Component{

    constructor(props){
        super(props)

        this.props = props;

        this.state = {
            mensaje: "",
            token: cookie.load("token")
        }

        this.changeMessage = this.changeMessage.bind(this);
        this.sendMessage = this.sendMessage.bind(this)
    }

    changeMessage(e){
        let { value } = e.target;

        this.setState({
            mensaje: value 
        })
    }

    sendMessage(){
        let data = {
            mensaje: this.state.mensaje,
            producto: this.props.producto
        }

        Axios.post(
            service_path + "/mensaje/nuevo",
            JSON.stringify(data),
            {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + this.state.token
                }
            }
        ).then((res) => {
            let data = res;

            window.location.href = prev_url + "message/" + data.llave
        }).catch((err) => {
            console.log(err)
            console.log(err.response)
            swal({
                icon: "error",
                title: "No se ha podido enviar tu mensaje",
                text: "No hemos podido contactar al usuario vendedor en este momento, vuelve a intentarlo mas tarde"
            })
        })
    }

    render(){
        let { mensaje } = this.state;
        let { visible } = this.props
        return(
            <Modal show={visible}>
                <Modal.Header>
                    <Modal.Title>Enviale un mensaje al vendedor</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group>
                            <Form.Label>Mensaje: </Form.Label>
                            <textarea onChange={this.changeMessage} >{mensaje}</textarea>
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={this.props.onClose}>
                        Regresar
                    </Button>
                    <Button variant="primary" onClick={this.sendMessage}>
                        Agregar
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }

}

export default NuevoMensaje;