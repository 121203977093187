import React from 'react'
import CategorieCard from './CategorieCard'
import ProductCard from './ProductCard'
import Axios from 'axios'
import { service_path } from './conts'
import Loader from './Loader'
import Slider from "react-slick";
import Pagination from './Pagination'
import banner2021 from './assets/banner2021.png'
import cookie from 'react-cookies'
import tutorial from './assets/tutorial.jpeg'
import { Modal, Button } from 'react-bootstrap'
import Banner from './templates/Banner'
import { List } from 'tabler-react'
import ProductList from './ProductList'
import { ReactComponent as Icon_list } from './assets/icon_list.svg'
import { ReactComponent as Icon_menu } from './assets/icon_menu.svg'
import { isMobile } from 'react-device-detect'
import AlertaNuevo from './AlertaNuevo'
import Anuncio from './Anuncio'

class Home extends React.Component{

    constructor(){
        super()
        this.state = {
            categories: [],
            products: "",
            list: false,
            loader: true,
            page: 0,
            totalPage: 1,
            first: true,
            last: true,
            settings: {
                dots: false,
                infinite: false,
                speed: 500,
                slidesToShow: 10,
                slidesToScroll: 1,
                arrows: true,
                swipeToSlide: true
            },
            tutorial: cookie.load("tutorial"),
            primeraVisita: cookie.load("primeraVisita"),
            token: cookie.load("token"),
            showTutorial: false,
            showAlertaVendedor: false,
        }

        this.getDataProducto = this.getDataProducto.bind(this);
        this.changePage = this.changePage.bind(this);
        this.resize = this.resize.bind(this)
        this.hideTutorial = this.hideTutorial.bind(this)
        this.changeHandle = this.changeHandle.bind(this)
        this.handleScroll = this.handleScroll.bind(this)
        this.hideAlerta = this.hideAlerta.bind(this)
    }

    componentDidMount(){
        window.addEventListener('resize', this.resize)
        this.resize();

        let { tutorial, showTutorial, primeraVisita, token } = this.state

        console.log(tutorial);


        if(tutorial === undefined){
            this.setState({
                showTutorial: true
            })

            cookie.save("tutorial", true);
        }else{
            console.log("Entro", primeraVisita, token)
            if(primeraVisita === undefined && token === undefined){
                this.setState({
                    showAlertaVendedor: false
                })
            }
        }


        Axios.get(service_path + "/categoria/all")
            .then(response => {
                this.setState({
                    categories: response.data.map((item, index) => {
                        return(
                            <CategorieCard key={index} data={item} /> 
                        )
                    }),
                    loader: false
                })
            })

        
        this.getDataProducto();

        if(isMobile){
            window.addEventListener('scroll', this.handleScroll);
            document.getElementById('container-menu').style.paddingTop = "0"
        }
    }

    hideAlerta(){
        this.setState({
            showAlertaVendedor: false
        })
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }

    handleScroll(e){
        let { lastScrollTop } = this.state;
   
        var st = window.pageYOffset || document.documentElement.scrollTop; // Credits: "https://github.com/qeremy/so/blob/master/so.dom.js#L426"
        if (st > lastScrollTop){
           document.getElementById('container-menu').style.paddingTop = "2.5rem"
        } else {
            document.getElementById('container-menu').style.paddingTop = "0"
        }
        lastScrollTop = st <= 0 ? 0 : st; 

        this.setState({
            lastScrollTop: lastScrollTop
        })
    }

    hideTutorial(){
        this.setState({
            showTutorial: false
        })
    }

    resize() {
        if(window.innerWidth > 992){
            this.setState({
                settings: {
                    dots: false,
                    infinite: false,
                    speed: 500,
                    slidesToShow: 12,
                    slidesToScroll: 1,
                    arrows: true,
                    swipeToSlide: true
                }
            })
        }
        else if(window.innerWidth > 639){
            this.setState({
                settings: {
                    dots: false,
                    infinite: false,
                    speed: 500,
                    slidesToShow: 8,
                    slidesToScroll: 1,
                    arrows: true,
                    swipeToSlide: true
                }
            })
        }
        else{
            this.setState({
                settings: {
                    dots: false,
                    infinite: true,
                    speed: 500,
                    slidesToShow: 4,
                    arrows: true,
                    swipeToSlide: true
                }
            })
        }
    }

    changePage(page){
        this.setState({
            page: page,
        }, () => {
            this.getDataProducto()
            let banner = document.getElementsByClassName("banner")
            let alto = banner[0].clientHeight
            window.scrollTo(0, alto)
        })
    }

    getDataProducto(){
        Axios.get(
            service_path + "/producto/recientes?page=" + this.state.page
        ).then((response) => {
            let {data} = response;
            
            let lista = <List.Group>
            {
                data.lista.map((item, index) => {
                    return(
                        <ProductList key={index} data={item} />
                    )
                })
            }   
            </List.Group>

            this.setState({
                first: data.first,
                last: data.last,
                totalPage: data.total,
                products: data.lista.map((item, index) => {
                    return(
                        <ProductCard key={index} data={item}  />
                    )
                }),
                productsList: lista
            })
        })
    }

    changeHandle(event){
        const list = event;
        this.setState({
            list: list  
        })
        
    }

    render(){

        let { settings, showTutorial, list, showAlertaVendedor } = this.state;
        console.log("alerta nuevo vendedor: ", showAlertaVendedor)
        return(
          
                
                <div className="container" id="container">
                    <Loader visible={this.state.loader} />
                    <img src={banner2021} className="banner" />
                    <Anuncio />
                    <Slider {...settings} >
                       {this.state.categories}
                    </Slider>
                    <AlertaNuevo visible={showAlertaVendedor} onClose={this.hideAlerta} />
                    <br></br>
                    <div className="d-flex justify-content-between align-items-center">
                        <h1>Productos recientes</h1>
                        <label>
                            Ver en lista
                            <Icon_menu className={list ? "icon-grid": "icon-grid selected"} onClick={(e) => {
                                    e.preventDefault();
                                    this.changeHandle(false)
                                }} />
                            <Icon_list className={list ? "icon-grid selected": "icon-grid"} onClick={(e) => {
                                    e.preventDefault();
                                    this.changeHandle(true)
                                }} />
                        </label>
                    </div>
                    
                    <div className="col-md-12 row row-cards">
                        {
                            !list ?
                                this.state.products
                            :
                                this.state.productsList
                        }
                    </div>
                    <Pagination current={this.state.page} total={this.state.totalPage} isFirst={this.state.first} isLast={this.state.last} changePage={this.changePage} />
                    <Banner />
                </div>
               
          
        )
    }

}

export default Home