import React from 'react'
import Loader from './Loader'
import Axios from 'axios';
import { prev_url, service_path } from './conts';
import swal from 'sweetalert';
import { Form, Col, Button } from 'react-bootstrap'
import CrearCuenta from './CrearCuenta';
import NuevaDireccion from './NuevaDireccion';
import cookie from 'react-cookies'
import JwtDecode from 'jwt-decode';

class SignUp extends React.Component{

    constructor(props){
        super(props)
        this.state = {
            nombre: "",
            email: "",
            direccion: "",
            password: "",
            confPassword: "",
            numero: "",
            loader: false,
            token: cookie.load("token"),
            showCrearCuenta: false,
            showCrearDireccion: false,
            deptos: "",
            munis: "",
            dirnombre: "",
            dirdireccion: "",
            dirdepto: "",
            dirmuni: "",
            dirid: 0,
            alias: "",
            terminos: false
        }

        this.onChange = this.onChange.bind(this)
        this.onSubmit = this.onSubmit.bind(this)
        this.login = this.login.bind(this)
        this.showAgregarCuenta = this.showAgregarCuenta.bind(this)
        this.subirCuenta = this.subirCuenta.bind(this)
        this.showDireccion = this.showDireccion.bind(this)
        this.changeDepto = this.changeDepto.bind(this)
        this.changeMuni = this.changeMuni.bind(this)
        this.addDireccion = this.addDireccion.bind(this)
        this.returnCuentas = this.returnCuentas.bind(this)
        this.hideDireccion = this.hideDireccion.bind(this)
        this.changeDir = this.changeDir.bind(this)
        this.onChageCheck = this.onChageCheck.bind(this)
    }

    componentDidMount(){
        let { token } = this.state
        
        if(token === undefined){
            Axios.get(
                service_path + "/usuario/depto",
            ).then((res) => {
                let { data } = res;
    
                this.setState({
                    deptos: data.map((item, index) => {
                        return(
                            <option key={index} value={item.codigoDepartamento}>{item.nombre}</option>
                        )
                    })
                })
            })
        }
        else{
            window.location.href = "/"
        }
    }

    onChange(event){
        
        const { name, value } = event.target;
        
        this.setState({
            [name]: value
        })
    }

    onChageCheck(){
        let { terminos } = this.state;

        this.setState({
            terminos: !terminos
        })
    }

    onSubmit(event){
        event.preventDefault();
        let email = this.state.email;

        email = email.replace(/\s/g,''); 
        if(this.state.password === this.state.confPassword){
            const data = {
                nombre: this.state.nombre,
                email: email,
                direccion: this.state.direccion,
                password: this.state.password,
                numero: this.state.numero,
                alias: this.state.alias,
                roles: [
                    "1"
                ]
            }

            this.setState({
                loader: true
            })

            Axios.post(
                service_path+"/auth/signup", 
                JSON.stringify(data), 
                {
                    headers: {
                        'Access-Control-Allow-Origin': '*',
                        'Content-Type': 'application/json'
                    }
                }
            ).then((response) => {
                this.setState({
                    loader: false,
                })

                let { data } = response;

                if(data.success){
                    swal({
                        title: "Te has registrado con éxito", 
                        text: "Gracias por registrarte a Rapimercado, por favor ingresa la dirección a donde quieres que te lleguen tus compras.", 
                        icon: "success"
                    }).then(() => {
                        this.login((err) => {
                            if(err === null){
                                this.showDireccion();
                            }
                            else{
                                swal({
                                    icon: err.icon,
                                    title: err.title,
                                    text: err.text
                                })
                            }
                        })
                    });
                }
                else{
                    swal({
                        icon: "error",
                        title: "No se podido completar el Registro",
                        text: "El correo ingresado ya tiene una cuenta asociada"
                    })
                }
               
            }).catch((error) => {
                console.log(error)
                this.setState({
                    loader: false,
                })
                swal({
                    title: "Opps! algo ha salido mal", 
                    text: "Algo ha salido mal al momento de registrarte, vuelve a intentarlo", 
                    icon: "error"
                });
            })
        }
        else{
            this.setState({
                password: "",
                confPassword: ""
            })
            swal({
                title: "Las contraseñas no coinciden", 
                text: "La confirmación de contraseña no conicide con la contraseña ingresada", 
                icon: "error"
            });
        }
    }

    login(callback){

        let email = this.state.email;

        email = email.replace(/\s/g,'');  

        let data = {
            usuario: email,
            password: this.state.password
        }

        Axios.post(
            service_path+"/auth/login",
            JSON.stringify(data),
            {
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Content-Type': 'application/json'
                }
            }
        ).then((res) => {
            console.log(res.data.accessToken)
            let token = res.data.accessToken

            let decoded = JwtDecode(token);

            cookie.save("token", token,  { path: '/' })
            cookie.save("token_expire", decoded.exp + "000", { path: '/' })
             
            this.setState({
                token: token,
                modal: false
            })

            callback(null)

             
        }).catch((err) => {
            console.log(err);

            let error = {
                icon: "error",
                title: "No has podidio iniciar sesión",
                text: "No se ha podido iniciar sesión: " + data.mensaje
            }

            callback(error);
        })
    }

    showAgregarCuenta(){
        this.setState({
            showCrearCuenta: true
        })
    }

    subirCuenta(data){
        this.setState({
            showCrearCuenta: false,
            loader: true,
        })

        Axios.post(
            service_path + "/cuenta/nueva",
            JSON.stringify(data),
            {
                headers: {
                    'Authorization': "Bearer " + this.state.token,
                    'Content-Type': 'application/json'
                }
            }
        ).then(() => {
            swal({
                icon: "success",
                title: "Cuenta creada",
                text: "Su cuenta ha sido creada exitosamente. Disfruta de todos los beneficios de tu cuenta de Rapimercado."
            }).then(() => {
                window.location.href = "/"
            })            
        }).catch(() => {
            this.setState({
                showCrearCuenta: true,
                loader: false,
            })
        })
        
    }

    showDireccion(){
        this.setState({
            showCrearDireccion: true,
            dirnombre: "",
            dirdireccion: "",
            dirdepto: "",
            dirmuni: "",
            dirid: 0,
        })
    }

    changeDepto(value){
        Axios.get(
            service_path + "/usuario/muni/" + value
        ).then(res => {
            let {data} = res;
            console.log(value)
            this.setState({
                munis:  data.map((item, index) => {
                    return(
                        <option key={index} value={item.codigoMunicipio} >{item.nombre}</option>
                    )
                })
            }, () => {
                console.log(this.state)
            })
        })
    }

    changeMuni(value){
        console.log(value);
    }

    addDireccion(data){
        Axios.post(
            service_path + "/usuario/direcciones",
            JSON.stringify(data),
            {
                headers: {
                    "Authorization": "Bearer " + this.state.token,
                    "Content-Type": "application/json"
                }
            }
        ).then(() => {
            this.setState({
                visible: false,
                showCrearDireccion: false
            })
            swal({
                title: "Dirección guardada con éxito",
                text: "Se ha guardado tu dirección con éxito, ahora agrega una cuenta bancaria para realizarte los depositos de lo que vendas. Puedes omitir este paso si no vas a vender nada.",
                icon: "success"
            }).then(() => {
                this.showAgregarCuenta()
            })
        }).catch((ex) => {

        })
    }

    returnCuentas(){
        swal({
            icon: "success",
            text: "Paso omitido",
            title: "Si decides vender recuerda que se te pedira los datos de tu cuenta bancaria para realizarte tus pagos"
        })
        .then(() => {
            window.location.href = "/"
        })
    }

    hideDireccion(){
        swal({
            icon: "warning",
            text: "Este paso es obligatorio para continuar",
            title: "Accion inválida"
        })
    }

    changeDir(name, value){
        let campo = "dir"+name;
        this.setState({
   
            [campo]: value
     
        })
    }

    render(){
        let {loader, showCrearCuenta, showCrearDireccion, munis, deptos} = this.state
        
        let dataDir = {
            nombre: this.state.dirnombre,
            direccion: this.state.dirdireccion,
            muni: this.state.dirmuni,
            depto: this.state.dirdepto,
            id: this.state.dirid
        }
        
        let note = "Estos datos son unicamente para los usuarios que quieren vender en Rapimercado. Puedes omitir este paso dandole click al botón de cancelar"

        return(
            <div>
                <Loader visible={loader} />
                <CrearCuenta visible={showCrearCuenta} uploadData={this.subirCuenta} back={this.returnCuentas} note={note}/>
                <NuevaDireccion visible={showCrearDireccion} uploadData={this.addDireccion} onClose={this.hideDireccion} 
                    deptos={deptos} munis={munis} changeDepto={this.changeDepto}
                    changeMuni={this.changeMuni} data={dataDir} onChange={this.changeDir}
                />
                <div className="login-container" style={{minHeight: "100vh", paddingTop: "5.5rem"}}>
                    <div className="col-md-8">
                        <div className="col-12 text-center">
                            <h2>Registrarse</h2>
                        </div>
                        <div className="col-12">
                            <Form onSubmit={this.onSubmit} >
                                <Form.Group as={Col} controlId="formEmail">
                                    <Form.Label>Nombre</Form.Label>
                                    <Form.Control type="text" name="nombre" onChange={this.onChange} value={this.state.nombre} />
                                </Form.Group>

                                <Form.Group as={Col} controlId="formAlias">
                                    <Form.Label>Alias de Vendedor</Form.Label>
                                    <Form.Control type="text" name="alias" onChange={this.onChange} value={this.state.alias} />
                                </Form.Group>

                                <Form.Group as={Col} controlId="formEmail">
                                    <Form.Label>Correo Eléctronico</Form.Label>
                                    <Form.Control type="text" name="email" onChange={this.onChange} value={this.state.email} />
                                </Form.Group>

                                <Form.Group as={Col} controlId="formTelefono">
                                    <Form.Label>Telefono</Form.Label>
                                    <Form.Control type="text" name="numero" onChange={this.onChange} value={this.state.numero} />
                                </Form.Group>

                                <Form.Group as={Col} controlId="formPassword">
                                    <Form.Label>Contraseña</Form.Label>
                                    <Form.Control type="password" name="password" onChange={this.onChange} value={this.state.password} />
                                </Form.Group>

                                <Form.Group as={Col} controlId="formPassword">
                                    <Form.Label>Confirmar Contraseña</Form.Label>
                                    <Form.Control type="password" name="confPassword" onChange={this.onChange} value={this.state.confPassword} />
                                </Form.Group>
                                <Form.Group style={{display: "flex", alignItems: "center"}}>
                                    <input type="checkbox" name="terminos" className="checkTerm" checked={this.state.terminos} onChange={this.onChageCheck}/>
                                    <span>He leído y acepto los <a href={prev_url + "terms"} target="_blank"> Términos y condiciones de uso</a> de Rapimercado</span>
                                </Form.Group>
                                <Col md={12} className="text-center">
                                    <Button type="submit" disabled={ this.state.terminos ? "" : "disabled" }>Registrase</Button>
                                    
                                </Col>
                            </Form>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

}

export default SignUp