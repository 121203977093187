import React from 'react'
import Faq from 'react-faq-component'

class FAQ extends React.Component{

    constructor(){
        super();

        let comprador = {
            title: "Quiero Comprar",
            rows: [
                {
                    title: "¿El precio de los productos ya incluye el costo de la entrega en mi domicilio?",
                    content: "Si, envío ya está incluido en el precio del producto anunciado."
                },
                {
                    title: "¿Cómo funciona Rapimercado?",
                    content: "Compras un producto, esperas a que te lo llevemos a tu domicilio y pagas cuando lo recibes."
                },
                {
                    title: "¿Qué es Rapimercado?",
                    content: "Somos una empresa Guatemalteca de logística, facilitamos la página Web www.rapimercado.com para que puedas comprar productos nuevos y usados desde cualquier lugar de Guatemala."
                },
                {
                    title: "¿En qué lugares de Guatemala entregan los productos?",
                    content: "Llegamos a cualquier lugar de Guatemala."
                },
                {
                    title: "¿Cuándo pago el producto que compré?",
                    content: "Lo pagas cuando recibes el producto en tu domicilio.  Por el momento el pago es unicamente en efectivo."
                }
            ]
        }

        let vendedor = {
            title: "Quiero Vender",
            rows: [
                {
                    title: "¿Qué puedo vender?",
                    content: "Puedes vender cualquier producto nuevo o usado que sea legal en Guatemala."
                },
                {
                    title: "¿Cuánto cobran por vender en Rapimercado?",
                    content: "En Rapimercado pagas dos cosas, (1) cobramos una base de veinticinco quetzales (Q25) por la logística de recoger el producto en tu domicilio y entregarlo en el domicilio del comprador en cualquier parte de Guatemala y (2) cobramos 9% del valor del producto por el cobro contra entrega y logística general."
                },
                {
                    title: "¿Puedo vender un producto desde mi casa?",
                    content: "Si, recogemos el producto que vendiste en cualquier parte de Guatemala y lo entregamos en cualquier parte de Guatemala."
                },
                {
                    title: "¿Que debe incluir el precio que le coloco a mi producto?",
                    content: "Únicamente debes incluir los costos expresados en la pregunta número dos (2)."
                },
                {
                    title: "¿Cómo funciona Rapimercado?",
                    content: "Abres tu cuenta de vendedor (menú inicio), públicas los productos que quieres vender y cuando se venda un producto nosotros lo recogemos en tu domicilio y se lo llevamos al comprador en cualquier parte de Guatemala."
                }
            ]
        }

        let styles = {
            titleTextColor: "#fec516",
            rowTitleColor: "#315695",
            arrowColor: "#315695",
        };

        this.state = {
            vendedor: vendedor,
            comprador: comprador,
            style: styles,
        }

        window.scrollTo(0,0);
    }
    

    render(){
        let {vendedor, comprador, style} = this.state
        let config = {
            animate: true
        }
        return(
            <div className="container">
                <h2>Preguntas frecuentes</h2>
                <div className="col-12" style={{marginBottom: "2rem"}}>
                    <Faq data={comprador} styles={style} config={config} />
                </div>
                <div className="col-12" style={{marginBottom: "2rem"}}>
                    <Faq data={vendedor} styles={style} config={config} />
                </div>
                
            </div>
        )
    }

}

export default FAQ