 import React from 'react'
import { Button, Modal } from 'react-bootstrap';
import cookie from 'react-cookies'
import Axios from 'axios';
import { service_path, img_url, prev_url } from './conts';
import swal from 'sweetalert';
import Loader from './Loader';
import CancelarOrden from './CancelarOrden';
import no_image from './assets/no_image.jpg'
import TrackingModal from './TrackingModal';

 class OrdenesList extends React.Component{

    constructor(props){
        super(props)
        console.log(props)
        this.props = props;

        this.state = {
            visible: false,
            dias: 1,
            token: cookie.load("token"),
            loader: false,
            cancelar: false,

        }

        this.showReprogramar = this.showReprogramar.bind(this)
        this.hideReprogramar = this.hideReprogramar.bind(this)
        this.changeSelect = this.changeSelect.bind(this)
        this.reprogramar = this.reprogramar.bind(this)
        this.showCancelar = this.showCancelar.bind(this)
        this.hideCancelar = this.hideCancelar.bind(this)
        this.uploadCancelar = this.uploadCancelar.bind(this)
        this.showNewMessage = this.showNewMessage.bind(this)
        this.getURLEtiqueta = this.getURLEtiqueta.bind(this)
    }

    showReprogramar(){
        this.setState({
            visible: true
        })
    }

    hideReprogramar(){
        this.setState({
            visible: false,
        })
    }

    changeSelect(e){
        let {value} = e.target;

        this.setState({
            dias: value
        }, console.log(this.state))
    }

    reprogramar(){
        this.setState({
            loader: true,
            visible: false
        })
        let {codigoOrden, guias} = this.props.orden;
        let {fechaRecoleccion} = guias[0]; 
        let {dias, token} = this.state;
        dias = parseInt(dias);
        let date = new Date(fechaRecoleccion + "T00:00:00")
        let comparar = new Date();
        if(comparar > date){
            date = comparar
        }
        date = this.addDays(date, dias)
        console.log(date);
        let fecha = "";
        let dia = date.getDate();
        let mes = date.getMonth() + 1;
        console.log(mes);
        if(dia < 10){
            dia = "0" + dia;
        }
        if(mes < 10){
            mes = "0" + mes
        }

        fecha = date.getFullYear() + "-" + mes + "-" + dia;
        console.log(fecha);
        Axios.post(
            service_path + "/orden/reprogramar",
            JSON.stringify({
                orden: codigoOrden,
                fecha: fecha
            }),
            {
                "headers": {
                    "Authorization": "Bearer " + token,
                    "Content-Type": "application/json"
                }
            }
        ).then((res) => {
            this.setState({
                loader: false
            })
            swal({
                icon: "success",
                title: "Recogida reprogramada con exito"
            }).then(() => {
                window.location.reload()
            })
        })
        .catch((err) => {
            this.setState({
                loader: true
            })
            swal({
                icon: "error",
                title: "No se ha podido reprogramar tu viaje"
            })
        })
    }

    isAvaibleDate(date){
        let day = date.getDay();
        if(day === 0 || day === 6)
            return false
        else
            return true
    }

    addDays(date, days){
        for(let i = 0; i < days; i++){
             do {date.setDate(date.getDate() + 1);}
             while(!this.isAvaibleDate(date))}
        return date;
    }

    showCancelar(){
        this.setState({
            cancelar: true
        })
    }

    hideCancelar(){
        this.setState({
            cancelar: false
        })
    }

    uploadCancelar(data){
        this.setState({
            cancelar: false,
            loader: true,
        })

        Axios.post(
            service_path + "/orden/cancelar",
            JSON.stringify(data),
            {
                "headers": {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + this.state.token
                }
            }
        ).then((res) => {
            this.setState({
                loader: false,
            })

            swal({
                icon: "success",
                title: "Se ha cancelado la orden con éxito"
            }).then(() => {
                 window.location.reload()
            });
        })
        .catch((err) => {
            this.setState({
                loader: false,
            })

            swal({
                icon: "error",
                title: "No se ha podido completar la acción"
            }).then(() => {
                this.showCancelar();
           })
        })
    }

    showNewMessage(){
        let data = {
            orden: this.props.orden.codigoOrden
        }
        Axios.post(
            service_path + "/mensaje/nuevovendedor",
            JSON.stringify(data),
            {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + this.state.token
                }
            }
        ).then((res) => {
            let {data} = res;
            console.log(res)
            console.log(data)
            window.location.href = prev_url + "message/" + data.llave
        }).catch((err) => {
            console.log(err)
            console.log(err.response)
            swal({
                icon: "error",
                title: "No se ha podido enviar tu mensaje",
                text: "No hemos podido contactar al usuario comprador en este momento, vuelve a intentarlo mas tarde"
            })
        })
    }

    getURLEtiqueta(){
        let {token} = this.state;
        let {orden} = this.props

        Axios.get(
            service_path + "/orden/urlEtiqueta/" + orden.codigoOrden,
            {
                headers: {
                    "Authorization": "Bearer " + token
                }
            }
        ).then((res)=>{
            let {data} = res;
            console.log(data);
            let a = document.createElement('a');
            a.href = data;
            a.target = "_blank";

            a.click();
        })


    }

    render(){
        let {orden, producto} = this.props;
        let {visible, dias, loader, cancelar } = this.state;
        let fechaRecoleccion = orden.guias[0].fechaRecoleccion + "";
        let guia = orden.guias[0];
        let foto = producto.fotos[0]
        fechaRecoleccion = fechaRecoleccion.split("-");
        return(
            <div>
                <Loader visible={loader} />
                <CancelarOrden visible={cancelar} orden={orden.codigoOrden} vendedor="1" uploadData={this.uploadCancelar} onClose={this.hideCancelar} />
                <Modal show={visible}>
                    <Modal.Header>
                        <Modal.Title>Reprogramar recogida de {producto.nombre}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
  
                     
                                <p>Dentro de cuantos días se pasa a recoger: </p>
                                <select value={dias} onChange={this.changeSelect}>
                                    <option value="1">1</option>
                                    <option value="2">2</option>
                                    <option value="3">3</option>
                                    <option value="4">4</option>
                                    <option value="5">5</option>
                                    
                                </select>
              
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="danger" onClick={this.hideReprogramar}>Cancelar</Button>
                        <Button variant="primary" onClick={this.reprogramar}>Aceptar</Button>
                    </Modal.Footer>
                </Modal>
                {/* <TrackingModal llave={orden.llaveOrden} /> */}
                <div className="col-md-12 vendidos" >
                    <div className="col-4 col-md-3 imagen">
                        <img 
                            className={foto !== undefined ? foto.clase : ""}
                            src={
                                foto !== undefined ?
                                    "" + img_url + foto.nombre + ".jpg"
                                :
                                    no_image
                            }
                        />
                    </div>
                    <div className="col-8 col-md-9">
                        <h3 className="titulo-vendidos">{producto.nombre}</h3>
                        <div className="col-12 no-padding d-flex flex-wrap">
                        <div className="col-6 no-padding">
                                <p className="detalle-vendidos"><strong>No. Orden: </strong> {orden.codigoOrden}</p>
                            </div>
                            <div className="col-6 no-padding">
                                <p className="detalle-vendidos"><strong>Comprador: </strong> {orden.comprador.nombre}</p>
                            </div>
                            <div className="col-6 no-padding">
                                <p className="detalle-vendidos"><strong>Cantidad: </strong>{orden.cantidad}</p>
                            </div>
                            <div className="col-6 no-padding">
                                <p className="detalle-vendidos"><strong>Fecha Envío: </strong>{fechaRecoleccion[2]}-{fechaRecoleccion[1]}-{fechaRecoleccion[0]}</p>
                            </div>
                            <div className="col-6 no-padding">
                                <p className="detalle-vendidos"><strong>Empresa Logistica: </strong>{guia.proveedor.nombre}</p>
                            </div>
                            <div className="col-6 no-padding">
                                <p className="detalle-vendidos"><strong>Estado:</strong>{guia.estado.nombre}</p>
                            </div>
                            <div className="col-6 no-padding">
                                <p className="detalle-vendidos"><strong>No. Servicio: </strong>{guia.numeroServicio}</p>
                            </div>
                            <div className="col-6 no-padding">
                            <p className="detalle-vendidos"><strong>Total Venta: </strong>{parseFloat(producto.precio * orden.cantidad).toFixed(2)}</p>    
                            </div>
                            <div className="col-6 no-padding">
                            <p className="detalle-vendidos"><strong>Ingreso neto: </strong>{parseFloat(producto.precioVendedor * orden.cantidad).toFixed(2)}</p>    
                            </div>
                            <div className="col-6 no-padding">    
                            </div>
                            <div className="col-12 no-padding">
                                <p className="detalle-vendidos"><strong>Acciones: </strong></p>
                                <div className="d-flex">
                                {
                                    (orden.estado.codigoEstadoOrden === 1 || orden.estado.codigoEstadoOrden === 8) ?
                                        <div>
                                            <Button style={{
                                                margin: "0.5rem",
                                                lineHeight: "1"
                                            }} variant="danger" onClick={this.showCancelar}>Cancelar<br /> venta</Button>
                                            {
                                                guia.numeroServicio !== "" ?
                                                <Button style={{
                                                    margin: "0.5rem",
                                                    lineHeight: "1"
                                                }} variant="warning" onClick={this.showReprogramar}>Reprogramar<br /> envio</Button>
                                                 :
                                                 ""
                                            }
                                            
                                            <Button style={{
                                                margin: "0.5rem",
                                                lineHeight: "1"
                                            }} variant="primary" onClick={(e) => {
                                                e.preventDefault();
                                                {/* this.getURLEtiqueta(); */}
                                                window.location.href = guia.urlEtiqueta
                                            }} >Descargar<br /> Etiqueta</Button>
                                        </div>
                                    :

                                    <div></div>
                                }
                                {
                                    orden.usuario !== null ?
                                    <div>

                                        <Button style={{
                                                margin: "0.5rem",
                                                lineHeight: "1"
                                            }} variant="primary" 
                                            onClick={this.showNewMessage}>Contactar<br /> comprador</Button>
                                    </div>
                                    : 
                                    ""
                                }
                              
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
           
        )
    }
 }

 export default OrdenesList