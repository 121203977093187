import React from 'react'
import Axios from 'axios';
import { service_path } from './conts';
import swal from 'sweetalert';

class Verificar extends React.Component{

    constructor(props){
        super(props);

        this.state = {
            token: props.match.params.token
        };
        console.log(this.state, props)
    }

    componentDidMount(){
        console.log("Entro", this.state.token)
        Axios.post(
            service_path + "/auth/verificar",
            JSON.stringify(this.state),
            {
                headers: {
                    'Content-Type': 'application/json'
                }
            }
        ).then((response) => {
            console.log(response)
            let data = response.data
            swal({
                icon: data.icono,
                title: data.titulo,
                text: data.texto
            }).then(() => {
                window.location.href = "/"
            });
        }).catch((error) => {
            console.log("error: ", error)
        })
    }

    render(){
        return (<div></div>)
    }
}

export default Verificar