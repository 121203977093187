import React from 'react'
import Axios from 'axios';
import cookie from 'react-cookies'
import { service_path } from './conts';
import OrdenesList from './OrdenesList'
import {isMobile} from 'react-device-detect';
import Loader from './Loader';

class Ordenes extends React.Component{

    constructor(){
        super();

        this.state = {
            productos: "",
            token: cookie.load("token"),
            orden: "",
            estado: "",
            showTracking: false,
            loader: false,
            lastScrollTop: 0,
        }

        // this.trackingOrden = this.trackingOrden.bind(this);
        // this.closeTracking = this.closeTracking.bind(this);
        this.handleScroll = this.handleScroll.bind(this);
    }

    componentDidMount(){
        let {token} = this.state;

        Axios.get(
            service_path + "/orden/vendidos?page=0",
            {
                "headers": {
                    "Authorization": "Bearer " + token,
                }
            }
        ).then((res) => {
            this.setState({
                productos: res.data.map((item, index) => {
                    return (
                        <OrdenesList producto={item.producto} orden={item} key={index} />
                    )
                })
            })
        })
        .catch((err) => {
            console.log("error: ", err)
        })

        if(isMobile){
            window.addEventListener('scroll', this.handleScroll);
            document.getElementById('container-menu').style.paddingTop = "0"
        }
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }

    handleScroll(e){
        let { lastScrollTop } = this.state;
   
        var st = window.pageYOffset || document.documentElement.scrollTop; // Credits: "https://github.com/qeremy/so/blob/master/so.dom.js#L426"
        if (st > lastScrollTop){
           document.getElementById('container-menu').style.paddingTop = "2.5rem"
        } else {
            document.getElementById('container-menu').style.paddingTop = "0"
        }
        lastScrollTop = st <= 0 ? 0 : st; 

        this.setState({
            lastScrollTop: lastScrollTop
        })
    }

    // trackingOrden(orden){
    //     let { token } = this.state

    //     this.setState({
    //         loader: true
    //     })

    //     Axios.get(
    //         service_path + "/orden/tracking/" + orden,
    //         {
    //             headers: {
    //                 "Authorization": "Bearer " + token
    //             }
    //         }
    //     ).then((res) => {
    //         let { orden, estado } = res.data

    //         this.setState({
    //             loader: false,
    //             orden: orden,
    //             estado: estado,
    //             trackingOrden: true
    //         })
    //     })
    // }

    // closeTracking(){
    //     this.setState({
    //         trackingOrden: false
    //     })
    // }

    render(){
        let { productos, loader } = this.state;
        return (
            <div className="container" style={{minHeight: "110vh"}}>
                <h1>Productos vendidos</h1>

                { productos }
            </div>
        )
    }

}

export default Ordenes