import React from 'react';

class Pagination extends React.Component{
    
    constructor(props){
        super(props);
        console.log(props);
        this.props = props;

        this.nextPage = this.nextPage.bind(this);
        this.prevPage = this.prevPage.bind(this);
        this.toPage = this.toPage.bind(this);
        this.clases = this.clases.bind(this);

        this.state = {
            pages: []
        }

    }

    componentDidMount(){
    }

    nextPage(){
        
        let { current } = this.props;

        current++;
        console.log(current)
        this.setState({
            current: current
        }, this.props.changePage(current))
    }

    prevPage(){
        let { current } = this.props;

        current--;
        this.setState({
            current: current
        }, this.props.changePage(current))
    }

    toPage(page){
        this.setState({
            current: page
        }, this.props.changePage(page))
    }

    clases(page){
        var clases = "page-item"

        if(page === this.props.current){
            clases += " active";
        }

        return clases;
    }

    render(){
        var pages = [];
        for(var i = 1; i <= this.props.total; i++){
            console.log("index: ", i , " total: ", this.props.total)
            pages.push(i);
        }
        return(
            <ul className="pagination">
                <li className="page-item">
                    <a href="!#" className="page-link" onClick={(e) => {
                            e.preventDefault()
                            if(this.props.isFirst) return false;
                            this.prevPage()
                        }} >
                        {"<<"}
                    </a>
                </li>
                        
                {
                    pages.map((item, index) => {
                        return(
                            <li className={this.clases(index)}>
                                <a href="!#" className="page-link" key={index} onClick={(e) => {
                                        e.preventDefault()
                                        if(this.props.current === index) return false;
                                        this.toPage(index)
                                    }} >
                                    {item}
                                </a>
                            </li>
                        )
                    })
                }
                <li className="page-item">
                    <a href="!#" className="page-link" onClick={(e) => {
                            e.preventDefault()
                            if(this.props.isLast) return false;
                            this.nextPage()
                        }} >
                        {">>"}
                    </a>
                </li>
            </ul>
        )
    }

}

export default Pagination;