import React from 'react'
import { Modal, Form, Col, Row, Button } from 'react-bootstrap'
import add from './assets/add.jpg'

class SelectCuenta extends React.Component{

    constructor(props){
        super(props)
        this.props = props;
        this.state = {
            cuentas: ""
        }

        this.changeSelect = this.changeSelect.bind(this)

        console.log("Props: ", props)
    }

    changeSelect(event){
        let {value, name} = event.target;

        this.props.changeSelect(name, value);
    }

    render(){
        return(
            <Modal show={this.props.visible} >
                <Modal.Header>
                    <Modal.Title>Selecciona una cuenta para habilitar el pago de depositos</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group controlId="formCuenta" >
                            <Form.Label>
                                Cuentas  
                                <a className="add-icon" variant="secondary" onClick={this.props.uploadData} >
                                    Agregar cuenta <img src={add} /> 
                                </a>
                            </Form.Label>
                            <Form.Control as="select" value={this.props.cuenta} name="cuenta" onChange={this.changeSelect}>
                                <option value="" disabled selected>Escoger</option>
                                {this.props.data}
                            </Form.Control>
                        </Form.Group>
                        <Form.Group controlId="formTarjeta" >
                            <Form.Label>
                                Tarjetas 
                                <a className="add-icon"  variant="secondary" onClick={this.props.uploadData2} >
                                    Agregar tarjeta <img src={add} /> 
                                </a>
                            </Form.Label>
                            <Form.Control as="select" value={this.props.tarjeta} name="tarjeta" onChange={this.changeSelect}>
                                <option value="" disabled selected>Escoger</option>
                                {this.props.data2}
                            </Form.Control>
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                   
                    <Button variant="primary" onClick={this.props.save}>
                        Guardar
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }

}

export default SelectCuenta