import React from 'react';
import Loader from './Loader';
import { Modal, Form, Button } from 'react-bootstrap';

class ReiniciarContraseña extends React.Component{

    constructor(props){
        super(props);

        this.props = props;

        this.state = {
            loader: false,
            email: "",
        }

        this.onChange = this.onChange.bind(this)
        this.onSubmit = this.onSubmit.bind(this)
    }

    onChange(e){
        let {value, name} = e.target

        this.setState({
            [name]: value
        })
    }

    onSubmit(){
        let data = {
            email: this.state.email
        }

        this.props.onSubmit(data)
    }

    render(){
        let {loader, email} = this.state
        let {visible, onClose} = this.props
        return(
            <div>
                <Modal show={visible}>
                    <Modal.Header>
                        <Modal.Title>Haz olvidado tu contraseña</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form>
                            <Form.Group>
                                <Form.Label>Ingresa el correo con el que te registraste</Form.Label>
                                <Form.Control name="email" value={email} onChange={this.onChange} />
                            </Form.Group>
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="danger" onClick={onClose}>
                            Cancelar
                        </Button>
                        <Button variant="primary" onClick={this.onSubmit} >
                            Aceptar
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        )
    }

}

export default ReiniciarContraseña;