import React from 'react'
import Axios from 'axios';
import { prev_url, service_path } from './conts';
import cookie from 'react-cookies';
import VendidosList from './VendidoList';
import { Button, Col, Form } from 'react-bootstrap';
import swal from 'sweetalert';
import Loader from './Loader';
import NuevaDireccion from './NuevaDireccion';
import SelectDireccion from './SelectDireccion';
import MasiveUpdate from './MasiveUpdate';
import { CSVLink } from 'react-csv';
import Pagination from './Pagination';

class Vendidos extends React.Component{

    constructor(){
        super();

        this.state = {
            productos: "",
            productoUpdate: "",
            direccion: "",
            direcciones: "",
            aliasdireccion: "",
            deptos: "",
            departamento: "",
            muni: "",
            munis: "",
            dirnombre: "",
            dirdireccion: "",
            dirdepto: "",
            dirmuni: "",
            selectDir: false,
            addDir: false,
            token: cookie.load("token"),
            loader: false,
            dataCSV: [],
            headerCSV: [
                {label: "Codigo Producto", key: "codigo"},
                {label: "Nombre Producto", key: "nombre"},
                {label: "Descripcion", key: "descripcion"},
                {label: "Cantidad", key: "cantidad"},
                {label: "Precio", key: "precio"},
                {label: "Incluir envio en Precio", key: "envio"},
            ],
            page: 0,
            totalPages: 0,
            fist: true,
            last: true,
            estado: 0,
            estados: [
                {
                    "nombre": "Todos",
                    "codigoEstadoProducto": 0
                }
            ]
        }

        this.editDir = this.editDir.bind(this)
        this.updateDir = this.updateDir.bind(this)
        this.hideSelectDir = this.hideSelectDir.bind(this)
        this.updateDir = this.updateDir.bind(this)
        this.addDireccion = this.addDireccion.bind(this)
        this.showAddDir = this.showAddDir.bind(this)
        this.hideAddDir = this.hideAddDir.bind(this)
        this.changeDepto = this.changeDepto.bind(this)
        this.changeMuni = this.changeMuni.bind(this)
        this.changeDir = this.changeDir.bind(this)
        this.updateDireccion = this.updateDireccion.bind(this)
        this.loadData = this.loadData.bind(this)
        this.changePage = this.changePage.bind(this)
        this.onChange = this.onChange.bind(this)
    }

    componentDidMount(){
        this.loadData();

        Axios.get(
            service_path + "/producto/estados",
            {
                headers: {
                    "Authorization": "Bearer " + this.state.token,
                }
            }
        ).then((res) => {
            let { data } = res;

            let { estados } = this.state;

            estados = estados.concat(data);
            
            this.setState({
                estados: estados
            })
        })

        Axios.get(
            service_path + "/usuario/direcciones",
            {
                headers: {
                    "Authorization": "Bearer " + this.state.token,
                }
            }
        ).then((res) => {
            let { data } = res;

            this.setState({
                direcciones: data
            })
        })

        Axios.get(
            service_path + "/usuario/depto",
        ).then((res) => {
            let { data } = res;

            this.setState({
                deptos: data.map((item, index) => {
                    return(
                        <option key={index} value={item.codigoDepartamento}>{item.nombre}</option>
                    )
                })
            })
        })
    }

    changePage(page){
        this.setState({
            page: page,
        }, () => {
            this.loadData()
            window.scrollTo(0,0);
        })
    }

    loadData(){
        let { token, page, estado } = this.state

        let data = {
            page: page,
            estado: estado
        }

        Axios.post(
            service_path + "/producto/vendidos/",
            JSON.stringify(data),
            {
                headers: {
                    "Authorization": "Bearer " + token,
                    "Content-Type": "application/json"
                }
            }
        ).then((response) => {
            console.log("response", response)
            let { pageData, data } = response.data

            this.setState({
                productos: data.map((item, index) => {
                    return(
                        <VendidosList data={item} key={index} changeDir={this.editDir}/>
                    )
                }),
                dataCSV: data.map((item, index) => {
                    return({
                        codigo: item.codigoProducto,
                        nombre: item.nombre,
                        descripcion: item.descripcion,
                        cantidad: item.cantidad,
                        precio: item.precio,
                        envio: item.incluirEnvio ? "si" : "no"
                    })
                }),
                first: pageData.first,
                last: pageData.last,
                totalPages: pageData.totalPage
            })
        }).catch((err) => {
            console.log(err)
        })
    }

    editDir(producto, direccion){
        this.setState({
            selectDir: true,
            productoUpdate: producto,
            direccion: direccion
        })
    }

    updateDir(e){
        let { value } = e.target

        this.setState({
            direccion: value
        })
    }

    hideSelectDir(){
        this.setState({
            selectDir: false,
        })
    }

    updateDireccion(){
        let { direccion, productoUpdate, token } = this.state;

        this.setState({
            loader: true,
            selectDir: false,
        })

        let data = {
            direccion: direccion,
            producto: productoUpdate
        }

        Axios.post(
            service_path + "/producto/updateDir",
            JSON.stringify(data),
            {
                headers: {
                    "Authorization": "Bearer " + token,
                    "Content-Type": "application/json"
                }
            }
        )
        .then((res) => {
            this.setState({
                loader: false,
            })
            swal({
                icon: "success",
                title: "Actualización completa",
                text: "Se ha actualizado la dirección de recogida de producto"
            }).then(() => {
                window.location.reload()
            })
        })
        .catch((err) => {
            console.log(err)
            this.setState({
                loader: false,
            })
            swal({
                icon: "error",
                title: "Ha ocurrido un error",
                text: "No se ha podido actulizar tu dirección"
            })
            .then(() => {
                this.setState({
                    selectDir: true,
                })
            })
        })
    }

    addDireccion(data){
        Axios.post(
            service_path + "/usuario/direcciones",
            JSON.stringify(data),
            {
                headers: {
                    "Authorization": "Bearer " + this.state.token,
                    "Content-Type": "application/json"
                }
            }
        ).then((res) => {
            let direcciones = this.state.direcciones;
            direcciones.push(res.data);
            this.setState({
                addDir: false,
                direccion: res.data.codigoDireccion,
                direcciones: direcciones
            })
            swal({
                title: "Dirección guardada con éxito",
                icon: "success"
            }).then(() => {
                this.updateDireccion()
            })
        }).catch((ex) => {
            console.log(ex);
        })
    }

    showAddDir(){
        this.setState({
            addDir: true,
            selectDir: false
        })
    }

    hideAddDir(){
        this.setState({
            addDir: false,
            selectDir: true
        })
    }

    changeDepto(value){
        Axios.get(
            service_path + "/usuario/muni/" + value
        ).then(res => {
            let {data} = res;
            console.log(value)
            this.setState({
                departamento: value,
                muni: "",
                munis:  data.map((item, index) => {
                    return(
                        <option key={index} value={item.codigoMunicipio} >{item.nombre}</option>
                    )
                })
            }, () => {
                console.log(this.state)
            })
        })

    }

    changeMuni(value){
        this.setState({
            muni: value
        })
    }

    changeDir(name, value){
        
        let campo = "dir"+name;
        console.log("Entro al change: ", campo, ", ", value)
        this.setState({
   
            [campo]: value
     
        })
    }

    onChange(e){
        let { name, value } = e.target;

        this.setState({
            [name]: parseInt(value),
            page: 0
        }, () => {
            this.loadData();
        })
    }

    render(){
        let dataDir = {
            nombre: this.state.dirnombre,
            direccion: this.state.dirdireccion,
            muni: this.state.dirmuni,
            depto: this.state.dirdepto,
            id: this.state.dirid
        }
        let {page, totalPages, first, last, productos, estado, estados} = this.state
        console.log(totalPages)
        return(
            <div className="container">
                <Loader visible={this.state.loader} />
                <NuevaDireccion visible={this.state.addDir} uploadData={this.addDireccion} onClose={this.hideAddDir} 
                    deptos={this.state.deptos} munis={this.state.munis} changeDepto={this.changeDepto}
                    changeMuni={this.changeMuni} data={dataDir} onChange={this.changeDir}
                />
                <SelectDireccion visible={this.state.selectDir} direcciones={this.state.direcciones}
                    direccion={this.state.direccion} onChange={this.updateDir} 
                    addDireccion={this.showAddDir} onClose={this.hideSelectDir} 
                    uploadData={this.updateDireccion}
                />
                <h1 className="d-flex justify-content-start">Inventario  
                    <Button variant="danger" onClick={(e) => {
                        e.preventDefault();
                        window.location.href = prev_url + "add-product"
                    }}>Agregar producto</Button>
                    
                    <MasiveUpdate />
                    {/* <CSVLink
                        className="btn btn-warning"
                        data={this.state.dataCSV}
                        headers={this.state.headerCSV}
                        filename="Mis productos.csv"
                        onClick={
                            () => {
                                console.log(this.state.dataCSV)
                            }
                        }
                        >
                        Descargar Productos
                    </CSVLink> */}
                    </h1>

                <Form>
                    <Form.Row>
                        <Form.Group as={Col}>
                            <Form.Label>
                                Por Estados
                            </Form.Label>
                            <Form.Control as="select" name="estado" value={estado} onChange={this.onChange}>
                                {
                                    estados.map((item, index) => {
                                        if(index != 5){
                                            return(
                                                <option key={index} value={item.codigoEstadoProducto}>{item.nombre}</option>
                                            )
                                        }
                                        else{
                                            return("")
                                        }
                                    })
                                }
                            </Form.Control>
                        </Form.Group>
                        <Form.Group as={Col}>
                            
                        </Form.Group>
                        <Form.Group as={Col}>
                            
                        </Form.Group>
                    </Form.Row>
                </Form>
                {productos}
                <Pagination current={page} total={totalPages} isFirst={first} isLast={last} changePage={this.changePage} />
            </div>
        )
    }

}

export default Vendidos;