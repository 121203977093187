import React from 'react'
import imageCompression from 'browser-image-compression';
import AWS from 'aws-sdk'

class TestGetFile extends React.Component{

    constructor(){
        super();

        AWS.config.update({
            accessKeyId: "AKIAIH3GPDOPHNNEAG6Q",
            secretAccessKey: "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"
        })

        this.myBucket = new AWS.S3({
            params: { Bucket: 'rapimercado'},
            region: 'us-east-1',
        })
    }

    makeid(length) {
        var result           = '';
        var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;
        for ( var i = 0; i < length; i++ ) {
           result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
     }

     uploadS3(file,  name, callback){        
        console.log("file", file);
        const params = {
            ACL: 'public-read',
            Key: name + ".jpg",
            ContentType: "image/jpg",
            Body: file,
        }

        this.myBucket.putObject(params)
        .on('httpUploadProgress', (evt) => {
            // that's how you can keep track of your upload progress
            
            console.log(Math.round((evt.loaded / evt.total) * 100))
        })   
        .send((err) => {
            callback(err)
        })
    }

    componentDidMount(){

        var name = this.makeid(15);
        console.log("name ", name)

        fetch('https://images-na.ssl-images-amazon.com/images/I/61pBvlYVPxL._AC_SX679_.jpg')
        .then(res => res.blob()) // Gets the response and returns it as a blob
        .then(blob => {
            // Here's where you get access to the blob
            // And you can use it for whatever you want
            // Like calling ref().put(blob)

            // Here, I use it to make an image appear on the page
            console.log(blob)

            var file = new File([blob], "image.jpg", {
                type: blob.type,
              });
            

            console.log(file);

            const options = {
                maxWidthOrHeight: 360,
            }
    
            let original, normal, thumbnail = "";
    
            const optionsthumbnail = {
                maxWidthOrHeight: 50,
            }
    
            const optionoriginal = {
                maxWidthOrHeight: 960,
            }
    
            imageCompression(file, options)
            .then((fileCompresed) => {
                this.uploadS3(fileCompresed, name, (err) => {
                    if(!err){
                        imageCompression(file, optionsthumbnail)
                        .then((fileCompresed) => {
                            this.uploadS3(fileCompresed, name + "thumb", (err) => {
                                if(!err){
                                    imageCompression(file, optionoriginal)
                                    .then((fileCompresed) => {
                                        this.uploadS3(fileCompresed, name + "full", (err) => {
                                            let { numImagenes } = this.state;
                                            console.log("completo", numImagenes)
                                            numImagenes--;
    
                                            let imagen = {
                                                codigoFoto: null,
                                                nombre: name, 
                                                clase: null
                                            }
    
                                            let loader = numImagenes !== 0;
                                            console.log(loader)
                                            
                                        })
                                    })
                                }
                            })
                        })
                    }
                })
            })
        });

        // console.log(file);

        
    }

    render(){
        return(
            <div className="container">
                <h1>Test</h1>
            </div>
        )
    }
}

export default TestGetFile;