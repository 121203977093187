import React from 'react'
import cookie from 'react-cookies'
import Loader from './Loader';
import Axios from 'axios';
import { prev_url, service_path } from './conts';
import swal from 'sweetalert';
import { Form, Col, Button } from 'react-bootstrap';

class NuevaClave extends React.Component{

    constructor(props){
        super(props);

        this.state = {
            loader: false,
            password: "",
            confPassword: "",
            token: props.match.params.token
        }

        this.onChange = this.onChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    componentDidMount(){
        let { token } = this.state

        Axios.get(
            service_path + "/auth/verificarRestaurar/" + token
        ).then((res) => {
            let { data } = res;

            if(data === 0){
                swal({
                    icon: "warning",
                    title: "No se ha encontrado ninguna solicitud",
                    text: "No se ha encontrado ninguna solicitud de reinicio de contraseña para el enlace consultado"
                }).then(() => {
                    window.location.href = "/"
                })
            }
        })
    }

    onChange(e){
        let { name, value } = e.target

        this.setState({
            [name]: value
        })
    }

    onSubmit(e){
        e.preventDefault();

        let { token, password, confPassword} = this.state
        let data = {
            token: token,
            password: password,
        }

        if(password === confPassword){
            Axios.post(
                service_path + "/auth/restaurar",
                JSON.stringify(data),
                {
                    "headers": {
                        "Content-Type": "application/json"
                    }
                }
            ).then((res) => {
                let {data} = res
                this.setState({
                    loader: false
                })
                swal({
                    icon: data.icono,
                    title: data.titulo,
                    text: data.mensaje
                }).then(() => {
                    if(data.actualizar){
                        window.location.href = prev_url +"login"
                    }
                    else{
                        this.setState({
                            restaurar: true,
                        })
                    }
                })
    
    
            })
        }
        else{
            swal({
                icon: "error",
                title: "Contraseña inválida",
                text: "Las contraseñas ingresadas no son iguales, vuelve a intentarlo"
            })
        }
    }

    render(){
        let {loader, password, confPassword} = this.state
        return(
            <div className="login-container">
                <Loader visible={loader} />
                 <div className="col-md-8">
                    <div className="col-12 text-center">
                        <h2>Ingrese nueva contraseña</h2>
                    </div>
                    <div className="col-12">
                        <Form onSubmit={this.onSubmit} >
                            <Form.Group as={Col} controlId="formPassword">
                                <Form.Label>Contraseña</Form.Label>
                                <Form.Control type="password" name="password" onChange={this.onChange} value={password} />
                            </Form.Group>
                            <Form.Group as={Col} controlId="formPassword">
                                <Form.Label>Confirmar contraseña</Form.Label>
                                <Form.Control type="password" name="confPassword" onChange={this.onChange} value={confPassword} />
                            </Form.Group>
                            <Col md={12} className="text-center">
                                <Button type="submit">Cambiar contraseña</Button>
                            </Col>
                        </Form>
                    </div>
                </div>
            </div>
        )
    }

}

export default NuevaClave;