import React from 'react';
import Axios from 'axios';
import { service_path } from './conts';
import { ReactComponent as Recogido } from './assets/recogido.svg'
import { ReactComponent as Proceso } from './assets/proceso.svg'
import { ReactComponent as Entregado } from './assets/entregado.svg'
import Loader from './Loader';
import swal from 'sweetalert';

class Tracking extends  React.Component{

    constructor(props){
        super(props)

        let codigo = "";

        if(props.match.params.codigo !== undefined){
            codigo = props.match.params.codigo
        }

        this.state = {
            codigo: codigo,
            orden: "",
            receptor: "",
            loader: false
            
        }

        this.buscarCodigo = this.buscarCodigo.bind(this)
        this.buscar = this.buscar.bind(this)
        this.onChange = this.onChange.bind(this)
    }

    componentDidMount(){

        let { codigo } = this.state;

        if(codigo !== ""){
           this.buscarCodigo(codigo)
        }

    }

    buscar(){
        this.buscarCodigo(this.state.codigo);
    }

    onChange(e){
        let {name, value} = e.target;

        this.setState({
            [name]: value
        })
    }

    buscarCodigo(codigo){
        this.setState({
            loader: true
        })

        Axios.get(
            service_path + "/orden/tracking/" + codigo
        ).then((res) => {
            let { orden, receptor } = res.data;

            this.setState({
                orden: orden,
                receptor: receptor,
                loader: false
            })
        }).catch((err) => {
            console.log(err);

            this.setState({
                loader: false
            })

            swal({
                title: "No se ha encontrado información",
                text: "No se ha encontrado información acerca del paquete ingresado, "+
                "revisa que el código sea correcto y vuelve a intentarlo",
                icon: "info"
            })
        })
    }

    render(){
        let { codigo, orden, loader } = this.state
        return(
            <div className="container">
                <Loader visible={loader} />
                <h1>Seguimiento de paquete</h1>
                <div className="col-md-12 text-center d-flex flex-column align-items-center" style={{padding: "1rem"}}>
                    <h3>Codigo de Orden</h3>
                    <p>Ingresa el código de tu orden</p>
                    <input type="text" className="text-center" name="codigo" value={codigo} onChange={this.onChange} />
                    <br />
                    <button className="btn btn-danger" onClick={this.buscar}>Buscar</button>
                </div>
                {
                    orden !== "" ? 
                    <div className="text-center">
                        <div className="col-md-12 icono-tracking-container">
                            <div className="col-4">
                                <Recogido className="icono-tracking" />
                                <h5>Recolectado</h5>
                            </div>
                            <div className="col-4">
                                <Proceso className="icono-tracking" />
                                <h5>En camino</h5>
                            </div>
                            <div className="col-4">
                                <Entregado className="icono-tracking" />
                                <h5>Entregado</h5>
                            </div>
                        </div>
                        <h3>Estado actual</h3>
                        <h4>{orden.estado.nombre}</h4>
                    </div>
                    :
                    <div></div>
                }
            </div>
        )
    }

}

export default Tracking;