import React, { Component } from 'react'
import GoogleMapReact from 'google-map-react';
import locationpin from './assets/locationpin.svg'

const AnyReactComponent = ({ text }) => <div>{text}</div>;

class Mapa extends Component{

    constructor(props){
        super(props)

        this.props = props;

        this.state = {
            latitud: 0,
            longitud: 0
        }

        this.clickMap = this.clickMap.bind(this)
    }

    clickMap(e){
        console.log(e)

        let { lat, lng } = e;

        this.setState({
            latitud: lat,
            longitud: lng,
        })

        this.props.changeLocation(lat, lng);
    }


    render() {
        let { latitud, longitud } = this.state;
        return(
            <div style={{ height: '60vh', width: '100%' }}>
                <GoogleMapReact
                    bootstrapURLKeys={{ key: "AIzaSyDITuumELzOJIDYH8-f2uwGUe85OqPy1z0" }}
                    defaultCenter={{
                        lat: 14.614026,
                        lng: -90.534809   
                    }}
                    defaultZoom={11}
                    yesIWantToUseGoogleMapApiInternals
                    options={{
                        fullscreenControl: false
                    }} 
                    onClick={this.clickMap}
                >
                <div className="marker-map" 
                    lat={latitud}
                    lng={longitud}
                >
                    <img src={locationpin} style={{width: "100%", height: "100%"}} />

                </div>   
                </GoogleMapReact>
            </div>
        )
    }

}

export default Mapa;