import React from 'react';
import Axios from 'axios';
import { service_path } from './conts';
import ProductCard from './ProductCard'
import StarRatings from 'react-star-ratings'

class Vendedor extends React.Component{

    constructor(props){
        super(props);

        let {params} = props.match;

        this.state = {
            id: params.id,
            vendedor: {},
            productos: [],
            venta: 0,
            vendidos: 0
        }
    }

    componentDidMount(){
        Axios.get(
            service_path + "/usuario/seller/" + this.state.id
        ).then((res) => {
            let { data } = res;
            console.log("productos", data.productos)
            this.setState({
                vendedor: data.usuario,
                productos: data.productos,
                venta: data.venta,
                vendidos: data.vendidos
            })
        })
    }

    render(){
        let { vendedor, productos } = this.state

        return(
            <div className="container">
                <div className="align-items-center col-12 d-flex flex-column justify-content-center text-center">
                    <h1 className="no-margin">
                        {vendedor.alias}  
                       
                    </h1>
                    <StarRatings
                        rating={vendedor.calificacion === null ? 0 : vendedor.calificacion}
                        starDimension="15px"
                        starRatedColor="yellow"
                    />
                    <div className="col-md-8 d-flex justify-content-around">
                        <p className="no-margin"><strong>En venta: </strong>{this.state.venta}</p>
                        <p className="no-margin"><strong>Vendidos: </strong>{this.state.vendidos}</p>
                    </div>
                </div>
                <div className="col-md-12 row row-cards">
                    {
                        productos.map((item, index) => {
                            let foto = item.fotos[0];
                            let data = {
                                nombre: item.nombre,
                                codigo: item.codigoProducto,
                                precio: item.precio,
                                clase: foto !== undefined ? foto.clase : "",
                                imagen: foto !== undefined ? foto.nombre : "",
                            }
                            return(
                                <ProductCard key={index} data={data}  />
                            )
                        })
                    }
                </div>
            </div>
        )
    }

}

export default Vendedor;