import React from 'react'
import { GalleryCard } from 'tabler-react'
import { img_url, prev_url } from './conts';

function formatNumber(num) {
    return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

function CategorieCard(props) {
    const classes = "d-flex px-2"
    let portada = img_url + props.data.imagen + ".jpg";
    let nombre = props.data.nombre;
    let clase = props.data.clase !== null ? props.data.clase : "";
    nombre = nombre.replace(/\~|\!|\@|\#|\$|\&|\*|\(|\)|\=|\:|\/|\,|\;|\?|\+|\'/g, '')
    nombre = encodeURI(nombre);
    console.log("props: ", props)
    return (
        <div className="col-xl-3 col-md-3 col-6 producto">
            <a className="link-route" href={prev_url + "art/" + nombre + "/" + props.data.codigo }>
             {/* <a className="link-route" href={"/product/"+props.data.id}>  */}
                <GalleryCard>
                    <GalleryCard.Image className={clase} src={  portada } />
                    <div className={classes} style={{"flexDirection": "column"}}>
                        <h4>{props.data.nombre}</h4>
                        <span><strong>Q{formatNumber(parseFloat(props.data.precio).toFixed(2))}</strong></span>
                    </div>
                </GalleryCard>
            </a>
           
        </div>

    )
}

export default CategorieCard