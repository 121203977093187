import React from 'react';
import { Modal, Form, Button } from 'react-bootstrap';
import Mapa from './Mapa';
import swal from 'sweetalert';

class NuevaDireccion extends React.Component{

    constructor(props){
        super(props);

        this.props = props;

        this.state = {
            alias: props.data.nombre,
            direccion: props.data.direccion,
            depto: props.data.depto,
            muni: props.data.muni,
            latitud: "",
            longitud: ""
        }

        this.onChange = this.onChange.bind(this)
        this.onSubmit = this.onSubmit.bind(this)
        this.changeLocation = this.changeLocation.bind(this)
    }

    componentDidMount(){
        if(this.state.depto !== ""){
            this.props.changeDepto(this.state.depto)
        }
    }

    onChange(e){
        let { name, value } = e.target;

        this.props.onChange(name, value);

    }

    componentWillReceiveProps(props){
        
    }

    changeLocation(lat, lng){
        this.setState({
            latitud: lat,
            longitud: lng
        })
    }

    onSubmit(){
        let data = {
            nombre: this.props.data.nombre,
            direccion: this.props.data.direccion,
            depto: this.props.data.depto, 
            muni: this.props.data.muni,
            id: this.props.data.id,
            latitud: this.state.latitud,
            longitud: this.state.longitud
        }
        console.log(this.props)
        console.log(data);
        if(data.nombre !== undefined && data.direccion !== undefined && data.depto !== undefined && data.muni !== undefined){
            this.props.uploadData(data);
        }
        else{
            let faltantes = [];

            if(data.nombre === undefined){
                faltantes.push("Alias")
            }

            if(data.direccion === undefined){
                faltantes.push("Dirección")
            }

            if(data.depto === undefined){
                faltantes.push("Departamento")
            }

            if(data.muni === undefined){
                faltantes.push("Municipio")
            }

            swal({
                icon: "error",
                title: "No se ha podido guardar tu dirección",
                text: "Los siguientes campos no pueden estar vacios: " + faltantes.join(", ")
            })
        }
        
    }


    render(){
      
        let { data } = this.props
        console.log(data);
        return(
             <Modal show={this.props.visible}>
                <Modal.Header>
                    <Modal.Title>Ingresa los datos de tu Dirección</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group controlId="forAlias">
                            <Form.Label>Nombre Dirección: </Form.Label>
                            <Form.Control name="nombre" value={data.nombre} onChange={this.onChange} />
                        </Form.Group>
                        <Form.Group controlId="forDepto">
                            <Form.Label>Departamento: </Form.Label>
                            <Form.Control as="select" name="depto" value={data.depto} onChange={(e) => { 
                                let {value, name} = e.target
                                this.setState({
                                    depto: value
                                }) 
                                this.props.onChange(name, value)
                                this.props.changeDepto(value)
                            }} >
                                <option value="">Elegir</option>
                                {this.props.deptos}
                            </Form.Control>
                        </Form.Group>
                        <Form.Group controlId="forMuni">
                            <Form.Label>Municipio: </Form.Label>
                            <Form.Control as="select" name="muni" value={data.muni} onChange={(e) => {
                                let {value, name} = e.target
                                this.setState({
                                    muni: value
                                }) 
                                this.props.onChange(name, value)
                                this.props.changeMuni(value)
                            }} >
                                <option value="">Elegir</option>
                                {this.props.munis}
                            </Form.Control>
                        </Form.Group>
                        <Form.Group controlId="forDireccion">
                            <Form.Label>Direccion: </Form.Label>
                            <Form.Control name="direccion" value={data.direccion} onChange={this.onChange} />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Elige en el mapa tu direccion</Form.Label>
                            <Mapa changeLocation={this.changeLocation} />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={this.props.onClose}>
                        Regresar
                    </Button>
                    <Button variant="primary" onClick={this.onSubmit}>
                        Agregar
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }

}

export default NuevaDireccion;