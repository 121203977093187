import React from 'react';
import logo from './logo.png'
import facebook from './facebook.png'
import twitter from './twitter.png'
import { prev_url } from '../conts';

class Footer extends React.Component{

    constructor(){
        super();
    }

    render(){
        return(

            <div className="footer" id="footer">
                <div className="col-md-12 no-padding d-flex flex-wrap" style={{
                    padding: "1rem 0 !important"
                }}>
                    <div className="col-md-3">
                        <img style={{width: "75%"}} src={logo} />
                    </div>
                    <div className="col-md-3">
                        <h5>Rapimercado</h5>
                        <h5>Escribenos: <a href="mailto:ayuda@rapimercado.com">ayuda@rapimercado.com</a></h5>
                    </div>
                    <div className="col-md-3">
                        <h5><a href={prev_url + "faq"}>Preguntas frecuentes</a></h5>
                        <h5><a href={prev_url + "terms"}>Términos y condiciones</a></h5>
                    </div>
                    <div className="col-md-3">
                        {/* <h5>Redes Sociales</h5>
                        <a className="icono-social" href="#"><img src={facebook} /></a>
                        <a className="icono-social" href="#"><img src={twitter} /></a> */}
                    </div>
                </div>
                <div className="col-md-12 copyright">
                    <h4 class="">©Copyright Rapimercado 2020</h4>
                </div>
            </div>
        )
    }

}

export default Footer;