import React from 'react'
import cookie from 'react-cookies'
import Axios from 'axios';
import { service_path } from './conts';
import { Modal, Form, Row, Col, Button } from 'react-bootstrap';

class CancelarOrden extends React.Component{

    constructor(props){

        super(props)

        this.props = props;

        this.state = {
            estados: "",
            token: cookie.load("token"),
            razon: "",
        }

        this.onChange = this.onChange.bind(this)
        this.onSubmit = this.onSubmit.bind(this)
    }

    componentDidMount(){
        let { vendedor } = this.props;
        let { token } = this.state;

        Axios.get(
            service_path + "/orden/estadoCancelar/"+vendedor,
            {
                "headers": {
                    "Authorization": "Bearer " + token,
                    "Content-Type": "application:/json"
                }
            }
        ).then((res) => {
            let { data } = res;

            this.setState({
                estados: data.map((item, index) => {
                    return(
                        <option key={index} value={item.codigoRazonCancelacion}>{item.razon}</option>
                    )
                })
            })
        })

    }

    onChange(event){
        let { name, value } = event.target;

        this.setState({
            [name]: value
        })
    }

    onSubmit(e){
        e.preventDefault();

        let data = {
            codigoRazon:  this.state.razon,
            codigoOrden: this.props.orden,
        }

        this.props.uploadData(data);
    }

    render(){
        let { visible, onClose } = this.props;
        let { estados, razon } = this.state;
        return(
            <Modal show={visible}>
                <Modal.Header>
                    <Modal.Title>Cancelar Orden</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group as={Row} controlId="forEstado" >
                            <Form.Label column md={2}>
                                Razón de cancelación
                            </Form.Label>
                            <Col md={10}>
                                <Form.Control as="select" value={razon} name="razon" onChange={this.onChange}>
                                    <option value="" disabled>Escoger</option>
                                    {estados}
                                </Form.Control>                            
                            </Col>
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={onClose}>
                        Regresar
                    </Button>
                    <Button variant="primary" onClick={this.onSubmit}>
                        Agregar
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }

}

export default CancelarOrden;