import React from 'react';
import ComprarLista from './ComprasLista';
import Axios from 'axios';
import { service_path } from './conts';
import cookie from 'react-cookies';

class Compras extends React.Component{

    constructor(){
        super();

        this.state = {
            compras: "",
        }
    }

    componentDidMount(){
        Axios.get(
            service_path + "/orden/comprados",
            {
                headers: {
                    "Authorization": "Bearer " + cookie.load("token")
                }
            }
        ).then((response) => {
            let {ordenes} = response.data;

            this.setState({
                compras: ordenes.map((item, index) => {
                    return(
                        <ComprarLista data={item} key={index} />
                    )
                })
            })
        }).catch((err) => {

        })
    }

    render(){
        return(
            <div className="container">
                <h2>Productos comprados</h2>
                {this.state.compras}
            </div>
        )
    }

}

export default Compras;