import React from 'react'

import loader from './assets/loader.svg'

function Loader(props){
    const nameclass = props.visible ? "loader-page" : "loader-page hide";

    return (
        <div className={nameclass}>
            <img src={loader} alt="Cargando..."/>
        </div>
    )
}

export default Loader