import React from 'react'
import { List } from 'tabler-react'
import { img_url, prev_url } from './conts';

function ProductList(props){
    let portada = img_url +  props.data.imagen + ".jpg";
    let clase = props.data.clase !== null ? props.data.clase : "";
    console.log("Clase Imagen: ", clase)
    let nombre =  props.data.nombre
    nombre = nombre.replace(/\~|\!|\@|\#|\$|\&|\*|\(|\)|\=|\:|\/|\,|\;|\?|\+|\'/g, '')
    nombre = encodeURI(nombre);

    return(
        
            <a className="list-group-item "  href={prev_url + "art/" + nombre + "/" + props.data.codigo }> 
                <div className="col-md-12 d-flex">
                    <div className="col-3 no-padding d-flex image-list">
                        <img className={clase} src={portada} alt=""/>
                    </div>
                    <div className="col-xl-8 col-9 d-flex flex-column justify-content-center">
                        <h4>{props.data.nombre}</h4>
                        <span><strong>Precio: </strong> Q{props.data.precio}</span>
                    </div>
                </div>
            </a>
      
        
    )

}

export default ProductList