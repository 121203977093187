import React from 'react';
import cookie from 'react-cookies';
import Axios from 'axios';
import { prev_url, service_path } from './conts';
import swal from 'sweetalert';

class Mensaje extends React.Component{

    constructor(props){
        super(props);

        this.state = {
            mensajes: [],
            usuario: {},
            ultimo: 0,
            hilo: 0,
            token: cookie.load("token"),
            mensaje: "",
            llave: props.match.params.llave,
            producto: {}
        }

        this.classMessage = this.classMessage.bind(this);
        this.changeMessage = this.changeMessage.bind(this);
        this.sendMessage = this.sendMessage.bind(this);
        this.actualizar = this.actualizar.bind(this);
        this.findNewMessages = this.findNewMessages.bind(this);
    }


    componentDidMount(){
        

        let {token, llave} = this.state;
        if(token !== undefined){
            Axios.get(
                service_path + "/mensaje/hilo/" + llave + "?page=0",
                {
                    headers: {
                        "Authorization": "Bearer " + token
                    }
                }
            ).then((res) => {
                let {usuario, mensajes, ultimoMensajeId, codigoHilo, producto} = res.data;

                this.setState({
                    mensajes: mensajes,
                    usuario: usuario,
                    ultimo: ultimoMensajeId,
                    hilo: codigoHilo,
                    producto: producto
                })
            })

            setTimeout(this.findNewMessages, 60000);
        }
        else{
            swal({
                icon: "error",
                title: "No has iniciado sesión",
                text: "Para poder ver el mensaje debes iniciar sesión"
            }).then(() => { 
                window.location.href = prev_url + "login"
            })

             
        }
    }

    classMessage(logeado){
        let classes = logeado ? "message right" : "message left";

        return classes;
    }

    changeMessage(e){
        let { value } = e.target;
        this.setState({
            mensaje: value
        })
    }

    sendMessage(){
        let {hilo, token, mensaje, ultimo}  = this.state;

        let data = {
            hilo: hilo,
            mensaje: mensaje, 
            ultimo: ultimo,
        }

        Axios.post(
            service_path + "/mensaje/mensajehilo",
            JSON.stringify(data),
            {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + token
                }
            }
        ).then((res) => {
            document.getElementById("mensaje").value = ""
            this.setState({
                mensaje: ""
            })
            this.actualizar(res.data);
        }).catch((err) => {

        })
    }

    findNewMessages(){
        let { hilo, ultimo, token } = this.state;

        Axios.get(
            service_path + "/mensaje/verificar/" + hilo + "/" + ultimo,
            {
                headers: {
                    "Authorization": "Bearer " + token
                }
            }
        ).then((res) => {
            this.actualizar(res.data);
        })
        setTimeout(this.findNewMessages, 60000)
    }

    actualizar(data){
        console.log(data);
        if(data.actualizar){
            let actuales = this.state.mensajes;
            let mensajes = data.mensajes.concat(actuales);
            this.setState({
                mensajes: mensajes,
                ultimo: data.ultimo,
                mensaje: ""
            })
        }
    }

    render(){
        let { usuario, mensajes, mensaje, producto } = this.state;
        console.log();
        return(
            <div className="container d-flex flex-column">
                
                <div className="col-12 d-flex flex-wrap no-padding" style={{"minHeight": "5rem"}}>
                    <div className="col-md-6 no-padding d-flex align-items-center" style={{"margin": "10px 0"}}>
                        <h3 className="no-margin">{usuario.nombre}</h3>
                    </div>
                    <div className="col-md-6 no-padding d-flex" style={{"height": "5rem"}}>
                        <div className="col-4 d-flex justify-content-center align-items-center">
                            <img className="message-img" src={producto.fotos !== undefined ? producto.fotos[0].url : ""}  />
                        </div>
                        <div className="col-8 d-flex align-items-center">
                            <h5>{producto.nombre}</h5>
                        </div>
                    </div>
                </div>
                
                <div className="col-12 no-padding messages-container">
                {
                    mensajes.map((item, index) => {
                        return(
                           <div key={index} className={this.classMessage(item.logeado)}> 
                               <p>{item.mensaje}</p>
                            </div> 
                        )
                    })
                }   
                </div>
                <div className="col-12 message-write">
                    <textarea onChange={this.changeMessage} id="mensaje">{mensaje}</textarea>
                    <button onClick={this.sendMessage}>Enviar</button>
                </div>
            </div>
        )
    }

}

export default Mensaje;