import React from 'react';
import { Button } from 'react-bootstrap';
import Loader from './Loader';
import Axios from 'axios';
import cookie from 'react-cookies'
import swal from 'sweetalert';
import { service_path, img_url, prev_url } from './conts';
import no_image from './assets/no_image.jpg'

class VendidosList extends React.Component{

    constructor(props){
        super(props);

        this.props = props;

        this.state = {
            visible: false,
            token: cookie.load("token")
        }

        this.onClick = this.onClick.bind(this)
        this.republicar = this.republicar.bind(this)
        this.eliminar = this.eliminar.bind(this)
        this.confEliminiar = this.confEliminiar.bind(this)
    }

    onClick(id){
        window.location.href = prev_url + "edit-product/" + id;
    }

    eliminar(id){
        this.setState({
            visible: true
        })

        Axios.post(
            service_path + "/producto/eliminar/" + id,
            "",
            {
                "headers": {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + this.state.token
                }
            }
        ).then((res) => {
            let {data} = res

            this.setState({
                visible: false
            })

            swal({
                icon: "success",
                text: "Tu producto se ha eliminado del Sistema",
                title: "Producto Eliminado con éxito"
            })
            .then(() => {
                
                    window.location.reload();
            })
        })
        .catch((err) => {
            this.setState({
                visible: false
            })


            swal({
                icon: "error",
                text: "Tu producto no se ha eliminado del Sistema",
                title: "Producto no eliminado"
            })
            console.log(err);
        })
    }

    republicar(id){
        this.setState({
            visible: true
        })

        Axios.post(
            service_path + "/producto/republicar/" + id,
            "",
            {
                "headers": {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + this.state.token
                }
            }
        ).then((res) => {
            let {data} = res

            this.setState({
                visible: false
            })

            swal({
                icon: data.icono,
                text: data.mensaje,
                title: data.titulo 
            })
            .then(() => {
                if(data.actualizar){
                    window.location.reload();
                }
            })
        })
        .catch((err) => {
            this.setState({
                visible: false
            })

            console.log(err);
        })
    }

    confEliminiar(id){
        swal({
            title: "¿Deseas eliminar el producto?",
            text: "Esta acción no se puede revertir, estas seguro de querer eliminar el producto",
            icon: "warning",
            buttons: {
                cancelar: {
                    text: "Cancelar",
                    value: false
                },
                eliminar: {
                    text: "Eliminar",
                    value: true,
                    className: "btn-danger"
                }
            },
            dangerMode: true,
        }).then((value) => {
            if(value){
                this.eliminar(id);
            }
        })
    }

    render(){
        let { data } = this.props;
        let { estado } = data
        let foto = data.fotos[0];

        return(
            <div>
                <Loader visible={this.state.visible} />
                <div className="col-md-12 vendidos" >
                    <div className="col-4 col-md-3 imagen">
                        <img 
                            className={foto !== undefined ? foto.clase : ""}
                            src={

                            foto !== undefined ?
                                "" + img_url +foto.nombre + ".jpg"
                            :
                                no_image
                            }
                        />
                    </div>
                    <div className="col-8 col-md-9">
                        <h3 className="titulo-vendidos">{data.nombre}</h3>
                        <div className="col-12 no-padding d-flex flex-wrap">
                            <div className="col-6 no-padding">
                                <p className="detalle-vendidos"><strong>Codigo: </strong>{data.codigoProducto}</p>
                            </div>
                            <div className="col-6 no-padding">
                                <p className="detalle-vendidos"><strong>Inventario: </strong>{data.cantidad}</p>
                            </div>
                            <div className="col-6 no-padding">
                                <p className="detalle-vendidos"><strong>Precio: </strong>{data.precio}</p>
                            </div>
                            <div className="col-md-6 no-padding">
                                <p className="detalle-vendidos"><strong>Categoria: </strong>{data.categoria.nombre}</p>
                            </div>
                            <div className="col-md-6 no-padding">
                                <p className="detalle-vendidos"><strong>Condicion: </strong>{data.condicion.nombre}</p>
                            </div>
                            <div className="col-6 no-padding">
                                <p className="detalle-vendidos"><strong>Direccion Recoleccion: </strong>{data.direccion.nombre}</p>
                            </div>
                            <div className="col-md-6 no-padding">
                                <p className="detalle-vendidos">
                                    <strong>Visible en la página: </strong> {data.estado.codigoEstadoProducto === 1 ? "Si" : "No"}
                                </p>
                            </div>
                            <div className="col-md-6 no-padding">
                                <p className="detalle-vendidos">
                                    <strong>Estado del Producto: </strong> <span className={estado.codigoEstadoProducto === 7 ? "red" : ""}>{estado.nombre}</span>
                                </p>
                            </div>
                            {
                                estado.codigoEstadoProducto === 7 ?
                                <div className="col-md-12 no-padding">
                                    <p className="detalle-vendidos">
                                        <strong>Razón de Rechazo: </strong> {data.razonRechazo}
                                    </p>
                                </div>
                                :
                                ""
                            }
                            <div className="col-md-12 no-padding">
                                <Button variant="warning" size="sm" 
                                    onClick={(e) => {
                                        e.preventDefault();
                                        this.onClick(data.codigoProducto)
                                    }}>
                                    Editar
                                </Button>
                                <Button variant="danger" size="sm" style={{marginLeft: "1rem"}} 
                                    onClick={(e) => {
                                        e.preventDefault();
                                        this.confEliminiar(data.codigoProducto)
                                    }}>
                                    Eliminar
                                </Button>
                                <Button variant="primary" size="sm" style={{marginLeft: "1rem"}}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        this.props.changeDir(data.codigoProducto, data.direccion.codigoDireccion)
                                    }}
                                >
                                    Cambiar Dirección
                                </Button>

                                {
                                    estado.codigoEstadoProducto === 4 ?

                                    <Button variant="warning" size="sm" style={{marginLeft: "1rem"}} onClick={(e) => {
                                            e.preventDefault();
                                            console.log("hola")
                                            this.republicar(data.codigoProducto)
                                        }}>
                                        Republicar
                                    </Button>

                                    : 
                                    ""
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
        )
    }

}

export default VendidosList;