import React from 'react'
import cookie from 'react-cookies'
import { Form, Col, Button } from 'react-bootstrap'
import swal from 'sweetalert';
import Axios from 'axios';
import { service_path } from './conts';
import { FaBullseye } from 'react-icons/fa';

class Perfil extends React.Component{

    constructor(){
        super();

        this.state = {
            token: cookie.load("token"),
            nombre: "",
            alias: "",
            email: "",
            telefono: "",
            oldpassword: "",
            password: "",
            confpassword: ""
        }

        this.onSubmit = this.onSubmit.bind(this)
        this.onChange = this.onChange.bind(this)
    }

    onChange(e){
        let { name, value } = e.target;

        this.setState({
            [name]: value
        })
    }

    onSubmit(e){
        e.preventDefault();

        let { nombre, alias, email, telefono, oldpassword, 
            password, confpassword, token } = this.state
    
        if(confpassword === password){
            let data = {
                nombre: nombre, 
                telefono: telefono,
                email: email,  
                alias: alias,
                password: oldpassword,
                newPassword: password
            }

            Axios.post(
                service_path + "/usuario/update",
                JSON.stringify(data),
                {
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": "Bearer " + token
                    }
                }
            )
            .then((res) => {
                if(res.data){
                    swal({
                        icon: "success",
                        title: "Perfil Guardado",
                        text: "Se ha guardado tu información con éxito"
                    })
                    .then(() => {
                        window.location.reload()
                    })
                }
                else{
                    swal({
                        icon: "error",
                        title: "Verificación fallida",
                        text: "Las contraseña que ingresaste con coincide con tu contraseña actual"
                    })
                }
            })
            .catch((err) => {
                console.log(err)

                swal({
                    icon: "error",
                    title: "Ha ocurrido un error",
                    text: "No hemos podido guardar tu información, intentalo más tarde"
                })
            })
        }
        else{
            swal({
                icon: "error",
                title: "Verificación fallida",
                text: "Las contraseñas ingresadas no coinciden, por favor vuelva a ingresarlas"
            })
        }
    }

    componentDidMount(){
        let { token } = this.state;

        if(token !== undefined){
            Axios.get(
                service_path + "/usuario/get",
                {
                    headers:{
                        "Authorization": "Bearer " + token
                    }
                }
            )
            .then((res) => {
                let { data } = res;

                this.setState({
                    nombre: data.nombre,
                    alias: data.alias,
                    email: data.email,
                    telefono: data.telefono
                })
            })
        }
        else{
            window.location.href = "/"
        }
    }

    render(){
        let { nombre, alias, email, telefono, oldpassword, password, confpassword } = this.state
        return(
            <div className="container">
                <h2>Perfil</h2>
                <Form>
                    <Form.Row>
                        <Form.Group as={Col} controlId="formNombre">
                            <Form.Label>Nombre</Form.Label>
                            <Form.Control name="nombre" value={nombre} onChange={this.onChange} />
                        </Form.Group>
                        <Form.Group as={Col} controlId="formNombre">
                            <Form.Label>Alias</Form.Label>
                            <Form.Control name="alias" value={alias} onChange={this.onChange} />
                        </Form.Group>
                    </Form.Row>
                    <Form.Row>
                        <Form.Group as={Col} controlId="formNombre">
                            <Form.Label>Email</Form.Label>
                            <Form.Control name="email" value={email} onChange={this.onChange} />
                        </Form.Group>
                        <Form.Group as={Col} controlId="formNombre">
                            <Form.Label>Telefono</Form.Label>
                            <Form.Control name="telefono" value={telefono} onChange={this.onChange} />
                        </Form.Group>
                    </Form.Row>
                    <p><strong>Nota: </strong>Si no quieres modificar tu contraseña deja los siguientes campos en blanco.</p>
                    <Form.Row>
                        <Form.Group as={Col} controlId="formNombre">
                            <Form.Label>Contraseña actual</Form.Label>
                            <Form.Control autoComplete={"off"} type="password" name="oldpassword" value={oldpassword} onChange={this.onChange} />
                        </Form.Group>
                        <Form.Group as={Col} controlId="formNombre">
                          
                        </Form.Group>
                    </Form.Row>
                    <Form.Row>
                        <Form.Group as={Col} controlId="formNombre">
                            <Form.Label>Nueva Contraseña</Form.Label>
                            <Form.Control autoComplete={"off"} type="password"  name="password" value={password} onChange={this.onChange} />
                        </Form.Group>
                        <Form.Group as={Col} controlId="formNombre">
                            <Form.Label>Confirmar Contraseña</Form.Label>
                            <Form.Control autoComplete={"off"} type="password" name="confpassword" value={confpassword} onChange={this.onChange} />
                        </Form.Group>
                    </Form.Row>
                </Form>
                <Button variant="primary" onClick={this.onSubmit}>Guardar</Button>
            </div>
        )
    }

}

export default Perfil