import React from 'react';
import {Button, Modal, Breadcrumb} from 'react-bootstrap'
import ImageGallery from 'react-image-gallery';
import { service_path, img_url, prev_url } from './conts';
import Axios from 'axios';
import NuevoMensaje from './NuevoMensaje';
import cookie from 'react-cookies';
import chat from './templates/chat.png'
import swal from 'sweetalert';
import ReactBnbGallery from 'react-bnb-gallery'
import 'react-bnb-gallery/dist/style.css'
import Loader from './Loader';


class ProductDetail extends React.Component{

    constructor(props){
        super(props)
        this.state = {
            id: props.match.params.id,
            producto: {},
            data: {},
            newMessage: false,
            token: cookie.load("token"),
            cantidad: 1,
            vendedor: {},
            cantidades: "",
            loader: true,
            fullSize: [],
            imagenes: [],
            vista: false,
            currentImage: 0
        }

        this.handleOnDragStar = this.handleOnDragStar.bind(this)
        this.onClick = this.onClick.bind(this)
        this.showNewMessage = this.showNewMessage.bind(this)
        this.onChange = this.onChange.bind(this)
        this.showFullSize = this.showFullSize.bind(this)
        this.hideFullSize = this.hideFullSize.bind(this)
        //this.changeIndex = this.changeIndex(this)

        this.myRef = React.createRef();
    }

    handleOnDragStar(e){
        e.preventDefault()
    }

    showFullSize(){
        this.setState({
            vista: true
        })
    }

    hideFullSize(){
        this.setState({
            vista: false
        })
    }


    onChange(e){
        let {name, value} = e.target;
        console.log(name, value)
        this.setState({
            [name]: value
        })
    }

    clickChat(){
        let { token } = this.state;

        if(token !== undefined){
            this.showNewMessage()
        }
        else{
            swal({
                title: "¿Quieres contactar al vendedor?",
                text: "Para poder contactar al vendedor es necesario que inicies sesión",
                icon: "warning",
                buttons: {
                    cancelar: {
                        text: "Cancelar",
                        value: false,
                        className: "btn-danger"
                    },
                    eliminar: {
                        text: "Iniciar Sesión",
                        value: true,
                        className: "btn-primary"
                    }
                },
                dangerMode: true,
            }).then((value) => {
                if(value){
                    window.location.href = prev_url + "login"
                }
            })
        }
    }

    showNewMessage(){
        let data = {
            producto: this.state.id
        }
        Axios.post(
            service_path + "/mensaje/nuevo",
            JSON.stringify(data),
            {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + this.state.token
                }
            }
        ).then((res) => {
            let {data} = res;
            console.log(res)
            console.log(data)
            window.location.href = prev_url + "message/" + data.llave
        }).catch((err) => {
            console.log(err)
            console.log(err.response)
            swal({
                icon: "error",
                title: "No se ha podido enviar tu mensaje",
                text: "No hemos podido contactar al usuario vendedor en este momento, vuelve a intentarlo mas tarde"
            })
        })
    }

    componentDidMount(){
        Axios.get(service_path + "/producto/get/"+this.state.id)
            .then(response => {
                let { data } = response

                let imagenes = [];
                let fullsizeSlider = [];

                let { fotos } = data

                for(let i = 0; i < fotos.length; i++){
                    let key = fotos[i].nombre;
                    let clase = fotos[i].clase !== null ? fotos[i].clase : "";
                    const dataImage = {
                        original: img_url + key + ".jpg",
                        fullscreen: img_url + key + "full.jpg",
                        thumbnail: img_url + key + "thumb.jpg",
                        originalClass: clase,
                        thumbnailClass: clase, 
                    }

                    const dataFullSize = {
                        original: img_url + key + "full.jpg",
                        thumbnail: img_url + key + "thumb.jpg",
                        originalClass: clase,
                        thumbnailClass: clase, 
                    }

                    imagenes.push(dataImage);
                    fullsizeSlider.push(dataFullSize);
                    
                }

                let cantidades = []

                for(let i = 1; i <= data.cantidad; i++){
                    cantidades.push(<option value={i}>{i}</option>);
                }

                this.setState({
                    data: data,
                    imagenes: imagenes,
                    cantidades: cantidades,
                    fullSize: fullsizeSlider,
                    loader: false       
                }, () => { console.log(this.state)})
            })
    } 

    onClick(){
        let { compradoReciente } = this.state.data;
       
        if(compradoReciente){
            this.redirectCompra();
        }
        else{
            this.redirectCompra();
        }
        
    }

    redirectCompra(){
        let { nombre, codigoProducto, cantidad } = this.state.data;

        nombre = nombre.replace(/\~|\!|\@|\#|\$|\&|\*|\(|\)|\=|\:|\/|\,|\;|\?|\+|\'/g, '')
        nombre = encodeURI(nombre);

        let data = {
            "productoId": codigoProducto,
            "cantidad": cantidad
        }

        Axios.post(
            service_path + "/orden/disponible",
            JSON.stringify(data),
            {
                headers: {
                    "Content-Type": "application/json"
                }
            }
        ).then((res) => {
            let {data} = res;

            if(data.disponible){
                window.location.href = prev_url + "art/" + nombre + "/" + codigoProducto + "/pago/" + cantidad
            }
            else{
                swal({
                    icon: "error",
                    title: "Sin existencias suficientes",
                    text: "Existencias disponibles: " + data.cantidad
                })
            }
        }).catch((err) => {
            console.log(err)
        })

    }

    changeIndex(index){
        console.log(index)
        this.setState({
            currentImage: index
        })
    }

    isHTML(str) {
        var a = document.createElement('div');
        a.innerHTML = str;
      
        for (var c = a.childNodes, i = c.length; i--; ) {
          if (c[i].nodeType == 1) return true; 
        }
      
        return false;
    }

    render(){
        let { vista, data, fullSize, currentImage, loader, newMessage, id, imagenes, cantidad, cantidades } = this.state

        let condicion = data.condicion !== undefined ? 
                            data.condicion
                        :
                            "";

        let descripcion = data.descripcion + "";

        let htmldes = this.isHTML(descripcion)

        if(!htmldes){
            descripcion = descripcion.split("\n")
        }
        let caracteristicas = data.caracteristicas + "";

        let htmlcar = this.isHTML(caracteristicas);

        if(!htmlcar){
            caracteristicas = caracteristicas.split("\n");
        }

        return(
            <div className="container product">
                <Loader visible={loader} />
                <Modal show={vista} size="lg" onHide={this.hideFullSize}>
                    <Modal.Header closeButton>
                    </Modal.Header>
                    <Modal.Body>
                        <ImageGallery 
                            items={fullSize}
                            startIndex={currentImage}
                            showFullscreenButton={false}
                            showPlayButton={false}
                            ref={this.myRef}
                            additionalClass="fullsize"
                            onSlide={(index) => {
                                this.changeIndex(index);
                            }}
                        />
                    </Modal.Body>
                </Modal>
                <NuevoMensaje onClose={this.hideNewMessage} visible={newMessage} producto={id} />
                <div className="row no-margin">
                    <div className="col-md-12">
                     
                        <Breadcrumb>
                            <Breadcrumb.Item href="/">Inicio</Breadcrumb.Item>
                            <Breadcrumb.Item href={ data.categoria !== undefined ? prev_url + data.categoria.opcionMenu.nombre : "/" }>{data.categoria !== undefined ? data.categoria.nombre : ""}</Breadcrumb.Item>
                            <Breadcrumb.Item active>{data.nombre}</Breadcrumb.Item>
                        </Breadcrumb>
                          
                    </div>
                    <div className="col-md-5 col-xs-12">
                        <div className="ProductImage">
                            <ImageGallery 
                                items={imagenes} 
                                startIndex={currentImage}
                                showFullscreenButton={false}
                                showPlayButton={false}
                                ref={this.myRef}
                                onClick={this.showFullSize}
                                onSlide={(index) => {
                                    this.changeIndex(index);
                                }}
                            />
                        </div>
                    </div>
                    <div className="col-md-7 col-xs-12">
                        <p>Vendido por: <a className="vendedor-link" href={prev_url + "seller/" + data.codigoVendedor}>
                            <span>{data.aliasVendedor}</span>
                        
                        </a></p>

                        <h2>{data.nombre}</h2>
                        
                        <h4>Descripción del producto</h4>

                        {
                            htmldes ? 
                                <div dangerouslySetInnerHTML={{__html: descripcion}}></div>
                            :
                            <ul>
                                {
                                    descripcion.map((item, index) => {
                                        if(item !== ""){
                                            return(
                                                <li key={index}>{item}</li>
                                            )
                                        }
                                        else{
                                            return(
                                                <br></br>
                                            )
                                        }
                                    })
                                }
                            </ul>
                        }
                        
                                
                        <p><strong>Precio: </strong>Q.{data.precio}</p>
                        <div className="col-md-12 no-padding d-flex flex-wrap">
                            <div className="col-md-6 no-padding">
                                <p><strong>Condición: </strong>{condicion}</p>
                            </div>
                            <div className="col-md-6 no-padding">
                                <p><strong>Cantidad: </strong>
                                <select name="cantidad" value={cantidad} onChange={this.onChange}>
                                   {cantidades}
                                </select></p>
                            </div>
                            <div className="col-md-12 d-flex align-content-center justify-content-center">
                                <Button variant="danger" type="submit" onClick={this.onClick}>Comprar ahora</Button>
                            </div>
                        </div>
                        <br />
                        
                       
                            <a className="chat-vendedor" href="#" style={{float: "right"}} onClick={(e) => {
                                e.preventDefault();
                                this.clickChat();
                            }}><img src={chat} style={{ height: "2rem" }} /><span>Contactar Vendedor</span></a>
                           
                        
                    </div>
                    <div className="col-md-12">
                        <h3>Caracteristicas</h3>
                            {
                                htmlcar ? 
                                    <div dangerouslySetInnerHTML={{__html: caracteristicas}}></div>
                                :
                                <ul>
                                    {
                                        caracteristicas.map((item, index) => {
                                            return(
                                                <li key={index}>{item}</li>
                                            )
                                        })
                                    }
                                </ul>
                            }
                    </div>
                    
                </div>

            </div>
        )
    }

}

export default ProductDetail