import React from 'react';
import ReactDom from 'react-dom';
import './index.css';
import "tabler-react/dist/Tabler.css";
import 'react-dropzone-uploader/dist/styles.css';
import 'react-image-gallery/styles/css/image-gallery.css'
import 'react-credit-cards/es/styles-compiled.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import App from './components/App'

ReactDom.render(<App />, document.getElementById('root'))
