import React from 'react';
import { Button } from 'react-bootstrap';
import cookie from 'react-cookies'
import Axios from 'axios';
import swal from 'sweetalert';
import Loader from './Loader';
import CancelarOrden from './CancelarOrden';
import { service_path, img_url } from './conts';
import no_image from './assets/no_image.jpg'

class ComprarLista extends React.Component{

    constructor(props){
        super(props)

        this.props = props;

        this.state = {
            cancelar: false,
            loader: false,
            token: cookie.load('token')
        }

        this.showCancelar = this.showCancelar.bind(this)
        this.hideCancelar = this.hideCancelar.bind(this)
        this.uploadCancelar = this.uploadCancelar.bind(this)
    }

    showCancelar(){
        this.setState({
            cancelar: true
        })
    }

    hideCancelar(){
        this.setState({
            cancelar: false
        })
    }

    uploadCancelar(data){
        this.setState({
            cancelar: false,
            loader: true,
        })

        Axios.post(
            service_path + "/orden/cancelar",
            JSON.stringify(data),
            {
                "headers": {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + this.state.token
                }
            }
        ).then((res) => {
            this.setState({
                loader: false,
            })

            swal({
                icon: "success",
                title: "Se ha cancelado la orden con éxito"
            }).then(() => {
                 window.location.reload()
            });
        })
        .catch((err) => {
            this.setState({
                loader: false,
            })

            swal({
                icon: "error",
                title: "No se ha podido completar la acción"
            }).then(() => {
                this.showCancelar();
           })
        })
    }

    render(){
        let { data } = this.props;
        let {producto, } = data;
        let { loader, cancelar } = this.state
        let date = new Date(data.fecha);
        let foto = data.fotos[0]
        console.log(date.getDate());
        return(
            <div className="col-md-12 vendidos" >
                <Loader visible={loader} />
                <CancelarOrden visible={cancelar} orden={data.codigoOrden} vendedor="0" uploadData={this.uploadCancelar} onClose={this.hideCancelar} />
                <div className="col-4 col-md-3 imagen">
                    <img 
                        className={foto !== undefined ? foto.clase : ""}
                        src={
                            foto !== undefined ?
                                "" + img_url + foto.nombre + ".jpg"
                            :
                                no_image
                        }
                    />
                </div>
                <div className="col-8 col-md-9">
                    <h3 className="titulo-vendidos">{producto.nombre}</h3>
                    <div className="col-12 no-padding d-flex flex-wrap">
                        <div className="col-6 no-padding">
                            <p className="detalle-vendidos"><strong>No. Orden: </strong>{data.codigoOrden}</p>
                        </div>
                        <div className="col-6 no-padding">
                            <p className="detalle-vendidos"><strong>Cantidad: </strong>1</p>
                        </div>
                        <div className="col-6 no-padding">
                            <p className="detalle-vendidos"><strong>Total: </strong>{producto.precio}</p>
                        </div>
                        <div className="col-md-6 no-padding">
                            <p className="detalle-vendidos"><strong>Estado: </strong>{data.estado.nombre}</p>
                        </div>
                        <div className="col-md-6 no-padding">
                            <p className="detalle-vendidos"><strong>Fecha: </strong>{date.getDate()}/{date.getMonth() + 1}/{date.getFullYear()}</p>
                        </div>
                        {
                            (data.estado.codigoEstadoOrden === 1 || data.estado.codigoEstadoOrden === 8)  ?  
                            <div className="col-md-6 no-padding">
                                <Button variant="danger" onClick={this.showCancelar}>Cancelar</Button>
                            </div>
                            :
                            <div></div>
                        }
                    </div>
                </div>
            </div>
        )
    }

}

export default ComprarLista;