import React from 'react'

import {Link} from 'react-router-dom'
import {Navbar, Nav, NavDropdown } from 'react-bootstrap'
import logo from './logo.png'
import chat from './chat.png'
import Axios from 'axios';
import cookie from 'react-cookies';
import { prev_url, service_path } from '../conts';
import { FaHome, FaLocationArrow, FaDollarSign } from "react-icons/fa";
import {isMobile} from 'react-device-detect';

class Header extends React.Component{

    constructor(){
        super();

        let token = cookie.load('token');

        this.state = {
            categories: "",
            token: token,
            login: token !== undefined ? true : false,
            nuevos: 0,
            lastScrollTop: 0,
        };

        this.clickToogle = this.clickToogle.bind(this)
        this.handleScroll = this.handleScroll.bind(this);
        this.clickItem = this.clickItem.bind(this);
    }

    clickToogle(){
        let{token} = this.state;

        if(token !== undefined){
            document.getElementsByClassName('dropdown-toggle')[0].click()
        }
        
    }

    componentDidMount(){
        Axios.get(service_path + "/categoria/all")
            .then(response => {
                console.log(response);
                this.setState({
                    categories: response.data.map((item, index) => {
                        return(
                            <NavDropdown.Item key={index} href={"/#/"+item.opcionMenu.nombre}>{item.nombre}</NavDropdown.Item>
                        )
                        
                    })
                })
            })

        let { login, token } = this.state;

        if(login){
            Axios.get(
                service_path + "/mensaje/noleidos",
                {
                    "headers": {
                        "Authorization": "Bearer " +  token
                    }
                }
            ).then((res) => {
                let {data} = res;
                
                this.setState({
                    nuevos: data
                })
            }) 
        }
        
        if(isMobile){
            window.addEventListener('scroll', this.handleScroll);
            if(login){
                document.getElementById('message-icon').style.display= "none"
            }
            document.getElementById('track-button').style.display = "none"
            document.getElementById('logo-site').style.display = "block"
        }
        
    }

    handleScroll(e){
        let { lastScrollTop, login } = this.state;
   
        var st = window.pageYOffset || document.documentElement.scrollTop; // Credits: "https://github.com/qeremy/so/blob/master/so.dom.js#L426"
        if (st > lastScrollTop){
            if(login){
                document.getElementById('message-icon').style.display= "block"
            }
           document.getElementById('track-button').style.display = "flex"
           document.getElementById('logo-site').style.display = "none"
        } else {
            if(login){
                document.getElementById('message-icon').style.display= "none"
            }

            document.getElementById('track-button').style.display = "none"
            document.getElementById('logo-site').style.display = "block"
        }
        lastScrollTop = st <= 0 ? 0 : st; 

        this.setState({
            lastScrollTop: lastScrollTop
        })
    }

    clickItem(){
        document.getElementById('toggle-menu').click()
    }

    render(){
        let { login, nuevos } = this.state;
        return(
            <Navbar bg="light" expand="lg" fixed="top" style={{width: "100vw"}} id="navbar">
                <div className="container" id="container-menu" style={{marginTop: "0"}}>
                  
                    <div className="d-flex justify-content-center align-items-center" style={{width: "100%"}}>
                        <Navbar.Brand style={{display: "flex", alignItems: "center"}}>
                            <Navbar.Toggle id="toggle-menu" aria-controls="basic-navbar-nav" onClick={this.clickToogle}/>
                            <Link to="/" id="logo-site">
                                <img 
                                    src={logo}
                                    height="50"
                                    className="d-inline-block align-top"
                                    alt="Rapimercado"
                                   
                                />
                            </Link>
                            {
                                login ?
                                    <a href={prev_url + "messages"} className="nav-link" id="message-icon"><img src={chat} style={{height: "1.7rem"}} />
                                    {
                                        nuevos > 0 ?
                                        <p className="no-reading">{nuevos}</p>
                                        : ""   
                                    }
                                    </a>
                                :
                                ""
                            }
                        </Navbar.Brand>
                        <div className="menu-visible">                    
                            <a className="header-menu vender" href={prev_url + "add-product"}><strong className="currency-icon">Q</strong> Vender</a>
                            <a className="header-menu tracking" href={prev_url + "tracking"} id="track-button"><FaLocationArrow /> Rastrear</a>
                        </div>
                    </div>
                
                    
                    <Navbar.Collapse id="basic-navbar-nav">
                        
                        <Nav className="ml-auto">
                           
                        
                            {
                                this.state.login ?
                                <NavDropdown title="Usuario" id="basic-nav-dropdown-2">
                                    <NavDropdown.Item onClick={this.clickItem} href={prev_url + "profile"}>Mi Perfil</NavDropdown.Item>
                                    <NavDropdown.Item onClick={this.clickItem} href={prev_url + "onsale"}>Mis Productos</NavDropdown.Item>
                                    <NavDropdown.Item onClick={this.clickItem} href={prev_url + "buy"}>Mis Compras</NavDropdown.Item>
                                    <NavDropdown.Item onClick={this.clickItem} href={prev_url + "orders"}>Mis Ventas</NavDropdown.Item>
                                    <NavDropdown.Item onClick={this.clickItem} href={prev_url + "tracking"}>Rastreo de orden</NavDropdown.Item>
                                    <NavDropdown.Item onClick={this.clickItem} href={prev_url + "address"}>Mis Direcciones</NavDropdown.Item>
                                    <NavDropdown.Item onClick={this.clickItem} href={prev_url + "bankAccounts"}>Mis Cuentas Bancarias</NavDropdown.Item>
                                    <NavDropdown.Item onClick={this.clickItem} href={prev_url + "logout"}>Cerrar Sesión</NavDropdown.Item>
                                </NavDropdown>
                                :
                                <div className="menu-container">
                                
                                    <Nav.Link style={{textAlign: "center"}} onClick={this.clickItem} href={prev_url + "login"}>Iniciar Sesión</Nav.Link>
                                    <Nav.Link style={{textAlign: "center"}} onClick={this.clickItem} href={prev_url + "signup"}>Crear Usuario</Nav.Link>
                                </div>
                                
                            }
                            
                        </Nav>
                    </Navbar.Collapse>
                </div>
                
               
            </Navbar>
        )
    }
}

export default Header