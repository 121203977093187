import Axios from 'axios';
import React from 'react'
import { Button, Form, Modal } from 'react-bootstrap';
import cookie from 'react-cookies'
import swal from 'sweetalert';
import { prev_url, service_path } from './conts';
import Loader from './Loader';

class AlertaNuevo extends React.Component{

    constructor(props){
        super(props);

        this.state = {
            email: "",
            registrar: false,
            deptos: [],
            munis: [],
            bancos: [],
            tipoCuentas: [],
            monedas: [],
            nombre: "",
            telefono: "",
            alias: "",
            password: "",
            confPassword: "",
            aliasdir: "",
            direccion: "",
            depto: "",
            muni: "",
            numeroCuenta: "",
            banco: "",
            tipoCuenta: "",
            moneda: "",
            loader: false
        }

        this.props = props;

        this.onChange = this.onChange.bind(this)
        this.onClose = this.onClose.bind(this)
        this.validarEmail = this.validarEmail.bind(this)
        this.changeDepto = this.changeDepto.bind(this)
        this.onCloseRegistrar = this.onCloseRegistrar.bind(this)
        this.saveData = this.saveData.bind(this)
    }

    componentDidMount(){
        let { visible } = this.props;
        console.log("Visible", this.props);
        

        Axios.get(
            service_path + "/auth/signupVendedor"
        ).then((res) => {
            let { deptos, bancos, tipos, monedas } = res.data;

            this.setState({
                deptos: deptos,
                bancos: bancos,
                tipoCuentas: tipos,
                monedas: monedas
            })
        })
    }

    validateEmail(email) {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    validarEmail(){
        let { email } = this.state;

        if(this.validateEmail(email)){
            let data = {
                email: email
            }
    
            Axios.post(
                service_path + "/auth/comprobarVendedor",
                JSON.stringify(data),
                {
                    headers: {
                        "Content-Type": "application/json"
                    }
                }
            ).then((res) => {
                let { vendedor, size } = res.data;
    
                if(vendedor){
                    this.setState({
                        visible: false,
                        registrar: true
                    })
                }
                else{
                    swal({
                        icon: "warning",
                        title: "Usuario ya registrado",
                        text: "Haz realizado " + size + " ventas"
                    }).then(() => {
                        this.onClose()
                    })
                }
            })
        }
        else{
            swal({
                icon: "error",
                title: "Email inválido",
                text: "El email que haz ingresado no es válido"
            })
        }
        
    }

    onChange(e){
        let { name, value } = e.target

        this.setState({
            [name]: value
        })
    }

    changeDepto(e){
        let { value } = e.target

        Axios.get(
            service_path + "/usuario/muni/" + value
        ).then(res => {
            let {data} = res;
            console.log(value)
            this.setState({
                depto: value,
                muni: "",
                munis:  data
            }, () => {
                console.log(this.state)
            })
        })

    }

    onClose(){
       this.props.onClose();
    }

    onCloseRegistrar(){
        swal({
            icon: "warning",
            title: "¿Desea cancelar tu registro?",
            text: "Estas seguro que no deseas completar tu registro",
            buttons: {
                cancelar: {
                    text: "Regresar a Registro",
                    value: false
                },
                eliminar: {
                    text: "Salir sin Registrar",
                    value: true,
                    className: "btn-danger"
                }
            },
        }).then((res) => {
            if(res){
                this.setState({
                registrar: false
            })
            }
        })
        
    }

    saveData(){
        let {nombre, email, telefono, alias, password, confPassword, aliasdir, depto, muni, 
            numeroCuenta, banco, tipoCuenta, moneda, direccion} = this.state

        let data = {
            nombre: nombre,
            email: email,
            numero: telefono,
            alias: alias,
            password: password,
            aliasdir: aliasdir,
            direccion: direccion,
            depto: depto,
            muni: muni,
            numeroCuenta: numeroCuenta,
            banco: banco,
            tipoCuenta: tipoCuenta,
            moneda: moneda
        }

        if(password !== confPassword){
            swal({
                icon: "error",
                title: "Contraseña inválida",
                text: "La contraseña y confirmación de contraseña no coinciden"
            })
        }
        else{
            Axios.post(
                service_path + "/auth/signupVendedor",
                JSON.stringify(data),
                {
                    headers: {
                        "Content-Type": "application/json"
                    }
                }
            ).then((res) => {
                let { success, message } = res.data;

                let icon = success ? "success" : "error";
                let title = success ? "Usuario registrado": "Ha ocurrido un error";

                swal({
                    icon: icon,
                    title: title,
                    text: message
                }).then(() => {
                    if(success){
                        window.location = prev_url +"login"
                    }
                })

            })
        }
    }


    render(){
        let { loader, registrar, email, deptos, bancos, munis, tipoCuentas, monedas, direccion,
            nombre, telefono, alias, password, confPassword, aliasdir, depto, muni, numeroCuenta, banco, tipoCuenta,  moneda} = this.state;
        let { visible } = this.props

        if(visible){
            cookie.save("primeraVisita", false);
        }

        return (
            <div>
                <Loader visible={loader} />
                <Modal show={visible} centered size="lg" onHide={this.onClose}>
                    <Modal.Body>
                        <Form.Group>
                            <h2>¡Promoción para nuevos Vendedores!</h2>
                            <p>Al registrarte en Rapimercado para vender tus productos, realiza tus primeras ventas libres de comisión</p>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Ingresa tu email</Form.Label>
                            <Form.Control value={email} type="email" name="email" onChange={this.onChange} />
                        </Form.Group>
                        <Form.Group className="text-center">
                            <Button variant="danger" onClick={this.onClose}>Cancelar</Button>
                            <Button variant="primary" style={{margin:"0 10px"}} onClick={this.validarEmail}>Aceptar</Button>
                        </Form.Group>
                        
                    </Modal.Body>
                </Modal>
                <Modal show={registrar} centered size="lg" onHide={this.onCloseRegistrar}>
                    <Modal.Header closeButton>
                        <Modal.Title>Registrar Vendedor</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <h3>Datos Vendedor</h3>
                        <Form.Group>
                            <Form.Label>Nombre</Form.Label>
                            <Form.Control value={nombre} name="nombre" onChange={this.onChange} />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Ingresa tu email</Form.Label>
                            <Form.Control value={email} type="email" name="email" onChange={this.onChange} disabled={true} />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Contraseña</Form.Label>
                            <Form.Control value={password} type="password" name="password" onChange={this.onChange} />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Confirmar Contraseña</Form.Label>
                            <Form.Control value={confPassword} type="password" name="confPassword" onChange={this.onChange} />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Alias</Form.Label>
                            <Form.Control value={alias} name="alias" onChange={this.onChange} />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Telefono</Form.Label>
                            <Form.Control value={telefono} name="telefono" onChange={this.onChange} />
                        </Form.Group>
                        <h3>Direccion</h3>
                        <Form.Group>
                            <Form.Label>Nombre Direccion</Form.Label>
                            <Form.Control value={aliasdir} name="aliasdir" onChange={this.onChange} />
                        </Form.Group>
                        <Form.Group controlId="forDepto">
                            <Form.Label>Departamento: </Form.Label>
                            <Form.Control as="select" name="depto" value={depto} onChange={this.changeDepto} >
                                <option value="">Elegir</option>
                                {deptos.map((item, index) => {
                                    return (
                                        <option value={item.codigoDepartamento} key={index}>{item.nombre}</option>
                                    )
                                })}
                            </Form.Control>
                        </Form.Group>
                        <Form.Group controlId="forMuni">
                            <Form.Label>Municipio: </Form.Label>
                            <Form.Control as="select" name="muni" value={muni} onChange={this.onChange} >
                                <option value="">Elegir</option>
                                {munis.map((item, index) => {
                                    return (
                                        <option value={item.codigoMunicipio} key={index}>{item.nombre}</option>
                                    )
                                })}
                            </Form.Control>
                        </Form.Group>
                        <Form.Group controlId="forDireccion">
                            <Form.Label>Direccion: </Form.Label>
                            <Form.Control name="direccion" value={direccion} onChange={this.onChange} />
                        </Form.Group>
                        <h3>Cuenta Bancaria</h3>
                        <Form.Group>
                            <Form.Label>Numero de Cuenta</Form.Label>
                            <Form.Control name="numeroCuenta" value={numeroCuenta} onChange={this.onChange} />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Banco</Form.Label>
                            <Form.Control as="select" name="banco" value={banco} onChange={this.onChange} >
                                <option value="">Elegir</option>
                                {bancos.map((item, index) => {
                                    return (
                                        <option value={item.codigoBanco} key={index}>{item.nombre}</option>
                                    )
                                })}
                            </Form.Control>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Tipo de Cuenta</Form.Label>
                            <Form.Control as="select" name="tipoCuenta" value={tipoCuenta} onChange={this.onChange} >
                                <option value="">Elegir</option>
                                {tipoCuentas.map((item, index) => {
                                    return (
                                        <option value={item.codigoTipoCuenta} key={index}>{item.nombre}</option>
                                    )
                                })}
                            </Form.Control>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Moneda</Form.Label>
                            <Form.Control as="select" name="moneda" value={moneda} onChange={this.onChange} >
                                <option value="">Elegir</option>
                                {monedas.map((item, index) => {
                                    return (
                                        <option value={item.codigoMoneda} key={index}>{item.nombre}</option>
                                    )
                                })}
                            </Form.Control>
                        </Form.Group>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="danger" onClick={this.onCloseRegistrar}>Cerrar</Button>
                        <Button variant="primary" onClick={this.saveData}>Registrarse</Button>
                    </Modal.Footer>
                </Modal>
            </div>
            
        )
    }

}

export default AlertaNuevo;