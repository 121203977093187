import React from 'react'
import { Form, Col, Button } from 'react-bootstrap'
import axios from 'axios'
import cookie from 'react-cookies'
import { Redirect } from 'react-router-dom'
import jwtDecode from 'jwt-decode'
import { prev_url, service_path } from './conts'
import Loader from './Loader'
import swal from 'sweetalert'
import ReiniciarContraseña from './ReiniciarContraseña'

class Login extends React.Component{
    
    constructor(props){ 
        super(props)

        this.state = {
            email: "",
            password: "",
            from: props.location.state !== undefined ? props.location.state.from : { pathname: "/" },
            redirect: cookie.load('token') === undefined ? false : true,
            restaurar: false,
            loader: false,
        }

        console.log("State Login: ", this.state);

        this.onChange = this.onChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.showRestaurar = this.showRestaurar.bind(this);
        this.hideRestaurar = this.hideRestaurar.bind(this);
        this.submitResturar = this.submitResturar.bind(this)
    }

    onChange(event){
        
        const { name, value } = event.target;

        this.setState({
            [name]: value
        })
    }

    onSubmit(event){
        event.preventDefault();

        let data = {
            usuario: this.state.email,
            password: this.state.password
        }

        axios.post(
            service_path+"/auth/login",
            JSON.stringify(data),
            {
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Content-Type': 'application/json'
                }
            }
        ).then((res) => {
            let { data } = res;

            if(data.success){
                let token = res.data.accessToken

                let decoded = jwtDecode(token);

                cookie.save("token", token,  { path: '/' })
                cookie.save("token_expire", decoded.exp + "000", { path: '/' })
                
                window.location.href = this.state.from.path !== undefined ? this.state.from.path : ""; 

                console.log("Response: ", res);
            }
            else{
                swal({
                    icon: "error",
                    title: "No se pudo iniciar Sesión",
                    text: "Email inválido"
                })
    
            }
        }).catch((ex) => {
            swal({
                icon: "error",
                title: "No se pudo iniciar Sesión",
                text: "Contraseña inválida"
            })

            console.log("Error: ", ex);
        })
    }

    showRestaurar(){
        this.setState({
            restaurar: true
        })
    }

    hideRestaurar(){
        this.setState({
            restaurar: false
        })
    }

    submitResturar(data){
        this.setState({
            loader: true,
            restaurar: false
        })

        axios.post(
            service_path + "/auth/restablecer",
            JSON.stringify(data),
            {
                "headers": {
                    "Content-Type": "application/json"
                }
            }
        )
        .then((res) => {
            let {data} = res
            this.setState({
                loader: false
            })
            swal({
                icon: data.icono,
                title: data.titulo,
                text: data.mensaje
            }).then(() => {
                if(data.actualizar){
                    window.location.href = "/"
                }
                else{
                    this.setState({
                        restaurar: true,
                    })
                }
            })
        })
    }

    render(){
        let{redirect, loader, restaurar} = this.state

        if(redirect){
            return(
                <Redirect to={this.state.from} />
            )
        }

        return(
            <div className="login-container">
                <Loader visible={loader} />
                <ReiniciarContraseña visible={restaurar} onSubmit={this.submitResturar} onClose={this.hideRestaurar} />
                <div className="col-md-8">
                    <div className="col-12 text-center">
                        <h2>Iniciar Sesión</h2>
                    </div>
                    <div className="col-12">
                        <Form onSubmit={this.onSubmit} >
                            <Form.Group as={Col} controlId="formEmail">
                                <Form.Label>Correo Eléctronico</Form.Label>
                                <Form.Control type="text" name="email" onChange={this.onChange} />
                            </Form.Group>

                            <Form.Group as={Col} controlId="formPassword">
                                <Form.Label>Contraseña</Form.Label>
                                <Form.Control type="password" name="password" onChange={this.onChange} />
                            </Form.Group>
                            <span className="link" onClick={this.showRestaurar}>¿Haz olvidado tu contraseña?</span>
                            <Col md={12} className="text-center">
                                <Button type="button" variant="danger" onClick={(e) => {
                                        e.preventDefault();
                                        window.location.href = prev_url + "signup"
                                    }}>Registrarse</Button>
                                <Button type="submit" style={{"marginLeft": "1rem"}}>Iniciar Sesión</Button>
                            </Col>
                        </Form>
                    </div>
                </div>
            </div>
        )
    }

}

export default Login