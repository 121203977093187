import React, { Component } from 'react'
import cookie from 'react-cookies'
import Axios from 'axios';
import { service_path } from './conts';
import { Modal, Button } from 'react-bootstrap';

class TrackingModal extends Component{
    
    constructor(props){
        super(props)

        this.props = props;

       this.getclass = this.getclass.bind(this)
    }

    getclass(estado){
        let { codigoEstadoGuia } = this.props.estado;

        if(codigoEstadoGuia !== 4 && codigoEstadoGuia !== 3){
            if(codigoEstadoGuia <= estado){
                return "status active"
            }
            else{
                return "status"
            }
        }
        else{
            return "status"
        }
    }
 
    render(){
        let { visible, estado, onClose, orden } = this.props
        return(
            <Modal show={visible}>
                <Modal.Header>
                    <Modal.Title>Rastreo de Guia: <strong>{orden.guias[0].guiaProveedor}</strong></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="col-2">
                        <div className={this.getclass(1)}>

                        </div>
                    </div>
                    <div className="col-10">
                        <h5></h5>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={onClose} >

                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }
}

export default TrackingModal